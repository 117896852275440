import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import BreadCrumb from "../../components/breadcrumbs/BreadCrumb";
import MyLicense from "./MyLicense";
import Reports from "./Reports";

const Settings = () => {
  const location = useLocation();
  const isLicensePage = location.pathname === "/settings/license";

  return (
    <>
      <BreadCrumb
        breadcrumbItems={["Settings", isLicensePage ? "License" : "Reports"]}
        isLastUpdate={false}
        showScanning={false}
      />
      <Navbar className="border-bottom mt-4">
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto ms-3">
            <Link
              to="/settings"
              className={`disable-link-color discovery-nav-text text-white ${
                !isLicensePage ? "discovery-active-nav-text" : ""
              }`}
            >
              Reports
            </Link>
            <Link
              to="/settings/license"
              className={`disable-link-color discovery-nav-text text-white mx-4 ${
                isLicensePage ? "discovery-active-nav-text" : ""
              }`}
            >
              My License
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div>
        {isLicensePage ? (
          <div className="p-5">
            <MyLicense />
          </div>
        ) : (
          <div className="px-3 pt-4">
            <Reports />
          </div>
        )}
      </div>
    </>
  );
};

export default Settings;
