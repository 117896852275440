import React, { useRef, useState, useMemo,useEffect } from "react";
import { FormGroup, FormControl, Badge } from "react-bootstrap";
import { Field } from "formik";
import { showToast } from "../../util/toasts";
import { ReactComponent as ArrowDown } from "../../assets/images/chevron-green-down.svg";
import * as Yup from "yup";
import core from "../../util/core";
import { ReactComponent as Xmark } from "../../assets/images/xmark.svg";

const TargetInput = ({ data, setData, cleanDomainNew, addHost }) => {
  const hostInputRef = useRef(null);
  const domainChipsRef = useRef(null); // Ref for the domain-chips container
  const [isOpen, setIsOpen] = useState(false);
  const [toastDisplayed, setToastDisplayed] = useState(false);
  const [showAllHosts, setShowAllHosts] = useState(false);
  const [hostsPerRow, setHostsPerRow] = useState(3); // Default hosts per row
  const schema = Yup.object().shape({
    host: Yup.string()
      .test(
        "is-host-required",
        "Assets are required",
        (value) => data?.hosts?.length > 0 || value !== ""
      )
      .test(
        "isValidHost",
        "Invalid Assets",
        (value) => !value || core.isValidHost(value)
      ),
  });

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
    // Calculate the number of hosts that fit into one row
    const calculateHostsPerRow = () => {
      if (domainChipsRef.current) {
        const containerWidth = domainChipsRef.current.offsetWidth;
        const hostChipWidth = 150; 
        const hostsPerRow = Math.floor(containerWidth / hostChipWidth);
        setHostsPerRow(hostsPerRow);
      }
    };
    useEffect(() => {
      calculateHostsPerRow();
      window.addEventListener("resize", calculateHostsPerRow);
      return () => window.removeEventListener("resize", calculateHostsPerRow);
    }, []);
    const hostsToDisplay = showAllHosts
    ? data?.hosts
    : data?.hosts?.slice(0, hostsPerRow * 3); // Show hosts for 3 rows
  const remainingHostsCount = data?.hosts?.length - hostsPerRow * 3;

  const createChip = (e, setFieldValue) => {
    const newHostDomains = e.target.value
      .split(",")
      .map((host) => host.trim())
      ?.filter((item) => item.trim() !== "");
    if (newHostDomains?.length == 0) {
      showToast(`Asset Required`, "error", "top-center", "colored");
      return;
    }
    newHostDomains.forEach((newHostDomain) => {
      const newHost = cleanDomainNew(newHostDomain);

      if (!newHost) {
        showToast(
          "Entered Assets are invalid",
          "error",
          "top-center",
          "colored"
        );
        return;
      }

      if (data?.hosts?.includes(newHost)) {
        showToast("Assets already added", "error", "top-center", "colored");
        return;
      }

      schema
        .validateAt("host", { host: newHost })
        .then(() => {
          let extractr = newHost.match(
            /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/im
          );
          let transformedHost = newHost;
          if (extractr && extractr.length > 0) {
            transformedHost = core.validCidrIp(newHost)
              ? newHost
              : extractr[1]?.toLowerCase();
          }

          if (data?.hosts.includes(transformedHost)) {
            showToast("Host already added", "error", "top-center", "colored");
            return;
          }

          setData?.setHosts((prevHosts) => [...prevHosts, transformedHost]);
        })
        .catch((error) => {
          showToast(error.message, "error", "top-center", "colored");
        });
    });

    setFieldValue("host", "");
  };

  const removeHost = (hostToRemove) => {
    setData?.setHosts((prevHosts) =>
      prevHosts.filter((host) => host !== hostToRemove)
    );
  };

  const handleInputChange = (e, onChange) => {
    const inputValue = e.target.value;

    if (inputValue.length > 1000) {
      if (!toastDisplayed) {
        setToastDisplayed(true);
        showToast(
          "No more than 1000 characters can be entered in the asset input.",
          "error",
          "top-center",
          "colored"
        );

        setTimeout(() => {
          setToastDisplayed(false);
        }, 3000);
      }
      return;
    }
    onChange(e);
  };

  const processedHosts = useMemo(() => {
    return data?.scan?.scanInfo?.hosts_list?.split(",")?.filter((host) => host);
  }, [data?.scan?.scanInfo?.hosts_list]);

  return (
    <>
      <Field
        name="host"
        render={({ field, form: { touched, errors }, meta }) => (
          <FormGroup controlId="host">
            <label>Enter Assets Separated By Commas</label>
            <FormControl
              {...field}
              type={"text"}
              size="lg"
              value={field.value}
              onChange={(e) => handleInputChange(e, field.onChange)}
              onKeyDown={(e) => {
                if (e.key === ",") {
                  e.preventDefault();
                  addHost(e, () => {
                    field.onChange({
                      target: { name: field.name, value: field.value + "," },
                    });
                  });
                }
                if (e.key === "Enter") {
                  e.preventDefault();
                  createChip(e, setData.setFieldValue);
                }
              }}
              placeholder="Root Domain, Hostname, Single IP Address, IP Range, IP Class"
              className={
                meta.touched && meta.error
                  ? "is-invalid text-white fw-lighter"
                  : "text-white fw-lighter"
              }
              ref={hostInputRef}
            />
            {meta.touched && meta.error ? (
              <div className="invalid-feedback">{meta.error}</div>
            ) : null}
          </FormGroup>
        )}
      />
   <div className="domain-chips mt-3" ref={domainChipsRef}>
        {hostsToDisplay?.map((host, index) => (
          <Badge key={index} className="badge-border my-1 px-2 me-2">
            <label className="p-2 label-for-chip">{host}</label>
            {!processedHosts?.some((scanHost) => scanHost === host) ? (
              <span
                className="px-0 pb-0 pt-0 ms-2 cursor-pointer"
                onClick={() => removeHost(host)}
              >
                <Xmark />
              </span>
            ) : null}
          </Badge>
        ))}
        {!showAllHosts && remainingHostsCount > 0 && (
          <div className="mt-2 ">
            <span
              className="view-more "
              onClick={() => setShowAllHosts(true)}
            >
              View all ({remainingHostsCount})
            </span>
          </div>
        )}
      </div>
      <div
        className="flex gap-3 align-items-center cursor-pointer mb-3 mt-3"
        onClick={handleClick}
      >
        <div
          className={`transition-transform duration-300 ${
            isOpen ? "rotate-90" : ""
          }`}
          style={{
            color: "rgba(255, 255, 255, 0.7)",
          }}
        >
          <ArrowDown
            height={12}
            width={12}
            className={`base-drop-down-arrow ${isOpen ? "open" : ""}`}
          />
        </div>
        <>Input Examples</>
      </div>
      {isOpen && (
        <div
          className={`transition-max-height duration-500 ease-in-out ${
            isOpen ? "max-h-64 opacity-100" : "max-h-0 opacity-0"
          } overflow-hidden`}
        >
          <ul className="input-example-container">
            <li>
              <div className="flex align-items-center gap-1 mb-1">
                <div className="font-500">Root Domain:</div> <div>e.g.</div>{" "}
                <div className="example-badge">example.com</div>
              </div>
            </li>
            <li>
              <div className="flex align-items-center gap-1 mb-1">
                <div className="font-500">Hostname:</div> <div>e.g.</div>{" "}
                <div className="example-badge">sub.example.com</div>
              </div>
            </li>
            <li>
              <div className="flex align-items-center gap-1 mb-1">
                <div className="font-500">Single IP Address:</div>{" "}
                <div>e.g.</div> <div className="example-badge">102.105.0.1</div>
              </div>
            </li>
            <li>
              <div className="flex align-items-center gap-1 mb-1">
                <div className="font-500">IP Range:</div> <div>e.g.</div>{" "}
                <div className="example-badge">102.105.0.1-102.105.0.10</div>
              </div>
            </li>
            <li>
              <div className="flex align-items-center gap-1">
                <div className="font-500">IP Class:</div> <div>e.g.</div>{" "}
                <div className="example-badge">102.105.0.0/24</div>
              </div>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default TargetInput;
