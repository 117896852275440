import React, { useEffect, useState } from "react";
import { Button, FormGroup, FloatingLabel, FormControl } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import logopng from "../../assets/images/logo.png";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../util/axios";
import * as fido2 from "@simplewebauthn/browser";
import { verifyAuth } from "../../helpers/client";
import { detectCountry } from "../../util/country";
import { Stack } from "react-bootstrap/esm";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import { ReactComponent as Logo1 } from "../../assets/images/logo1.svg";
import { ReactComponent as Logo2 } from "../../assets/images/logo2.svg";
import { locationToPageName } from "../../helpers";
import { sendEvent, sendUserId } from "../../util/analytics";
import { showToast } from "../../util/toasts";

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required")
    .test(
      "no-leading-dot",
      "Invalid email format",
      (value) => value && !/^\./.test(value)
    )
    .test(
      "no-consecutive-dots",
      "Invalid email format",
      (value) => value && !/\.{2,}/.test(value)
    )
    .test(
      "no-trailing-dot",
      "Invalid email format",
      (value) => value && !/\.$/.test(value)
    ),
  password: Yup.string().required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};

const Login = (props) => {
  const navigate = useNavigate();
  const [submitBtn, setSubmitBtn] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [processOTP, setProcessOTP] = useState(false);
  const [platformAuthAvailable, setPlatformAuthAvailable] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState();
  const [searchParams] = useSearchParams();
  const [isPopupShow, setIsPopupShow] = useState(false);
  const [country, setCountry] = useState("");
  const [serverError, setServerError] = useState("");
  const location = useLocation();

  var parsedData = null;
  let API_ENDPOINT_AUTH_NODE = "/webauthn/generate-authentication-options";
  let API_ENDPOINT_LOGIN_NODE = "/login";
  const handleOTP = () => {
    setIsLoading(true);

    let REQ_URL = "/resend-otp";
    axios
      .post(REQ_URL, { code: token })
      .then((response) => {
        setIsLoading(false);
        const data = response.data;
        if (data.message) {
          showToast(data.message, "success", "top-center", "light");
          setTimeout(() => {
            navigate("/auth/otp/" + token);
          }, 500);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  // Handle Biometric Auth
  const handleWebAuthN = async (data, helpers) => {
    let authResp;

    try {
      // Pass the options to the authenticator and wait for a response
      authResp = await fido2.startAuthentication(data.opts);
      if (data["code"]) {
        authResp.token = data["code"];
      }
      const vRes = await verifyAuth(authResp);
      localStorage.clear();
      localStorage.setItem("user-token", vRes.token);
      localStorage.setItem("user", JSON.stringify(vRes.data));
      setTimeout(() => {
        navigate("/");
      }, 500);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // Some basic error handling
      console.log("error", error);
      setToken(data.code);
      // handleOTP(helpers)
      // throw error;
    }
  };
  const processData = async (data, helpers) => {
    setIsLoading(false);
    console.log("data", data);
    if (data.status == 200 || data["is_invited"]) {
      showToast(
        "Please complete your registration",
        "success",
        "top-center",
        "info"
      );

      setTimeout(() => {
        navigate("/auth/register/" + data.invitation.code);
      }, 500);
    } else {
      if (data["opts"]) {
        await handleWebAuthN(data, helpers);
        setProcessOTP(true);
      } else {
        setTimeout(() => {
          navigate("/auth/otp/" + data.code);
        }, 500);
      }
    }
  };
  // Handle Form Submit
  const handleSubmit = async (values, helpers, actions) => {
    
    setIsLoading(true);
    values["platformAuth"] = platformAuthAvailable;
    setServerError("");

    if (!parsedData) {
      try {
        const response = await axios.post(API_ENDPOINT_LOGIN_NODE, values);
        parsedData = response.data;
        localStorage.clear();
        navigate("/auth/mfa", { state: { mfaCode: parsedData.code } });
        const pageName = locationToPageName(location);
        sendEvent("buttonClick", { pageName, result: "login" });
        sendUserId(parsedData.data.id);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        if (e.response.status === 422) {
          let errorField = e.response.data;
          // helpers.setFieldError(errorField?.path, errorField?.msg);
          setServerError(errorField?.msg);
        }
        if(!e.response.data.status){
      showToast(
        e.response?.data.message,
         "error",
         "top-center",
         "light",
         { 
           toastId: "error1",
          className: "toast-login-error",
          }
       );
          }
      }
    }
    // if (parsedData) {
    //     setToken(parsedData.code);
    //     processData(parsedData, helpers);
    // }
  };
  const init = async () => {
    if (await fido2.platformAuthenticatorIsAvailable()) {
      /**
       * Prompt the user to use Touch ID/Windows Hello/etc... or security keys to register or
       * authenticate
       *
       * How to decide which name to show for the device's platform authenticator is an exercise
       * left up to the developer (your best bet is User Agent analysis)
       */
      setPlatformAuthAvailable(true);
    }

    setCountry(await detectCountry());
  };
  useEffect(() => {
    //passing getData method to the lifecycle method
    init();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (isPopupShow && searchParams.get("iu") === 1) {
        showToast(
          "Invitation has already been used, Please Login",
          "warn",
          "top-center",
          "light"
        );
      }
      // navigate("/auth/login?iu=1");
    }, 500);
    setIsPopupShow(1);
  }, [isPopupShow]);

  useEffect(() => {
    if (isLoading) {
      setSubmitBtn({ disabled: true, label: "Please wait.." });
    } else {
      setSubmitBtn({
        disabled: false,
        label: processOTP ? "Biometric Verification" : "Login",
      });
    }
  }, [isLoading]);
  return (
    <React.Fragment>
      {/* new design */}
      <div className="login-fragment">
        <div className="login-main">
        <img src={logopng} alt="Logo" className="mt-3 d-block mx-auto img-fluid logo-img" /> 
          <h2 className="mt-4 text-center ">Welcome to Armory </h2>
          <div className="login-form">
            <div className="login-input">
              <Formik
                validationSchema={schema}
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validateOnBlur={false}
                render={({
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  values,
                  errors,
                  validateForm,
                }) => (
                  <Form>
                    <Field
                      name="email"
                      render={({ field, form: { touched, errors }, meta }) => (
                        <FormGroup className="mt-3 " controlId="email">
                          <label controlId="floatingPassword">Email</label>
                          <FormControl
                            type={"text"}
                            size="lg"
                            value={field.value}
                            onChange={field.onChange}
                            placeholder="Enter your email "
                            className={
                              meta.touched && meta.error ? "is-invalid" : ""
                            }
                            {...field}
                          />
                          {meta.touched && meta.error ? (
                            <div className="invalid-feedback">{meta.error}</div>
                          ) : null}
                        </FormGroup>
                      )}
                    />
                    <Field name="password">
                      {({ field, form: { touched, errors }, meta }) => (
                        <FormGroup
                          className="mt-3 position-relative"
                          controlId="password"
                        >
                          <label>Password</label>
                          <FormControl
                            type={showPassword ? "text" : "password"}
                            size="lg"
                            {...field}
                            placeholder="Enter your password"
                            isInvalid={meta.touched && meta.error}
                          />
                          <span
                            onClick={() => setShowPassword(!showPassword)}
                            className="password-eye-position mt-3 mr-2"
                            style={{ cursor: "pointer", marginRight: "10px" }}
                          >
                            {showPassword ? <EyeSlashFill /> : <EyeFill />}
                          </span>
                          {meta.touched && meta.error && (
                            <div className="invalid-feedback">{meta.error}</div>
                          )}
                        </FormGroup>
                      )}
                    </Field>

                    {processOTP ? (
                      <Button
                        onClick={handleOTP}
                        className="mt-5 btn bg-primary btn-full my-3"
                        id="singup-btn"
                        disabled={submitBtn.disabled}
                      >
                        <i className="bi bi-chat-square-dots m-2"></i>
                        Send Me An OTP
                      </Button>
                    ) : (
                      ""
                    )}
                    <div className="mt-5">
                      {serverError && (
                        <div className="error-msg"> {serverError}</div>
                      )}
                      <Button
                        type="submit"
                        className=" btn bg-primary btn-full mx-auto my-3"
                        id="singup-btn"
                        disabled={submitBtn.disabled}
                      >
                        {platformAuthAvailable && processOTP ? (
                          <i className="bi bi-fingerprint m-2"></i>
                        ) : (
                          ""
                        )}
                        {submitBtn.label}
                      </Button>
                    </div>

                    <div className=" mt-1 mb-2 d-flex justify-content-center login-armory-forget">
                      <span
                        className="cursor-pointer text-decoration-underline"
                        style={{
                          color: "rgba(57, 143, 254, 1)",
                          textDecoration: "underline",
                        }}
                        onClick={() => navigate("/auth/forgot-password")}
                      >
                        Forgot Password?
                      </span>
                    </div>
                    <div className="loginLogos">
                      <Logo1 height="40px" width="90px" />
                      <Logo2 height="40px" width="90px" />
                    </div>
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
      </div>

      {/* old login */}

      {/* <div>
                <h2 className="my-5 text-center ">Welcome to Armory </h2>
                <Formik
                    validationSchema={schema}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validateOnBlur={false}
                    render={({
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        values,
                        errors,
                        validateForm,
                    }) => (
                        <Form>
                            <Stack gap={4}>
                                <Field
                                    name="email"
                                    render={({ field, form: { touched, errors }, meta }) => (
                                        <FormGroup controlId="email">
                                            <FloatingLabel
                                                controlId="floatingPassword"
                                                label="Enter your email"
                                            >
                                                <FormControl
                                                    type={"text"}
                                                    size="xxlg"
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    className={
                                                        meta.touched && meta.error ? "is-invalid" : ""
                                                    }
                                                    {...field}
                                                />
                                                {meta.touched && meta.error ? (
                                                    <div className="invalid-feedback">{meta.error}</div>
                                                ) : null}
                                            </FloatingLabel>
                                        </FormGroup>
                                    )}
                                />

                                <Field
                                    name="password"
                                    render={({ field, form: { touched, errors }, meta }) => (
                                        <FormGroup controlId="password">
                                            <FloatingLabel
                                                controlId="floatingPassword"
                                                label="Enter your password"
                                            >
                                                <FormControl
                                                    type={"password"}
                                                    size="xxlg"
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    className={
                                                        meta.touched && meta.error ? "is-invalid" : ""
                                                    }
                                                    {...field}
                                                />
                                                {meta.touched && meta.error ? (
                                                    <div className="invalid-feedback">{meta.error}</div>
                                                ) : null}
                                            </FloatingLabel>
                                        </FormGroup>
                                    )}
                                />



                                {processOTP ? (
                                    <Button
                                        onClick={handleOTP}
                                        className="btn bg-primary btn-full my-3"
                                        id="singup-btn"
                                        disabled={submitBtn.disabled}
                                    >
                                        <i className="bi bi-chat-square-dots m-2"></i>
                                        Send Me An OTP
                                    </Button>
                                ) : (
                                    ""
                                )}
                                <Button
                                    type="submit"
                                    className="btn bg-primary btn-full mx-auto my-3"
                                    id="singup-btn"
                                    disabled={submitBtn.disabled}
                                >
                                    {platformAuthAvailable && processOTP ? (
                                        <i className="bi bi-fingerprint m-2"></i>
                                    ) : (
                                        <i className="bi bi-shield m-2"></i>
                                    )}
                                    {submitBtn.label}
                                </Button>
                                <div className="d-flex justify-content-end login-armory-forget" >
                                    <span className="cursor-pointer text-decoration-underline" onClick={() => navigate('/auth/forgot-password')}>Forgot Password?</span>
                                </div>

                            </Stack>
                        </Form>
                    )}
                />
            </div> */}
    </React.Fragment>
  );
};

export default Login;
