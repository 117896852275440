import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { UseServices } from "./useServices";

export const useScanningRoutes = () => {
  const location = useLocation();
  const { allServices, isScannerStop } = UseServices();

  const { showBreadcrumb, assetsRoute, scanningStatus, scanningUpdatedAt } =
    useMemo(() => {
      const showBreadcrumb =
        location?.pathname?.includes("/scanner") ||
        location?.pathname?.includes("/offense") ||
        (location?.pathname?.includes("/assets-inventory") &&
          !location?.pathname?.includes("/new") &&
          !location?.pathname?.includes("/edit")) ||
        location?.pathname?.includes("/cyber-services");

      const scannerRoute = location?.pathname?.includes("/scanner");
      const assetsRoute =
        location?.pathname?.includes("/assets-inventory") &&
        !location?.pathname?.includes("/new") &&
        !location?.pathname?.includes("/edit");

      let scanningStatus = null;
      let scanningUpdatedAt = null;

      if (scannerRoute) {
        const key = location?.pathname?.split("/")?.[3];
        const filteredScanner = allServices?.find((el) =>
          el.key?.includes(`/${key}`)
        );
        scanningStatus = filteredScanner?.status;
        scanningUpdatedAt = filteredScanner?.updated_at;
      } else if (assetsRoute) {
        const key = location?.pathname?.split("/")?.[1];
        const filteredScanner = allServices?.find((el) =>
          el.key?.includes(`/${key}`)
        );
        scanningStatus = isScannerStop ? 4 : filteredScanner?.status;
        scanningUpdatedAt = filteredScanner?.updated_at;
      }

      return {
        showBreadcrumb,
        assetsRoute,
        scanningStatus,
        scanningUpdatedAt,
      };
    }, [location.pathname, allServices]);

  return {
    showBreadcrumb,
    assetsRoute,
    scanningStatus,
    scanningUpdatedAt,
  };
};
