import React, { useRef, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Overview from "./overview/Overview";
import moment from "moment";
import { useParams } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";
import LogoAnim from "../../assets/animation/Animation-logo.mp4";
import AssetsScanStats from "./AnimatedAssetsStats";
import LogsOutput from "../../components/web-scanner/PointerScanLogs";
import { Navbar, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import AssetDiscovery from "./asset-discovery/AssetDiscovery";
import AssetsInventory from "./asset-inventory/AssetInventory";
import { getUserRole } from "../../util/userUtils";

const AssetsIntelligence = () => {
  const { id } = useParams();
  const location = useLocation();
  const [scanStatus, setScanStatus] = useState(0);
  const [scanningData, setScanningData] = useState([]);
  const [stats, setStats] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const videoRef = useRef(null);
  const [isActiveLink, setIsActiveLink] = useState("Overview");
  const userRole = getUserRole();

  const handleStatsFetch = (data) => {
    setStats(data);
  };
  const updateScanningData = (scanData) => {
    setScanningData([]);
    let logsData = [];
    if (scanData?.logs) {
      logsData = scanData.logs.map((log) => {
        return {
          type: moment(log.updated_at).isValid()
            ? moment(log.updated_at).format("YYYY-MM-DD HH:mm:ss")
            : "Invalid date",
          message: log.log,
          isTimestamp: log.hide_timestamp,
          isDivider: log.has_divider,
        };
      });
    }
    if (scanningData.length < logsData.length) {
    }
    setScanningData(logsData);
  };

  const handelWebScanner = (data) => {
    if (data?.logs) {
      updateScanningData(data);
    }
  };

  const servicesStatusCheck = (status) => {
    setScanStatus(status);
  };

  useEffect(() => {
    const video = videoRef.current;
    const handleTimeUpdate = () => {
      if (video && video.currentTime >= video.duration - 1) {
        video.pause();
      }
    };

    if (video) {
      video.play().catch((error) => {
        console.log(error);
      });

      video.addEventListener("timeupdate", handleTimeUpdate);
    }

    return () => {
      if (video) {
        video.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (!isLoading && video) {
      video.currentTime = 0;
      video.play().catch((error) => {
        console.log(error);
      });
    }
  }, [isLoading]);

  useEffect(() => {
    location?.search?.includes("tab=1")
      ? setIsActiveLink("Asset Inventory")
      : setIsActiveLink("Overview");
  }, [id]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");
    if (tab === "2") {
      setIsActiveLink("Asset Inventory");
    } else {
      setIsActiveLink("Overview");
    }
  }, [location.search]);

  return (
    <React.Fragment>
      {isLoading && (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      )}
      <div style={{ display: isLoading ? "none" : "block" }}>
        <Navbar className="border-bottom mt-4">
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto ms-3">
              <Link
                className={`disable-link-color discovery-nav-text text-white ${
                  isActiveLink === "Overview" ? "discovery-active-nav-text" : ""
                }`}
                onClick={() => {
                  setIsActiveLink("Overview");
                }}
              >
                Overview
              </Link>
              <Link
                className={`disable-link-color discovery-nav-text text-white ${
                  isActiveLink === "Asset Inventory"
                    ? "discovery-active-nav-text"
                    : ""
                }`}
                style={{
                  marginLeft: "1.5rem",
                }}
                onClick={() => {
                  setIsActiveLink("Asset Inventory");
                }}
              >
                Asset Inventory
              </Link>
              <Link
                className={`disable-link-color discovery-nav-text text-white ${
                  isActiveLink === "Asset Discovery"
                    ? "discovery-active-nav-text"
                    : ""
                }`}
                style={{
                  marginLeft: "1.5rem",
                }}
                onClick={() => {
                  setIsActiveLink("Asset Discovery");
                }}
              >
                Asset Discovery
              </Link>
              <Link
                className={`disable-link-color discovery-nav-text text-white ${
                  isActiveLink === "Asset Archive"
                    ? "discovery-active-nav-text"
                    : ""
                }`}
                style={{
                  marginLeft: "1.5rem",
                }}
                onClick={() => {
                  setIsActiveLink("Asset Archive");
                }}
              >
                Asset Archive
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {(isActiveLink === "Overview" || isActiveLink === "Asset Archive") && (
          <div className="py-4 px-4">
            <>
              {isActiveLink === "Overview" && (
                <div className="animation-section">
                  <div className="video-container">
                    <video
                      ref={videoRef}
                      src={LogoAnim}
                      width="400px"
                      height="400px"
                      loop
                      muted
                    />
                  </div>
                  <div className="logs-output">
                    <LogsOutput data={scanningData} isType={false} />
                  </div>
                  {!isLoading && <AssetsScanStats stats={stats} />}
                </div>
              )}
              <Overview
                setScanStatus={servicesStatusCheck}
                webScanner={handelWebScanner}
                onStatsFetch={handleStatsFetch}
                setIsLoading={setIsLoading}
                isActiveLink={isActiveLink}
                isUser={userRole !== "viewer"}
                canEdit={userRole !== "viewer" && userRole !== "editor"}
              />
            </>
          </div>
        )}
        {isActiveLink === "Asset Inventory" && <AssetsInventory />}
        {isActiveLink === "Asset Discovery" && (
          <AssetDiscovery
            scanStatus={scanStatus}
            setScanStatus={setScanStatus}
            isUser={userRole !== "viewer"}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default AssetsIntelligence;
