import React, { useState, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import { ReactComponent as Firstarrow } from "../../assets/images/Firstarrow.svg";
import { ReactComponent as Lastarrow } from "../../assets/images/Lastarrow.svg";
import { ReactComponent as Nextarrow } from "../../assets/images/Prevarrow.svg";
import { ReactComponent as Prevarrow } from "../../assets/images/Nextarrow.svg";
import { ReactComponent as Downpagination } from "../../assets/images/downpagination.svg";

const BasePagination = ({
  currentPage,
  totalPages,
  recordsPerPage,
  dataLength,
  handlePageChange,
  handelPerPage,
}) => {
  const getPaginationItems = () => {
    const items = [];
    const visiblePages = 3;

    const startPage = Math.max(1, currentPage - visiblePages);
    const endPage = Math.min(totalPages, currentPage + visiblePages);

    if (startPage > 1) {
      items.push(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          1
        </Pagination.Item>
      );
      if (startPage > 2) {
        items.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }
    }

    for (let page = startPage; page <= endPage; page++) {
      items.push(
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        items.push(<Pagination.Ellipsis key="end-ellipsis" />);
      }
      items.push(
        <Pagination.Item
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return items;
  };

  const [selectedItem, setSelectedItem] = useState(recordsPerPage.toString());
  useEffect(() => {
    setSelectedItem(recordsPerPage.toString());
  }, [recordsPerPage]);

  const handleSelect = (eventKey) => {
    setSelectedItem(eventKey);
    handelPerPage(Number(eventKey));
  };
  const currentRecordsEnd = Math.min(dataLength, recordsPerPage);
  return (
    <Pagination className="justify-content-between custom-pagination">
      <div>
        <span className="pagination-page-text">
          {currentRecordsEnd} results
        </span>
      </div>
      <div className="d-flex align-items-center">
        <Pagination.First
          onClick={() => handlePageChange(1)}
          disabled={currentPage === 1}
        >
          <Firstarrow />
        </Pagination.First>
        <Pagination.Prev
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <Prevarrow />
        </Pagination.Prev>

        {getPaginationItems()}

        <Pagination.Next
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <Nextarrow />
        </Pagination.Next>
        <Pagination.Last
          onClick={() => handlePageChange(totalPages)}
          disabled={currentPage === totalPages}
        >
          <Lastarrow />
        </Pagination.Last>
      </div>
      <div className="select d-flex align-items-center">
        <span className="me-3 pagination-page-text">Items Per Page:</span>
        <Dropdown onSelect={handleSelect}>
          <Dropdown.Toggle
            className="select-page d-flex align-items-center justify-content-center"
            id="dropdown-basic"
          >
            <span className="me-2">{selectedItem}</span> <Downpagination />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item className="text-white" eventKey="10">
              10
            </Dropdown.Item>
            <Dropdown.Item className="text-white" eventKey="25">
              25
            </Dropdown.Item>
            <Dropdown.Item className="text-white" eventKey="50">
              50
            </Dropdown.Item>
            <Dropdown.Item className="text-white" eventKey="100">
              100
            </Dropdown.Item>
            <Dropdown.Item className="text-white" eventKey="125">
              125
            </Dropdown.Item>
            <Dropdown.Item className="text-white" eventKey="150">
              150
            </Dropdown.Item>
            <Dropdown.Item className="text-white" eventKey="200">
              200
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Pagination>
  );
};
export default BasePagination;
