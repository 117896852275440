export const circularChartoptions = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: "80%",
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Secured vs Unsecured Email Configuration",
      color: "white",
      font: {
        family: "Kodchasan",
        size: 24,
      },
    },
  },
  onHover: (event, elements) => {
    event.native.target.style.cursor = elements.length ? "pointer" : "default";
  },
};

export const barChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        color: "white",
        padding: 30,
        fillStyle: "none",
        usePointStyle: true,
        pointStyle: "rectRounded",
        boxSize: 0,
        boxWidth: 0,
        font: {
          family: "Kodchasan",
        },
      },
    },
    tooltip: {
      backgroundColor: "white",
      titleColor: "black",
      bodyColor: "black",
      padding: 20,
      yPadding: 90,
      callbacks: {
        title: function () {
          return "";
        },
        label: function (context) {
          const label = context.dataset.label || "";
          const value = context.raw;
          return `${label}: ${value}`;
        },
      },
      yAlign: "bottom",
      xAlign: "center",
      titleFont: {
        family: "Kodchasan",
      },
      bodyFont: {
        family: "Kodchasan",
      },
    },
    datalabels: {
      color: "white",
      anchor: "end",
      align: "top",
      formatter: (value, context) => {
        return value;
      },
      font: {
        family: "Kodchasan",
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        color: "white",
        precision: 0,
        font: {
          family: "Kodchasan",
        },
      },
      border: { dash: [4, 4], borderColor: "black" },
      grid: {
        color: "rgba(255, 255, 255, 0.1)",
        borderDash: [10, 10],
      },
    },
    x: {
      ticks: {
        color: "white",
        font: {
          family: "Kodchasan",
        },
      },
      display: true,
      grid: {
        color: "transparent",
        drawOnChartArea: true,
        borderDash: [10, 10],
        borderDashOffset: function (context) {
          return context.index === context.chart.data.labels.length ? 0 : 10;
        },
      },
      border: {
        color: "#959595",
        width: 1,
      },
    },
  },
  layout: {
    padding: {
      top: 30,
      bottom: -40, // Adjust padding to your liking
    },
  },
  onHover: (event, elements) => {
    event.native.target.style.cursor = elements.length ? "pointer" : "default";
  },
};

export const doughnutChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: "70%",
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "white",
      titleColor: "black",
      bodyColor: "black",
      callbacks: {
        label: function (context) {
          const label = context.dataset.label || "";
          const value = context.raw;
          return `${label}: ${value}`;
        },
      },
      yAlign: "bottom",
      xAlign: "center",
    },
    title: {
      display: false,
    },
    centerText: {
      display: true,
      text: "70",
      color: "#FF0000",
      fontStyle: "bold",
      fontSize: 24,
    },
  },
  elements: {
    arc: {
      borderWidth: 0,
      borderColor: "transparent",
    },
  },
  onHover: (event, elements) => {
    event.native.target.style.cursor = elements.length ? "pointer" : "default";
  },
};
