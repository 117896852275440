import React, { useState, useEffect, useRef } from "react";
import logopng from "../../assets/images/logo.png";
import OtpInput from "react-otp-input";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../../util/axios";
import { showToast,ToastContent } from "../../util/toasts";
import { Form } from "formik";

const MFA = () => {
  const [code, setCode] = useState("");
  const [attempts, setAttempts] = useState(1);
  const maxAttempts = 5;
  const [resendTimer, setResendTimer] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [resendCount, setResendCount] = useState(0);
  const [isVerifying, setIsVerifying] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const mfaCode = location.state?.mfaCode;

  const handleVerify = async (event) => {
    event.preventDefault();
    const REQ_URL = "/verify-otp-email";
    const payload = {
      code: mfaCode,
      otp: code,
    };
    localStorage.clear();
    setIsVerifying(true);

    try {
      const response = await axios.post(REQ_URL, payload);
      localStorage.setItem("user-token", response.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.data));
      setTimeout(() => {
        navigate("/");
        fetchTargets();
      }, 500);
    } catch (e) {
      console.error("Verification failed:", e.response?.data);
      setAttempts(e.response?.data?.attempts);
      setErrorMessage(e.response?.data?.msg);
    } finally {
      setIsVerifying(false);
    }
  };

  const fetchTargets = async () => {
    try {
      const { data } = await axios.get(`targets`);
      if (data?.items?.length > 0) {
        const firstItemId = data.items[0].target_uuid;
        navigate(`/dashboard/${firstItemId}`);
      } else {
        navigate(`/assets-inventory/new`);
      }
    } catch (err) {}
  };

  const handleResendCode = async () => {

    if (resendTimer > 0) return;

    const REQ_URL = "/resend-otp-email";
    const payload = {
      code: mfaCode,
    };

    try {
      const response = await axios.post(REQ_URL, payload);
      showToast(
        <ToastContent
        title="Verification code has been resent"
        subtitle="Please check your inbox for the new verification code"
      />,
        "success",
        "top-center",
        "light",
        {
          className: "toast-success",
        }
      );
      setResendTimer(60);
      setCode("");
      setErrorMessage("");
    } catch (e) {
      if(!e.response?.data.status){
      setResendTimer(e.response?.data.timeLeft);
      }
    }
  };

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendTimer]);

  const returnToLogin = () => {
    navigate(`/auth/login`);
  };
  useEffect(() => {
    if (!mfaCode) {
      returnToLogin();
    }
  }, [mfaCode, navigate]);
  useEffect(() => {
    if (attempts >= maxAttempts) {
      const currentURL = window.location.pathname;
      const newURL = `${currentURL}?isReturn=true`;
      window.history.replaceState(null, "", newURL);
    }
  }, [attempts, maxAttempts]);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isReturn = queryParams.get("isReturn");

    if (isReturn === "true") {
      navigate(`/auth/login`);
    }
  }, [location.search, navigate]);

  return (
    <form className="mfa-fragment" onSubmit={handleVerify}>
      <div className="mfa-main">
        <img
          src={logopng}
          alt="Logo"
          className="mt-3 d-block mx-auto img-fluid logo-img"
        />
        <h2 className="my-3 text-center form-title">
          Enter The Code Sent To Your Email
        </h2>

        <div className={`mfa-input ${errorMessage ? "error-input" : ""}`}>
          <OtpInput
            className="otp-container"
            value={code}
            onChange={setCode}
            numInputs={6}
            inputType="number"
            renderSeparator={<span></span>}
            renderInput={(props) => <input {...props} className="otp-input" />}
          />
          <section className="justify-content-between d-flex align-items-center error-section-container">
            {errorMessage && (
              <p className="mfa-error-message p-0" style={{ width: "275px" }}>
                {errorMessage}
              </p>
            )}
            {(attempts > 0 && attempts < maxAttempts && !errorMessage) ||
              (errorMessage && (
                <p className="attempts-info mfa-error-message p-0">{`${attempts}/${maxAttempts} attempts`}</p>
              ))}
          </section>
          {attempts >= maxAttempts ? (
            <button
              className="btn btn-primary w-100 mt-3"
              onClick={returnToLogin}
            >
              Return To Login
            </button>
          ) : (
            <>
              <button
                className="btn btn-primary ms-0 verify-btn"
                type="submit"
                disabled={code.length !== 6 || resendCount >= 6}
              >
                {isVerifying ? "Please wait..." : "Verify"}
              </button>
              <div className="text-center">
                <p
                  className={`mt-3 resend-code ms-0 w-auto cursor-pointer ${
                    resendTimer > 0 ? "resend-code-disable" : ""
                  }`}
                  onClick={handleResendCode}
                >
                  Resend code
                </p>
              </div>
            </>
          )}
          {resendTimer > 0 && attempts < maxAttempts && (
            <section className="text-center my-2 form-footer-container">
              <div>
                <span>Code can be resent after a minute</span>
              </div>
              <div>
                <span>
                  {" "}
                  00:{resendTimer < 10 ? `0${resendTimer}` : resendTimer}
                </span>
              </div>
            </section>
          )}
        </div>
        <hr className="hr-line" />
        <p className="mt-4 text-left form-footer-container">
          Unable to receive your verification code? <br />
          Contact our support at{" "}
          <a href="mailto:office@armorydefense.com">office@armorydefense.com</a>
        </p>
      </div>
    </form>
  );
};

export default MFA;
