import React, { useState } from "react";
import BaseCheckbox from "../../../components/form/BaseCheckbox";
import { ReactComponent as CheckIcon } from "../../../assets/images/check-white.svg";

const ScanDomains = ({ domain, isChecked, onCheckboxChange, onOpenModal,isUser }) => {
  const [isButtonActive, setIsButtonActive] = useState(false);

  const handleButtonFocus = () => {
    setIsButtonActive(true);
  };

  const handleButtonBlur = () => {
    setIsButtonActive(false);
  };

  return (
    <div className="scan-domains-component">
      <div className="scan-domain-text">
        {isUser && (
          <BaseCheckbox
            height={18}
            type="checkbox"
            name={domain}
            checked={isChecked}
            onChange={onCheckboxChange}
            label=""
            className="mt-1 base-checkbox"
          />
          )} 

        <span>{domain}</span>
      </div>
      <div
  className={`scan-domains-button ${isButtonActive ? "active" : "non-active"} ${
    !isUser ? "disabled-user-view" : ""
  }`}
  onClick={() => isUser && onOpenModal(domain)}
  onFocus={isUser ? handleButtonFocus : undefined}
  onBlur={isUser ? handleButtonBlur : undefined}
  tabIndex={isUser ? "-1" : "0"}
>
  <CheckIcon height={18} />
  <span>Scan</span>
</div>


    </div>
  );
};

export default ScanDomains;