import React from "react";
import Card from "react-bootstrap/Card";
import { ReactComponent as HighRisk } from "../../assets/images/high-risk.svg";
import { ReactComponent as ModerateRisk } from "../../assets/images/moderate-risk.svg";
import { ReactComponent as LowRisk } from "../../assets/images/low-risk.svg";
import { useNavigate, useParams } from "react-router-dom";
import AnimatedNumber from "../../components/animated-numbers/AnimatedNumbers";

const SecurityIssuesCard = ({ highRisk, mediumRisk, lowRisk }) => {
  const navigate = useNavigate();
  const { target_id } = useParams();

  const handleRedirectIssues = (risk) => {
    const risScore =
      risk == "high" ? "80-100" : risk == "medium" ? "40-79" : "0-39";
    navigate(`/security-issues/${target_id}?risk_score=${risScore}`);
  };

  return (
    <React.Fragment>
      <Card
        style={{
          width: "100%",
          color: "white",
          border: "none",
        }}
        className="SecurityIssues-main-card"
      >
        <Card.Body
          className="pt-0"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "24px",
            padding: 0,
          }}
        >
          <div
            className="SecurityIssues_content"
            style={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <HighRisk style={{ width: "24px", height: "24px" }} />
            <div
              className="fs-5 text-highlights-title text-decoration-underline cursor-pointer"
              onClick={() => handleRedirectIssues("high")}
              style={{
                fontSize: "16px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
                gap: "4px",
                cursor: "pointer",
              }}
            >
              High Risk |
              <AnimatedNumber value={highRisk || 0} />
            </div>
          </div>

          <div
            className="SecurityIssues_content"
            style={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <ModerateRisk style={{ width: "24px", height: "24px" }} />
            <div
              className="fs-5 text-highlights-title text-decoration-underline cursor-pointer"
              onClick={() => handleRedirectIssues("medium")}
              style={{
                fontSize: "16px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
                gap: "4px",
                cursor: "pointer",
              }}
            >
              Medium Risk |
              <AnimatedNumber value={mediumRisk || 0} />
            </div>
          </div>

          <div
            className="SecurityIssues_content"
            style={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <LowRisk style={{ width: "24px", height: "24px" }} />
            <div
              className="fs-5 text-highlights-title text-decoration-underline cursor-pointer"
              onClick={() => handleRedirectIssues("low")}
              style={{
                fontSize: "16px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
                gap: "4px",
                cursor: "pointer",
              }}
            >
              Low Risk | <AnimatedNumber value={lowRisk || 0} />
            </div>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default SecurityIssuesCard;
