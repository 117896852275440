import React, { useState, useEffect, useRef } from "react";
import { Form, FormCheck, Card } from "react-bootstrap";
import { ReactComponent as FaSearch } from "../../assets/images/search.svg";
import { colors } from "../../util/colors";

const NestedDropdown = ({
  allOptions,
  onSelectOptions,
  setSelectedOptions,
  selectedOptions,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isAllSelected, setIsAllSelected] = useState(false);
  const checkboxRefs = useRef({});

  const getAllValues = (options) => {
    return options.flatMap((opt) =>
      opt.children ? [opt.id, ...getAllValues(opt.children)] : opt.id
    );
  };

  const getParents = (options, childValue, parents = []) => {
    for (const option of options) {
      if (option.children?.some((child) => child.id === childValue)) {
        parents.push(option.id);
        getParents(options, option.id, parents);
      }
      if (option.children) {
        getParents(option.children, childValue, parents);
      }
    }
    return parents;
  };

  const getChildren = (option) => {
    if (!option.children) return [];
    return option.children.flatMap((child) => [
      child.id,
      ...getChildren(child),
    ]);
  };

  const findOption = (options, value) => {
    for (const option of options) {
      if (option.id === value) return option;

      if (option.children) {
        const found = findOption(option.children, value);
        if (found) return found;
      }
    }
    return null;
  };

  const handleChange = (value) => {
    setSelectedOptions((prevSelected) => {
      let newSelection = new Set(prevSelected);

      if (newSelection.has(value)) {
        newSelection.delete(value);
        const option = findOption(allOptions, value);
        if (option) {
          getChildren(option).forEach((child) => newSelection.delete(child));
        }

        let parents = getParents(allOptions, value);
        parents.forEach((parent) => {
          const parentOption = findOption(allOptions, parent);
          if (
            parentOption &&
            parentOption.children.every((child) => !newSelection.has(child.id))
          ) {
            newSelection.delete(parent);
          }
        });

        parents.reverse().forEach((parent) => {
          const parentParents = getParents(allOptions, parent);
          parentParents.forEach((pParent) => {
            const pParentOption = findOption(allOptions, pParent);
            if (
              pParentOption &&
              pParentOption.children.every(
                (child) => !newSelection.has(child.id)
              )
            ) {
              newSelection.delete(pParent);
            }
          });
        });
      } else {
        newSelection.add(value);

        let parents = getParents(allOptions, value);
        parents.forEach((parent) => {
          newSelection.add(parent);
          const parentParents = getParents(allOptions, parent);
          parentParents.forEach((pParent) => newSelection.add(pParent));
        });
      }

      return Array.from(newSelection);
    });
  };

  useEffect(() => {
    setIsAllSelected(
      selectedOptions.length === getAllValues(allOptions).length
    );

    const updateParentStates = (parentIds) => {
      parentIds.forEach((parentId) => {
        const parentCheckbox = checkboxRefs.current[parentId];
        const parentOption = findOption(allOptions, parseInt(parentId));

        if (parentCheckbox && parentOption?.children.length > 0) {
          const selectedChildren = parentOption.children.filter((child) =>
            selectedOptions.includes(child.id)
          ).length;

          if (
            selectedChildren > 0 &&
            selectedChildren < parentOption.children.length
          ) {
            parentCheckbox.indeterminate = true;
            parentCheckbox.checked = false;
            parentCheckbox.disabled = true;
          } else if (selectedChildren === parentOption.children.length) {
            parentCheckbox.indeterminate = false;
            parentCheckbox.checked = true;
            parentCheckbox.disabled = false;
          } else {
            parentCheckbox.indeterminate = false;
            parentCheckbox.checked = false;
            parentCheckbox.disabled = false;
          }

          const grandParents = getParents(allOptions, parseInt(parentId));
          if (grandParents.length > 0) {
            updateParentStates(grandParents);
          }
        }
      });
    };

    Object.keys(checkboxRefs.current).forEach((key) => {
      const checkbox = checkboxRefs.current[key];
      if (checkbox) {
        const option = findOption(allOptions, parseInt(key));
        if (option?.children.length > 0) {
          const selectedChildren = option.children.filter((child) =>
            selectedOptions.includes(child.id)
          ).length;

          if (
            selectedChildren > 0 &&
            selectedChildren < option.children.length
          ) {
            checkbox.indeterminate = true;
            checkbox.checked = false;
            checkbox.disabled = true;
          } else if (selectedChildren === option.children.length) {
            checkbox.indeterminate = false;
            checkbox.checked = true;
            checkbox.disabled = false;
          } else {
            checkbox.indeterminate = false;
            checkbox.checked = false;
            checkbox.disabled = false;
          }

          const parentIds = getParents(allOptions, parseInt(key));
          if (parentIds.length > 0) {
            updateParentStates(parentIds);
          }
        } else {
          checkbox.indeterminate = false;
          checkbox.checked = selectedOptions.includes(parseInt(key));
          checkbox.disabled = false;
        }
      }
    });
  }, [selectedOptions]);

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(getAllValues(allOptions));
    }
  };

  const handleClearAll = () => {
    setSelectedOptions([]);
  };

  useEffect(() => {
    onSelectOptions(selectedOptions);
  }, [selectedOptions]);

  const renderOptions = (options) => {
    const filterOptions = (options) => {
      return options
        .map((option) => {
          if (option.title.toLowerCase().includes(searchTerm.toLowerCase())) {
            return option;
          }

          if (option.children) {
            const filteredChildren = filterOptions(option.children);
            if (filteredChildren.length > 0) {
              return {
                ...option,
                children: filteredChildren,
              };
            }
          }

          return null;
        })
        .filter(Boolean);
    };

    const filteredOptions = searchTerm ? filterOptions(options) : options;

    return filteredOptions.map((option) => (
      <Card
        key={option.id}
        style={{
          background: colors["bg-black"],
          color: colors["white"],
          border: "none",
          padding: "7px 12px 0px 12px",
        }}
      >
        <Card.Header
          style={{
            background: colors["bg-black"],
            border: "none",
            padding: "2px 12px",
          }}
        >
          <FormCheck
            type="checkbox"
            label={option.title}
            checked={selectedOptions.includes(option.id)}
            onChange={() => handleChange(option.id)}
            ref={(el) => (checkboxRefs.current[option.id] = el)}
            style={{
              color: colors["white"],
              cursor: checkboxRefs.current[option.id]?.indeterminate
                ? "not-allowed"
                : "pointer",
              opacity: 1,
            }}
            aria-disabled={checkboxRefs.current[option.id]?.indeterminate}
            disabled={false}
          />
        </Card.Header>
        <Card.Body style={{ paddingTop: "3px", paddingBottom: "3px" }}>
          {option.children && renderOptions(option.children)}
        </Card.Body>
      </Card>
    ));
  };

  return (
    <div
      style={{
        background: colors["bg-black"],
        padding: "10px",
        borderRadius: "8px",
        border: `1px solid ${colors["border-blue"]}`,
        marginTop: "25px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          background: colors["bg-black"],
          padding: "3px 10px",
          borderRadius: "8px",
          border: `1px solid ${colors["white"]}`,
          width: "100%",
          position: "relative",
          margin: "10px 0px",
        }}
      >
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            background: "transparent",
            border: "none",
            color: colors["white"],
            outline: "none",
            width: "100%",
            padding: "8px 12px",
            fontSize: "14px",
          }}
        />
        <FaSearch
          style={{
            color: colors["white"],
            position: "absolute",
            right: "10px",
            cursor: "pointer",
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          padding: "10px 0",
          borderBottom: `1px solid ${colors["border-gray"]}`,
        }}
      >
        <FormCheck
          type="checkbox"
          label="Select All"
          checked={isAllSelected}
          onChange={handleSelectAll}
        />
        <span className="text-white">|</span>
        <span
          style={{ color: colors["border-blue"], cursor: "pointer" }}
          onClick={handleClearAll}
        >
          Clear all
        </span>
      </div>

      <div
        style={{
          maxHeight: "350px",
          overflowY: "auto",
          paddingRight: "5px",
          scrollbarWidth: "thin",
          borderRadius: "8px",
          scrollbarColor: `${colors["white"]} transparent`,
        }}
        className="scan-form-container"
      >
        {renderOptions(allOptions)}
      </div>
    </div>
  );
};
export default NestedDropdown;
