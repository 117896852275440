import React, { useEffect, useRef, useState } from "react";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { useNavigate, useParams } from "react-router-dom";
import { formatAdditionalValue } from "../../util/genericFunctions";
import { colors } from "../../util/colors.js";
import AnimatedNumber from "../../components/animated-numbers/AnimatedNumbers";
import { SCREEN_SIZES } from "../../util/constants.js";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler);

const CustomRadarChart = ({ riskScore, issuesPerCategory }) => {
  const navigate = useNavigate();
  const { target_id } = useParams();
  const chartRef = useRef(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const data = {
    labels: [
      "Web Applications",
      "Network Security",
      "CVEs",
      "Server & Hosts",
      "Data Exposure",
    ],
    datasets: [
      {
        data: [
          issuesPerCategory?.groupedByCategory?.["Web Applications"],
          issuesPerCategory?.groupedByCategory?.["Network Security"],
          issuesPerCategory?.groupedByCategory?.["CVEs"],
          issuesPerCategory?.groupedByCategory?.["Servers and Hosts"],
          issuesPerCategory?.groupedByCategory?.["Data Exposure"],
        ],
        borderColor: "rgba(255, 255, 255, 1)",
        borderWidth: 1.5,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx } = chart;
          return createGradient(ctx);
        },
        pointRadius: 0,
        pointHoverRadius: 0,
      },
    ],
  };

  const createGradient = (ctx) => {
    const gradient = ctx.createRadialGradient(150, 150, 0, 150, 150, 150);
    gradient.addColorStop(0, "rgba(192, 203, 217, 0.7)");
    gradient.addColorStop(1, "rgba(3, 7, 14, 0.7)");
    return gradient;
  };

  const handleRedirectCategory = (category, isNew) => {
    const categoryName =
      category == "Server & Hosts" ? "Servers and Hosts" : category;
    const link = isNew
      ? `/security-issues/${target_id}?scan_category=${categoryName}&is_new=true`
      : `/security-issues/${target_id}?scan_category=${categoryName}`;
    navigate(link);
  };

  const getChartColor = (value) => {
    if (value == "High") {
      return colors["danger"];
    } else if (value == "Medium") {
      return colors["warning"];
    }
    return colors["success"];
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      r: {
        ticks: {
          display: false,
          stepSize: 65,
          count: 4,
        },
        grid: {
          color: "rgba(255, 255, 255, 1)",
        },
        angleLines: {
          color: "rgba(255, 255, 255, 1)",
          display: false,
        },
        pointLabels: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const additionalLabels = [
    {
      mainValue:
        riskScore === 0
          ? "0"
          : issuesPerCategory?.groupedByCategory?.["Web Applications"],
      additionalValue: formatAdditionalValue(
        issuesPerCategory?.addedIssues?.["Web Applications"],
        issuesPerCategory?.movedIssues?.["Web Applications"]
      ),
      underlineText: "Web Applications",
      color:
        riskScore === 0
          ? colors["white"]
          : getChartColor(
              issuesPerCategory?.groupedByCategoryColors?.["Web Applications"]
            ),
      position:
        screenWidth <= SCREEN_SIZES.LAPTOP_LARGE
          ? { top: "-7%", left: "50%" }
          : { top: "-3%", left: "50%" },
    },
    {
      mainValue:
        riskScore === 0
          ? "0"
          : issuesPerCategory?.groupedByCategory?.["Network Security"],
      additionalValue: formatAdditionalValue(
        issuesPerCategory?.addedIssues?.["Network Security"],
        issuesPerCategory?.movedIssues?.["Network Security"]
      ),
      underlineText: "Network Security",
      color:
        riskScore === 0
          ? colors["white"]
          : getChartColor(
              issuesPerCategory?.groupedByCategoryColors?.["Network Security"]
            ),
      position:
        screenWidth <= SCREEN_SIZES.LAPTOP_LARGE
          ? { top: "25%", left: "77%" }
          : { top: "26%", left: "77%" },
    },
    {
      mainValue:
        riskScore === 0 ? "0" : issuesPerCategory?.groupedByCategory?.["CVEs"],
      additionalValue: formatAdditionalValue(
        issuesPerCategory?.addedIssues?.["CVEs"],
        issuesPerCategory?.movedIssues?.["CVEs"]
      ),
      underlineText: "CVEs",
      color:
        riskScore === 0
          ? colors["white"]
          : getChartColor(issuesPerCategory?.groupedByCategoryColors?.["CVEs"]),
      position:
        screenWidth <= SCREEN_SIZES.LAPTOP_LARGE
          ? { top: "67%", left: "69%" }
          : { top: "82%", left: "71%" },
    },
    {
      mainValue:
        riskScore === 0
          ? "0"
          : issuesPerCategory?.groupedByCategory?.["Servers and Hosts"],
      additionalValue: formatAdditionalValue(
        issuesPerCategory?.addedIssues?.["Servers and Hosts"],
        issuesPerCategory?.movedIssues?.["Servers and Hosts"]
      ),
      underlineText: "Server & Hosts",
      color:
        riskScore === 0
          ? colors["white"]
          : getChartColor(
              issuesPerCategory?.groupedByCategoryColors?.["Servers and Hosts"]
            ),
      position:
        screenWidth <= SCREEN_SIZES.LAPTOP_LARGE
          ? { top: "63%", left: "28%" }
          : { top: "77%", left: "28%" },
    },
    {
      mainValue:
        riskScore === 0
          ? "0"
          : issuesPerCategory?.groupedByCategory?.["Data Exposure"],
      additionalValue: formatAdditionalValue(
        issuesPerCategory?.addedIssues?.["Data Exposure"],
        issuesPerCategory?.movedIssues?.["Data Exposure"]
      ),
      underlineText: "Data Exposure",
      color:
        riskScore === 0
          ? colors["white"]
          : getChartColor(
              issuesPerCategory?.groupedByCategoryColors?.["Data Exposure"]
            ),
      position:
        screenWidth <= SCREEN_SIZES.LAPTOP_LARGE
          ? { top: "20%", left: "24%" }
          : { top: "31%", left: "21%" },
    },
  ];

  return (
    <div className="radar-chart" style={{ position: "relative", top: 8 }}>
      <Radar ref={chartRef} data={data} options={options} />

      {additionalLabels.map((label, index) => (
        <div
          key={label.underlineText}
          style={{
            position: "absolute",
            color: colors["white"],
            fontSize:
              screenWidth <= SCREEN_SIZES.LAPTOP_LARGE ? "20px" : "23px",
            fontWeight: "bold",
            top: label.position.top,
            left: label.position.left,
            transform: "translate(-50%, -50%)",
            padding: "5px 10px",
            borderRadius: "5px",
            textAlign: "center",
            display: "flex",
            flexDirection: index == 0 ? "row" : "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{ color: label.color }}
            className="radar-chart-labels-container"
          >
            <span
              className="radar-chart-labels-value"
              style={{
                textShadow:
                  riskScore === 0
                    ? "none"
                    : `0px 0px 93.3865px ${label.color}, 0px 0px 26.6818px ${label.color},
      0px 0px 13.3409px ${label.color}`,
                fontSize:
                  screenWidth <= SCREEN_SIZES.LAPTOP_LARGE ? "20px" : "23px",
              }}
            >
              <AnimatedNumber value={label.mainValue || 0} />
            </span>
            {label.additionalValue && (
              <span
                className="radar-chart-labels-additional"
                style={{
                  cursor: label.additionalValue?.startsWith("+")
                    ? "pointer"
                    : "initial",
                  textDecoration: label.additionalValue?.startsWith("+")
                    ? "underline"
                    : "none",
                  fontSize:
                    screenWidth <= SCREEN_SIZES.LAPTOP_LARGE ? "13px" : "15px",
                }}
                onClick={() => {
                  if (label.additionalValue?.startsWith("+")) {
                    handleRedirectCategory(label.underlineText, true);
                  }
                }}
              >
                {label.additionalValue}
              </span>
            )}
          </div>
          <div
            className="radar-chart-labels"
            style={{
              textDecoration: "underline",
              textAlign: index == 0 || index == 4 ? "justify" : "center",
              marginLeft: index == 0 ? "10px" : "0px",
              cursor: "pointer",
              fontSize:
                screenWidth <= SCREEN_SIZES.LAPTOP_LARGE ? "10px" : "12px",
            }}
            onClick={() => handleRedirectCategory(label.underlineText)}
          >
            {label.underlineText}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CustomRadarChart;
