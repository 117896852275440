import React from "react";
import { Tooltip } from "react-bootstrap";

const CustomTooltip = React.forwardRef((props, ref) => (
  <Tooltip
    ref={ref}
    {...props}
    className={`custom-white-tooltip ${props.className}`}
  >
    {props.children}
  </Tooltip>
));

export default CustomTooltip;
