import React from "react";
import GenericCard from "./GenericCard";
import AttackSurfaceComponent from "./AttackSurfaceComponent";
import StatsCard from "./StatsCard";

const RightSection = ({
  cardVisibility,
  isSidebarOpen,
  highRiskAssets,
  attacksurfaceData,
  assetsInventory,
  isDone,
  isDefault,
}) => {
  return (
    <div
      className={`${
        isSidebarOpen ? "dashboard-right-side-bar-open" : "dashboard-right"
      } `}
    >
      {cardVisibility.showAssetInventory1 && (
        <GenericCard className="mb-2" title="Attack Surface">
          <StatsCard
            stats={attacksurfaceData}
            className={`${isSidebarOpen && "stats-card-side-bar-open"}`}
            isDone={isDone}
            isDefault={isDefault}
          />
        </GenericCard>
      )}

      {cardVisibility.showHighestRiskAssets && (
        <GenericCard
          title="Highest Risk Assets"
          className="radar-chart-container mb-2"
        >
          {highRiskAssets?.length > 0 ? (
            <AttackSurfaceComponent data={highRiskAssets} />
          ) : !isDone ? (
            <div className="highlight-scan-title">Scan in progress...</div>
          ) : (
            <div className="highlight-scan-title">No Data Found</div>
          )}
        </GenericCard>
      )}

      {cardVisibility.showAssetInventory2 && (
        <GenericCard className="mb-2" title="Asset Inventory">
          <StatsCard
            stats={assetsInventory}
            className={`${isSidebarOpen && "stats-card-side-bar-open"}`}
            isAssets={true}
            isDone={isDone}
            isDefault={isDefault}
          />
        </GenericCard>
      )}
    </div>
  );
};

export default RightSection;
