import React, { useEffect, useState, useRef } from "react";
import Breadcrumb from "../../components/breadcrumbs/BreadCrumb";
import animationBlueIntroData from "../../assets/animation/blue-intro.json";
import animationBlueLoopData from "../../assets/animation/blue-loop.json";
import animationRedIntroData from "../../assets/animation/red-intro.json";
import animationRedLoopData from "../../assets/animation/red-loop.json";
import animationGreenIntroData from "../../assets/animation/green-intro.json";
import animationGreenLoopData from "../../assets/animation/green-loop.json";
import animationYellowIntroData from "../../assets/animation/yellow-Intro.json";
import animationYellowLoopData from "../../assets/animation/yellow-loop.json";
import { useOutletContext, useParams } from "react-router-dom";
import { UseServices } from "../../hooks/useServices.js";
import LeftSection from "./LeftSection.js";
import RightSection from "./RightSection.js";
import CenterSection from "./CenterSection.js";
import { ThreeCircles } from "react-loader-spinner";
import axios from "../../util/axios";
import { colors } from "../../util/colors.js";

const Dashboard = () => {
  const { isSidebarOpen } = useOutletContext();
  const { target_id } = useParams();
  const { isDone } = UseServices();
  const timeoutRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [highRiskAssets, setHighRiskAssets] = useState([]);
  const [title, setTitle] = useState("");
  const [updatedAt, setUpdatedAt] = useState(null);
  const [isDefault, setIsDefault] = useState(0);
  const [attacksurfaceData, setAttackSurfaceData] = useState([]);
  const [assetsInventory, setAssetsInventory] = useState([]);
  const [lightRiskScore, setLightRiskScore] = useState({});
  const [issuesPerCategory, setIssuesPerCategory] = useState({});
  const [cardVisibility, setCardVisibility] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const fetchAttackSurfaceApi = async () => {
        try {
          const { data } = await axios.get(`/scans/${target_id}/attacksurface`);
          setAttackSurfaceData([
            {
              count: data?.ports?.total,
              new: data?.ports?.groups?.new,
              removed: data?.ports?.groups?.removed,
              label: "Ports",
              key: "ports",
            },
            {
              count: data?.leaks?.total,
              new: data?.leaks?.groups?.new,
              removed: data?.leaks?.groups?.removed,
              label: "Leaks",
              key: "data-leaks",
            },
            {
              count: data?.sensitiveDataExposure?.total,
              new: data?.sensitiveDataExposure?.groups?.new,
              removed: data?.sensitiveDataExposure?.groups?.removed,
              label: "Exposed Data",
              key: "sensitive-exposed-data",
            },
            {
              count: data?.certificates?.total,
              new: data?.certificates?.groups?.new,
              removed: data?.certificates?.groups?.removed,
              label: "Certificates",
              key: "certificates",
            },
          ]);
        } catch (err) {
          console.error("API Error:", err);
        }
      };

      const fetchAssetsInventoryApi = async () => {
        try {
          const { data } = await axios.get(
            `/scans/${target_id}/dashboard-assets-inventory`
          );
          setAssetsInventory([
            {
              count: data?.totals?.domain,
              new: data?.domainChanges > 0 ? data?.domainChanges : 0,
              removed:
                data?.domainChanges < 0 ? Math.abs(data?.domainChanges) : 0,
              label: "Domains",
            },
            {
              count: data?.totals?.ips,
              new: data?.ipChanges?.new,
              removed: data?.ipChanges?.removed,
              label: "IPs",
            },
            {
              count: data?.totals?.hosts,
              new: data?.hostChanges?.new,
              removed: data?.hostChanges?.removed,
              label: "Hostnames",
            },
          ]);
        } catch (err) {
          console.error("API Error:", err);
        }
      };

      const fetchHighRiskAssetsApi = async () => {
        try {
          const { data } = await axios.get(
            `/issues/high-risk-assets/${target_id}`
          );
          setHighRiskAssets(
            data?.highRiskAssets?.length > 0 ? data?.highRiskAssets : []
          );
        } catch (err) {
          console.error("API Error:", err);
        }
      };

      const fetchLightApi = async () => {
        try {
          const { data } = await axios.get(
            `/issues/light-risk-score/${target_id}`
          );
          setIssuesPerCategory(data?.issuesPerCategory);
          setLightRiskScore({
            riskScore: data?.lightRiskScore?.avgRiskScore || 0,
            totalSecurityIssues: data?.lightRiskScore?.totalIssues,
            issuesGroupByRisk: data?.issuesGroupByRisk,
          });
          setIsDefault(data?.isDefaultTarget);
          setCardVisibility({
            showAssetInventory1: !!data?.isDefaultTarget,
            showRiskScoreTrend: !!data?.isDefaultTarget,
            showIssuesOverTime: !!data?.isDefaultTarget,
            showHighestRiskAssets: true,
            showAssetInventory2: true,
            showIssuesPerCategory: true,
            showSecurityIssues: !data?.isDefaultTarget,
          });
          setTitle(data?.targetName);
          setUpdatedAt(data?.lastUpdated);
        } catch (err) {
          console.error("API Error:", err);
        }
      };
      try {
        await Promise.all([
          fetchLightApi(),
          fetchAttackSurfaceApi(),
          fetchAssetsInventoryApi(),
          fetchHighRiskAssetsApi(),
        ]);
      } catch (err) {
        console.error("API Error:", err);
      } finally {
        setIsLoading(false);
      }
    };
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    fetchData();
    if (!isDone) {
      timeoutRef.current = setInterval(fetchData, 5000);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isDone, target_id]);

  useEffect(() => {
    setIsLoading(true);
    setAttackSurfaceData([
      {
        label: "Ports",
        key: "ports",
      },
      {
        label: "Leaks",
        key: "data-leaks",
      },
      {
        label: "Exposed Data",
        key: "sensitive-exposed-data",
      },
      {
        label: "Certificates",
        key: "certificates",
      },
    ]);
    setAssetsInventory([
      {
        label: "Domains",
      },
      {
        label: "IPs",
      },
      {
        label: "Hostnames",
      },
    ]);
    setLightRiskScore({
      riskScore: 0,
      totalSecurityIssues: 0,
      issuesGroupByRisk: null,
    });
    setIssuesPerCategory({
      addedIssues: null,
      groupedByCategory: null,
      movedIssues: null,
    });
    setCardVisibility({
      showAssetInventory1: true,
      showRiskScoreTrend: true,
      showIssuesOverTime: true,
      showHighestRiskAssets: true,
      showAssetInventory2: true,
      showIssuesPerCategory: true,
      showSecurityIssues: false,
    });
    setTitle("");
  }, [target_id]);

  let riskColor, riskLevel, introAnimation, loopAnimation;
  if (lightRiskScore?.riskScore > 0) {
    if (lightRiskScore?.issuesGroupByRisk?.High > 0) {
      riskColor = "red";
      riskLevel = "RISK SCORE";
      introAnimation = animationRedIntroData;
      loopAnimation = animationRedLoopData;
    } else if (lightRiskScore?.issuesGroupByRisk?.Medium > 0) {
      riskColor = "yellow";
      riskLevel = "RISK SCORE";
      introAnimation = animationYellowIntroData;
      loopAnimation = animationYellowLoopData;
    } else {
      riskColor = "green";
      riskLevel = "RISK SCORE";
      introAnimation = animationGreenIntroData;
      loopAnimation = animationGreenLoopData;
    }
  } else {
    if (isDone) {
      riskColor = "green";
      riskLevel = "RISK SCORE";
      introAnimation = animationGreenIntroData;
      loopAnimation = animationGreenLoopData;
    } else {
      riskColor = "blue";
      riskLevel = "Scanning...";
      introAnimation = animationBlueIntroData;
      loopAnimation = animationBlueLoopData;
    }
  }
  return isLoading ? (
    <div className="content-loader">
      <ThreeCircles
        visible={true}
        height="60"
        width="60"
        color={colors["white"]}
        ariaLabel="three-circles-loading"
        wrapperClass=""
      />
    </div>
  ) : (
    <div
      className={`${
        isSidebarOpen ? "dashboard-main-side-bar-open" : "dashboard-main"
      }  ${riskColor}`}
    >
      <Breadcrumb
        showHorizontalRule={false}
        title={
          title ? (
            <div>
              Target:{" "}
              <span
                style={{
                  color: colors["gray-title"],
                  fontWeight: 500,
                }}
              >
                {title}
              </span>
            </div>
          ) : (
            ""
          )
        }
        endTime={updatedAt}
      />

      <div
        className={`dashboard-container-img dashboard-container-img-${riskColor}`}
      >
        <div className="dashboard-background-col">
          <div
            className="dashboard-container"
            style={{
              padding: cardVisibility?.showSecurityIssues
                ? "3rem 1rem 8rem 1rem"
                : "1rem 1rem 1rem 1rem",
            }}
          >
            <LeftSection
              isSidebarOpen={isSidebarOpen}
              cardVisibility={cardVisibility}
              riskScore={lightRiskScore?.riskScore}
              issuesPerCategory={issuesPerCategory}
              isDone={isDone}
              lightRiskScore={lightRiskScore}
            />
            <CenterSection
              loopAnimation={loopAnimation}
              introAnimation={introAnimation}
              riskColor={riskColor}
              lightRiskScore={lightRiskScore}
              isDone={isDone}
              riskLevel={riskLevel}
            />
            <RightSection
              isSidebarOpen={isSidebarOpen}
              cardVisibility={cardVisibility}
              highRiskAssets={highRiskAssets}
              attacksurfaceData={attacksurfaceData}
              assetsInventory={assetsInventory}
              isDone={isDone}
              isDefault={isDefault}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
