import React, { useState, useEffect } from "react";
import LottiePlayerLight from "react-lottie-player/dist/LottiePlayerLight";
import animationScanningLoopData from "../../assets/animation/scanning-loop.json";
import { useParams, useNavigate } from "react-router-dom";
import AnimatedNumber from "../../components/animated-numbers/AnimatedNumbers";

const CenterSection = ({
  introAnimation,
  riskColor,
  loopAnimation,
  lightRiskScore,
  isDone,
  riskLevel,
}) => {
  const navigate = useNavigate();
  const { target_id } = useParams();

  const [showLoopAnimation, setShowLoopAnimation] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    const introDuration = 2000;
    const timer = setTimeout(() => {
      setShowLoopAnimation(true);
      setAnimationKey((prev) => prev + 1);
    }, introDuration);

    return () => clearTimeout(timer);
  }, []);

  const handleRedirectSecurityIssues = () => {
    navigate(`/security-issues/${target_id}`);
  };

  return (
    <div className="dashboard-center">
      {!showLoopAnimation ? (
        <LottiePlayerLight
          key={animationKey}
          animationData={introAnimation}
          play
          loop={false}
          speed={1}
          style={{ width: 633, height: 542 }}
          className="dashoard-animation-player"
        />
      ) : (
        <LottiePlayerLight
          key={animationKey}
          animationData={loopAnimation}
          play
          loop={true}
          speed={1}
          style={{ width: 633, height: 542 }}
          className="dashoard-animation-player"
        />
      )}
      <div
        className={`risk-center-card ${riskColor} ${
          lightRiskScore?.riskScore === 0 && !isDone
            ? "scanning-mode"
            : "normal-mode"
        }`}
      >
        <div className="risk-center-text">
          <div
            className={`top ${
              lightRiskScore?.riskScore === 0 && !isDone
                ? "scanning-animation-position"
                : ""
            }`}
          >
            {lightRiskScore?.riskScore === 0 && !isDone ? (
              <LottiePlayerLight
                animationData={animationScanningLoopData}
                play
                loop
                speed={1}
                style={{ width: 303, height: 303 }}
              />
            ) : (
              <AnimatedNumber value={lightRiskScore?.riskScore || 0} />
            )}
          </div>
          <div className="middle">{riskLevel}</div>
          {!(lightRiskScore?.riskScore === 0 && !isDone) && (
            <div
              className="bottom cursor-pointer"
              onClick={handleRedirectSecurityIssues}
            >
              {lightRiskScore?.totalSecurityIssues} SECURITY ISSUES
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CenterSection;
