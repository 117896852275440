import React from 'react';
import { Form } from 'react-bootstrap';

const BaseCheckbox = ({ type, name, label, checked, onChange, ...props }) => {
    return (
        <div className='custom-base-checkbox'>
        <Form.Check 
            type={type}
            id={name}
            label={label}
            checked={checked}
            onChange={onChange}
            name={name}
            {...props} 
        />
        </div>
    );
};

export default BaseCheckbox;
