import { parseFilterString, applyCondition } from "./conditions";
import { statusMap } from "./filterGenericFunction.js";

export const webUpdatesFilter = (
  name,
  activeFilters,
  eventKey = "6",
  filterType = "Current State",
  isBackendProcess = false
) => {
  if (
    activeFilters.some(
      (filter) => filter.name === name && filter.eventKey == eventKey
    )
  ) {
    return activeFilters.filter((filter) => filter.name !== name);
  } else {
    const filteredCurrentFilters = activeFilters.filter(
      (filter) => filter.type !== filterType
    );
    if (name !== null) {
      if (isBackendProcess) {
        return [
          ...filteredCurrentFilters,
          {
            type: `${filterType}: ${name}`,
            eventKey,
            name,
            id: statusMap[name?.toLowerCase()]
          },
        ];
      } else {
        return [
          ...filteredCurrentFilters,
          {
            type: `${filterType}: ${name}`,
            eventKey,
            name,
          },
        ];
      }
    }
    return filteredCurrentFilters;
  }
};

export const webDataFillter = (key, name = "Current State") => {
  let data = [
    {
      id: 0,
      name: "New",
      type: `${name}: New`,
      key: key,
      active: false,
    },
    {
      id: 1,
      name: "Changed",
      type: `${name}: Changed`,
      key: key,
      active: false,
    },
    {
      id: 2,
      name: "Unchanged",
      type: `${name}: Unchanged`,
      key: key,
      active: false,
    },
    {
      id: 3,
      name: "Removed",
      type: "Current State: Removed",
      key: key,
      active: false,
    },
  ];
  return data;
};
export const webDataArchiveFillter = (key, name = "Current State") => {
  let data = [
    {
      id: 0,
      name: "Rejected",
      type: `${name}: Rejected`,
      key: key,
      active: false,
    },
    {
      id: 1,
      name: "Removed",
      type: `${name}: Removed`,
      key: key,
      active: false,
    },
  ];
  return data;
};

export const getAssetDiscoveryFilteredData = (
  assetsDiscovery,
  activeFilters,
  searchValue
) => {
  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});
  const filteredData = assetsDiscovery
    .filter((el) => {
      const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
        return groupedFilters[eventKey].some((filter) => {
          const lowerCaseFilter = filter.name?.toLowerCase();
          if (eventKey === "1") {
            return (
              el.root_domain &&
              el.root_domain?.toLowerCase() === lowerCaseFilter
            );
          } else if (eventKey === "2") {
            return el.subdomains.some(
              (ol) => ol.subdomain?.toLowerCase() === lowerCaseFilter
            );
          } else if (eventKey === "3") {
            return el.subdomains.some(
              (ol) => ol.ip?.toLowerCase() === lowerCaseFilter
            );
          } else if (eventKey === "advanced-filter") {
            const parsedFilters = parseFilterString(filter.name);
            return parsedFilters.every((ol) => {
              const { column, condition, value } = ol;
              switch (column) {
                case "root_domain":
                  return applyCondition(el.root_domain, condition, value);
                case "subdomain":
                  return el.subdomains.some((ol) =>
                    applyCondition(ol.subdomain, condition, value)
                  );
                case "ip":
                  return el.subdomains.some((ol) =>
                    applyCondition(ol.ip, condition, value)
                  );
                default:
                  return false;
              }
            });
          }
          return false;
        });
      });

      const matchesSearch =
        searchValue === "" ||
        el.root_domain?.toLowerCase().includes(searchValue.toLowerCase()) ||
        el.subdomains.some(
          (ol) =>
            ol.subdomain?.toLowerCase().includes(searchValue.toLowerCase()) ||
            ol.ip?.toLowerCase().includes(searchValue.toLowerCase()) ||
            ol.hosting?.toLowerCase().includes(searchValue.toLowerCase()) ||
            ol.cdn?.toLowerCase().includes(searchValue.toLowerCase())
        );
      return matchesFilters && matchesSearch;
    })
    .map((el) => {
      const allDomains = activeFilters.every(
        (filter) => filter.eventKey == "1"
      );

      return {
        ...el,
        subdomains: el.subdomains.filter(
          (ol) =>
            activeFilters.length === 0 ||
            allDomains ||
            (groupedFilters["2"] &&
              groupedFilters["2"].some(
                (filter) =>
                  ol.subdomain?.toLowerCase() === filter.name?.toLowerCase()
              )) ||
            (groupedFilters["3"] &&
              groupedFilters["3"].some(
                (filter) => ol.ip?.toLowerCase() === filter.name?.toLowerCase()
              )) ||
            (groupedFilters["4"] &&
              groupedFilters["4"].some(
                (filter) =>
                  ol.hosting?.toLowerCase() === filter.name?.toLowerCase()
              )) ||
            (groupedFilters["5"] &&
              groupedFilters["5"].some(
                (filter) => ol.cdn?.toLowerCase() === filter.name?.toLowerCase()
              )) ||
            (groupedFilters["advanced-filter"] &&
              groupedFilters["advanced-filter"].some((filter) => {
                const parsedFilters = parseFilterString(filter.name);
                const withoutIpFilter = parsedFilters?.filter(
                  (el) => el.column != "root_domain"
                );
                return withoutIpFilter.every((item) => {
                  const { column, condition, value } = item;
                  switch (column) {
                    case "subdomain":
                      return applyCondition(ol.subdomain, condition, value);
                    case "ip":
                      return applyCondition(ol.ip, condition, value);
                    case "hosting":
                      return applyCondition(ol.hosting, condition, value);
                    case "cdn":
                      return applyCondition(ol.cdn, condition, value);
                    default:
                      return false;
                  }
                });
              }))
        ),
      };
    });
  return filteredData;
};

export const formatAdditionalValue = (added, moved) => {
  if (added && added !== 0) {
    return `+${added}`;
  } else if (moved && moved !== 0) {
    return `-${moved}`;
  }
  return "";
};
