import axios from "axios";

const getBaseURL = (version = "v1") => {
  return process.env.REACT_APP_API_URL.replace("/v1", `/${version}`);
};

const axiosInstance = axios.create({
  baseURL: getBaseURL(),
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const version = config.version || "v1";
    config.baseURL = getBaseURL(version);
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403) &&
      originalRequest.url !== "auth/login"
    ) {
      localStorage.clear();
      window.location = "/auth/login";
    }
    return Promise.reject(error);
  }
);

if (typeof XMLHttpRequest !== "undefined") {
  const originalOpen = XMLHttpRequest.prototype.open;

  XMLHttpRequest.prototype.open = function (...args) {
    this.addEventListener("readystatechange", function () {
      if (this.responseType === "blob" && this.readyState === 4) {
        Object.defineProperty(this, "responseText", {
          get() {
            return "";
          },
        });
      }
    });
    originalOpen.apply(this, args);
  };
}

export default axiosInstance;
