import React from "react";
import { formatAdditionalValue } from "../../util/genericFunctions";
import { useParams, useNavigate } from "react-router-dom";
import AnimatedNumber from "../../components/animated-numbers/AnimatedNumbers";

const StatsComponent = ({ stats, className, isAssets, isDone, isDefault }) => {
  const { target_id } = useParams();
  const navigate = useNavigate();

  const handleRedirectScanner = (key, isNew) => {
    if (isAssets) {
      navigate(
        `/assets-inventory/${target_id}?tab=2${isNew ? "&status=new" : ""}`
      );
    } else {
      navigate(`/scanner/${target_id}/${key}${isNew ? "?is_new=true" : ""}`);
    }
  };

  return (
    <div className={`stats-main ${className}`}>
      {stats.map((item, index) => {
        let additionalValue = formatAdditionalValue(item?.new, item?.removed);
        return (
          <div key={index} className="stats-container">
            <div className="stats-number">
              {item?.count ? (
                <AnimatedNumber value={item?.count || 0} />
              ) : isDone ? (
                "0"
              ) : (
                "-"
              )}
              {additionalValue && !!isDefault && (
                <sup
                  className={
                    additionalValue?.startsWith("+")
                      ? "positive cursor-pointer"
                      : "negative"
                  }
                  onClick={() => {
                    if (additionalValue?.startsWith("+")) {
                      handleRedirectScanner(item?.key, true);
                    }
                  }}
                >
                  {additionalValue}
                </sup>
              )}
            </div>
            <div
              className="stats-label cursor-pointer"
              onClick={() => handleRedirectScanner(item?.key)}
            >
              {item.label}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StatsComponent;
