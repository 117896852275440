import React from "react";
import { useSpring, animated } from "@react-spring/web";

const AnimatedNumber = ({ value, duration = 2000 }) => {
  const customEasing = (t) => 1 - Math.pow(2, -10 * t);
  const animatedProps = useSpring({
    from: { number: 0 },
    to: { number: Number(value) },
    delay: 300,
    config: {
      duration: duration,
      easing: customEasing,
    },
  });

  return (
    <animated.span>
      {animatedProps.number.to((n) => Math.floor(n))}
    </animated.span>
  );
};

export default AnimatedNumber;
