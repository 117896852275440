import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import BaseTable from "../../../components/table/BaseTable.js";
import { ReactComponent as FaCheckCircle } from "../../../assets/images/FaCheckCircle.svg";
import { ReactComponent as FaExclamationCircle } from "../../../assets/images/inactive-status-red.svg";
import BaseFilter from "../../../components/filter/BaseFilter.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import CurrentStatus from "../../../components/status/CurrentStatus.js";
import axios from "../../../util/axios.js";
import moment from "moment";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ThreeCircles } from "react-loader-spinner";
import GenericCard from "../../../components/card/GenericCard.js";
import DoughnutChart from "../../../components/charts/DoughnutChart.js";
import NoData from "../../../components/empty/NoData.js";
import Data from "../../../components/summary/AssetSummary.jsx";
import { NoDataAfterScanning } from "../../../components/empty/NoDataAfterScanning.js";
import { ReactComponent as GlobeIcon } from "../../../assets/images/globe-blue.svg";
import { ReactComponent as IPAddressIcon } from "../../../assets/images/ip.svg";
import { ReactComponent as HostnameIcon } from "../../../assets/images/server.svg";
import { ReactComponent as LinkIcon } from "../../../assets/images/external-link-icon.svg";
import {
  useNavigate,
  useParams,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import { formatScannerName } from "../../../helpers/formatScannerName.js";
import { ScannerContext } from "../../../components/ScannerContext.js";
import { locationToPageName } from "../../../helpers/index.js";
import { sendEvent } from "../../../util/analytics.js";
import { webUpdatesFilter } from "../../../util/genericFunctions.js";
import AssetsModal from "./AssetsModal.js";
import { showToast, ToastContent } from "../../../util/toasts.js";
import ClickableBarChart from "./CriticalityBarChart.js";
import { parseFilterString, applyCondition } from "../../../util/conditions.js";
import { assetsDiscoveryTabsConfig } from "../../../util/tabsConfig.js";
import BulkSelection from "../../../components/bulk-selection/BulkSelection.js";
import { UseServices } from "../../../hooks/useServices.js";
import { formatDate } from "../../../util/formatDate.js";
import { UseAssetsModal } from "../../../hooks/useAssetsModal.js";
import { getUserRole } from "../../../util/userUtils.js";
import {
  initialColumns,
  discoveredByFilterOptions,
  networkZoneFilterOptions,
  criticalityFilterOptions,
  firstDetectedFilterOptions,
  criticalityMap,
  webInterfaceFilterOptions,
} from "../../../util/data-config.js";
import { colorList } from "../../../util/colors.js";
import { statusMap } from "../../../util/filterGenericFunction.js";
import { doughnutChartOptions } from "../../../util/chartOptions.js";

const AssetInventory = () => {
  const userRole = getUserRole();
  const isUser = userRole !== "viewer";
  const fetchIntervalRef = useRef();
  const { openModal } = UseAssetsModal();
  const abortControllerRef = useRef(null);
  const { fetchAllServicesItems, allServices } = UseServices();
  const { dropdownItems } = useContext(ScannerContext);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const statusParam = searchParams.get("status");
  const { scanningStatus } = useOutletContext();
  const routeParams = useParams();
  const searchTimeoutRef = useRef(null);
  const [targetScan, setTargetScan] = useState({});
  const [noDataDoughnutChart, setNoDataDoughnutChart] = useState(false);
  const [isDatainProcess, setIsDataInProcess] = useState(false);
  const [isDataisPartiallyProcessing, setIsDataisPartiallyProcessing] =
    useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState(null);
  const [rejectIP, setRejectIP] = useState([]);
  const [rejectAction, setRejectAction] = useState(2);
  const [allAssetsInventory, setAllAssetsInventory] = useState([]);
  const [isBackendProcess, setIsBackendProcess] = useState(false);
  const [isBackendInitialized, setIsBackendInitialized] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchQuery, setSearchQuery] = useState({
    search: "",
    sort: "",
    order: "desc",
    page: 1,
    pageSize: 10,
    filter: [],
    advancefilter: [],
  });
  const searchQueryRef = useRef(searchQuery);
  const [statsData, setStatsData] = useState({
    totalHostnames: 0,
    totalActiveHostnames: 0,
    totalIps: 0,
    totalActiveIps: 0,
    totalDomain: 0,
  });

  const [allColumnsObj, setAllColumnsObj] = useState([]);
  const [doughnutChartType, setDoughnutChartType] = useState({
    labels: ["Unchanged", "New", "Changed"],
    datasets: [
      {
        data: [0, 0, 0],
        backgroundColor: colorList,
        hoverBackgroundColor: colorList,
        borderColor: "transparent",
        borderWidth: 0,
      },
    ],
  });
  const [tabs, setTabs] = useState([]);
  const [availableActions, setAvailableActions] = useState([]);

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    if (isBackendProcess) {
      const query = {
        ...searchQuery,
        filter: updatedActiveFilters?.filter(
          (el) => el?.eventKey !== "advanced-filter"
        ),
        advancefilter: updatedActiveFilters?.filter(
          (el) => el?.eventKey === "advanced-filter"
        ),
      };
      setIsFetching(true);
      fetchAssetsInventory(query);
      setSearchQuery(query);
    }
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const handleChartClick = (name) => {
    if (isBackendProcess) {
      let UpdatedActiveFilters = webUpdatesFilter(
        name,
        activeFilters,
        "Current State",
        "Current State",
        isBackendProcess
      );
      const query = {
        ...searchQuery,
        filter: UpdatedActiveFilters,
      };
      setIsFetching(true);
      fetchAssetsInventory(query);
      setSearchQuery(query);
      setActiveFilters(UpdatedActiveFilters);
    } else {
      setActiveFilters(webUpdatesFilter(name, activeFilters, "10"));
    }
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    if (isBackendProcess) {
      const query = {
        ...searchQuery,
        filter: updatedFilters?.filter(
          (el) => el?.eventKey !== "advanced-filter"
        ),
        advancefilter: updatedFilters?.filter(
          (el) => el?.eventKey === "advanced-filter"
        ),
      };
      setIsFetching(true);
      fetchAssetsInventory(query);
      setSearchQuery(query);
    }
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const handleSelectionAll = () => {
    const allSelected = {};
    const allSelectedIps = [];

    filteredData.forEach((item) => {
      allSelected[item?.host_id || item?.ip_id] = true;
      allSelectedIps.push({
        domain_id: item?.domain_id,
        ip_id: item?.ip_id,
        ip: item?.ip,
        host_id: item?.host_id,
        hostname: item?.hostname,
        id: item?.host_id || item?.ip_id,
      });
    });
    setSelectedAssets(allSelected);
    setRejectIP(allSelectedIps);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (isBackendProcess && (e.target.value?.length > 1 || !e.target.value)) {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
      setSearchQuery({
        ...searchQuery,
        search: e.target.value,
        page: 1,
      });
      searchTimeoutRef.current = setTimeout(() => {
        setIsFetching(true);
        fetchAssetsInventory({
          ...searchQuery,
          search: e.target.value,
          page: 1,
        });
      }, 800);
    }
  };

  const handleBackendSort = (key) => {
    const direction = searchQuery?.order == "asc" ? "desc" : "asc";
    setIsFetching(true);
    fetchAssetsInventory({
      ...searchQuery,
      sort: key,
      order: direction,
    });
    setSearchQuery({
      ...searchQuery,
      sort: key,
      order: direction,
    });
  };

  const handleBackendPageChange = (page) => {
    fetchAssetsInventory({
      ...searchQuery,
      page,
    });
    setSearchQuery({
      ...searchQuery,
      page,
    });
  };
  const handleBackendPageSize = (pageSize) => {
    setIsFetching(true);
    fetchAssetsInventory({
      ...searchQuery,
      pageSize,
      page: 1,
    });
    setSearchQuery({
      ...searchQuery,
      pageSize,
      page: 1,
    });
  };

  const handleMultipleAction = (item) => {
    setRejectAction(item.id);
    setShowModal(true);
  };

  const redirected = (data) => {
    if (data?.security_issues > 0) {
      const filter = !data.hostname ? `ip=${data.ip}` : `host=${data.hostname}`;
      navigate(`/security-issues/${routeParams?.id}?${filter}`);
    }
  };

  const onClickActionItems = (row) => {
    const actions = [
      { id: 2, name: "Reject IP" },
      { id: 3, name: "Modify Asset Criticality" },
    ];
    if (row?.hostname) {
      actions.unshift({ id: 1, name: "Reject Hostname" });
    }
    setAvailableActions(actions);
  };

  const handleRowSelect = (selectedRow) => {
    const selectedIpsToAdd = [];
    const selectedIpsToRemove = [];
    Object.entries(selectedRow).forEach(([rowId, isSelected]) => {
      const selectedData = filteredData.find(
        (row) => row?.id === Number(rowId)
      );
      if (selectedData) {
        const relatedHosts = allAssetsInventory
          .filter((el) => el.ip_id == selectedData?.ip_id && el.hostname)
          .map((el) => el.hostname);
        const ipData = {
          domain_id: selectedData?.domain_id,
          ip_id: selectedData?.ip_id,
          ip: selectedData?.ip,
          host_id: selectedData?.host_id,
          hostname: selectedData?.hostname,
          id: selectedData?.host_id || selectedData?.ip_id,
          relatedHosts,
        };

        if (isSelected) {
          selectedIpsToAdd.push(ipData);
        } else {
          selectedIpsToRemove.push(ipData);
        }
      }
    });

    setRejectIP((prevIps) => {
      const updatedIps = prevIps.filter(
        (ip) =>
          !selectedIpsToRemove.some(
            (selectedIp) => selectedIp.ip_id === ip.ip_id
          )
      );
      return [...updatedIps, ...selectedIpsToAdd];
    });
    setRejectIP(selectedIpsToAdd);
    setSelectedAssets(selectedRow);
  };

  const handleRejectSubmit = async () => {
    let endpoint = "";
    let payload = [];

    if (rejectAction === 1) {
      endpoint = `/scans/${routeParams?.id}/rejectHostname`;
      let emptyHostname = false;
      rejectIP.forEach((el) => {
        if (el?.host_id) {
          payload.push({
            host_id: el?.host_id,
          });
        } else {
          emptyHostname = true;
        }
      });
      if (emptyHostname) {
        showToast(
          <div style={{ textWrap: "nowrap" }}>
            The selected assets include IP addresses without <br />
            associated hostnames, which prevents them from being rejected.
            <br />
            Please remove any assets that contain only an IP address
            <br /> and try again.
          </div>,
          "error",
          "top-center",
          "light",
          {
            style: { width: "fit-content", alignItems: "center" },
          }
        );
        return;
      }
    } else {
      endpoint = `/scans/${routeParams?.id}/rejects`;
      const uniqueIPs = {};
      rejectIP.forEach((el) => {
        uniqueIPs[el.ip_id] = {
          domain_id: el.domain_id,
          ip: el.ip,
          ip_id: el.ip_id,
        };
      });
      payload = Object.keys(uniqueIPs).map((ip_id) => uniqueIPs[ip_id]);
    }
    const response = await axios.post(endpoint, payload);
    if (response?.status === 200) {
      showToast(response.data.message, "success", "top-center", "light");
      const pageName = locationToPageName(location);
      sendEvent("buttonClick", {
        pageName,
        result: "rejectIP",
        ip: rejectIP,
      });
      fetchAssetsInventory();
      fetchSummary();
      fetchAllServicesItems(routeParams?.id);
      setShowModal(false);
      setSelectedAssets(null);
      setRejectIP([]);
    }
  };

  const handleOnHide = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setShowModal(false);
  };

  const handleModifyCriticalitySubmit = async (rowItems, selectedValue) => {
    try {
      const criticality = criticalityMap[selectedValue];
      const selectedItems = Array.isArray(rowItems) ? rowItems : [rowItems];
      const isSameCriticality = selectedItems.every(
        (el) => el.criticality === criticality
      );
      if (isSameCriticality) {
        return;
      }

      const ips = selectedItems
        .filter((el) => el.ip && !el.host_id)
        .map((el) => el.ip_id);

      const hosts = selectedItems
        .filter((el) => el.hostname && el.host_id)
        .map((el) => el.host_id);
      const payload = {
        criticality,
        ips,
        hosts,
      };

      const response = await axios.post(
        `/assets/${routeParams?.id}/adjust-criticality`,
        payload
      );

      if (response.status === 200) {
        showToast(
          "Criticality modified successfully",
          "success",
          "top-center",
          "light"
        );
        fetchAssetsInventory();
        fetchSummary();
        setShowModal(false);
      }
    } catch (error) {
      showToast("Failed to modify criticality", "error", "top-center", "light");
    }
  };

  const handleAction = (item) => {
    const relatedHosts = allAssetsInventory
      .filter((el) => el.ip_id === item.data?.ip_id && el.hostname)
      .map((el) => el.hostname);
    const ips = [
      {
        domain_id: item.data?.domain_id,
        ip_id: item.data?.ip_id,
        ip: item?.data?.ip,
        host_id: item?.data?.host_id,
        hostname: item?.data?.hostname,
        id: item?.data?.host_id || item?.data?.ip_id,
        relatedHosts,
        criticality: item?.data?.criticality,
      },
    ];
    setRejectAction(item.selectedAction.id);
    setRejectIP(ips);
    setShowModal(true);
  };

  const fetchManageColumns = async () => {
    try {
      const { data } = await axios.get(
        `userSettings?table_name=ASSETS_INVENTORY`
      );
      if (data?.columns) {
        let updatedColumns = [];
        initialColumns?.map((el) => {
          if (el?.default || data?.columns?.includes(el?.key)) {
            updatedColumns.push({
              ...el,
              visibility: true,
            });
          } else {
            updatedColumns.push({
              ...el,
              visibility: false,
            });
          }
        });
        setAllColumnsObj(updatedColumns);
      } else {
        setAllColumnsObj(initialColumns);
      }
    } catch (err) {
      console.error(err);
      setAllColumnsObj(initialColumns);
    }
  };

  const fetchSummary = async () => {
    try {
      const { data } = await axios.get(
        `/graph/${routeParams?.id}/assetInventory`,
        {
          version: "v2",
        }
      );
      let summary = data?.summary;
      let currentState = data?.asset_stats;
      setStatsData({
        totalHostnames: summary?.hosts?.inactive + summary?.hosts?.active || 0,
        totalActiveHostnames: summary?.hosts?.active || 0,
        totalIps: summary?.ips?.inactive + summary?.ips?.active || 0,
        totalActiveIps: summary?.ips?.active || 0,
        totalDomain: summary?.domains || 0,
      });
      setDoughnutChartType({
        labels: ["Unchanged", "New", "Changed"],
        datasets: [
          {
            data: [
              currentState?.["0"] || 0,
              currentState?.["1"] || 0,
              currentState?.["2"] || 0,
            ],
            backgroundColor: colorList,
            hoverBackgroundColor: colorList,
            borderColor: "transparent",
            borderWidth: 0,
          },
        ],
      });
    } catch (e) {}
  };

  const fetchAssetsInventory = async (searchParam0) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;
    try {
      const baseUrl = `/scans/${routeParams?.id}`;
      let url = "";
      if (searchParam0) {
        const params = {
          page: searchParam0?.page.toString(),
          pagesize: searchParam0?.pageSize.toString(),
          sort: searchParam0?.sort || "",
          order: searchParam0?.order || "",
          search: searchParam0?.search || "",
        };
        if (searchParam0?.filter && searchParam0?.filter.length > 0) {
          let filterQuery = "";
          filterQuery = searchParam0.filter
            .map((el) => {
              if (el?.eventKey == "First Detected") {
                return `{"name":"${el?.eventKey}","value":"${el?.id}","oper":"${
                  el?.type?.includes("Last 24 Hours")
                    ? "last_24_hours"
                    : el?.type?.includes("Last 7 Day")
                    ? "last_7_days"
                    : "last_30_days"
                }"}`;
              } else {
                return `{"name":"${el?.eventKey}","value":"${el?.id}","oper":"is"}`;
              }
            })
            .join(";");
          params["filter"] = filterQuery;
        }
        if (
          searchParam0?.advancefilter &&
          searchParam0?.advancefilter.length > 0
        ) {
          let filterQuery = "";
          filterQuery = searchParam0.advancefilter
            .map((el) => {
              const parsedFilters = parseFilterString(el.name);
              const advanceParsedFilter = parsedFilters?.map((ol, index) => {
                const ids = el?.id?.split("|");
                return `{"name":"${ol?.column}","value":"${ids[index]}","oper":"${ol?.condition}"}`;
              });
              return advanceParsedFilter?.join(";");
            })
            .join(";");
          params["advancefilter"] = filterQuery;
        }
        const queryParams = new URLSearchParams(params).toString();
        url = `${baseUrl}?${queryParams}`;
      } else {
        url = baseUrl;
      }
      const { data } = await axios.get(url, {
        version: "v2",
        signal: controller.signal,
      });
      if (
        data?.data?.length == 0 &&
        (scanningStatus === 2 || scanningStatus === 1)
      ) {
        setIsDataInProcess(true);
      } else {
        setIsDataInProcess(false);
      }
      setIsBackendProcess(data?.backendProcess);
      setIsBackendInitialized(true);
      setTotalPages(data?.numberOfpages);
      setTotalRecords(data?.resultsCount);
      const modifiedData = [
        ...data?.data?.map((el) => ({
          ...el,
          domain: el?.Domain,
          hostname: el?.Hostname,
          ip: el?.["IP Address"],
          status: el?.["Status"] == "Active" ? 1 : 0,
          hosting_provider: el?.["Hosting Provider"],
          cdn_name: el?.["CDN"],
          network_zone:
            el?.["Network Zone"] == "On-Premise"
              ? 1
              : el?.["Network Zone"] == "Cloud-Host"
              ? 2
              : el?.["Network Zone"],
          web_interface: el?.["Web Interface"],
          criticality:
            el?.["Asset Criticality"] == "High"
              ? 3
              : el?.["Asset Criticality"] == "Medium"
              ? 2
              : 1,
          security_issues: el?.["Security Issues"],
          discovered_by: el?.["Discovered By"] == "Armory" ? 1 : 2,
          first_detected: el?.["First Detected"],
          updated_at: el?.["Last Updated"],
          change_status: el?.["Current State"],
          service: el?.["Service Category"],
          id: el?.host_id || el?.ip_id,
        })),
      ];
      if (!data?.backendProcess) {
        updateTabsWithData(modifiedData);
      }
      setAllAssetsInventory(modifiedData);
      setIsLoading(false);
      setIsFetching(false);
      if (scanningStatus === 2 || scanningStatus === 1) {
        clearTimeout(fetchIntervalRef.current);
        fetchIntervalRef.current = setTimeout(() => {
          fetchAssetsInventory(searchQueryRef.current);
          fetchSummary();
        }, 5000);
      }
    } catch (e) {
      setIsLoading(false);
      setIsFetching(false);
    }
  };

  const updateTabsWithData = (data) => {
    const domainName = Array.from(new Set(data.map((el) => el.domain)))
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "Domain: " + item,
        key: "1",
        active: false,
      }));
    const categoryList = [];
    data?.map((el) => {
      if (el.service !== "-") {
        el?.service?.split(",")?.map((service) => {
          categoryList.push(service);
        });
      }
    });
    const serviceCategorName = Array.from(new Set(categoryList.map((el) => el)))
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "Service Category: " + item,
        key: "12",
        active: false,
      }));
    const hostName = Array.from(new Set(data.map((el) => el.hostname)))
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "Hostname: " + item,
        key: "2",
        active: false,
      }));

    const ipName = Array.from(new Set(data.map((el) => el.ip)))
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "IP Address: " + item,
        key: "3",
        active: false,
      }));

    const statusName = Array.from(
      new Set(data.map((el) => (el.status == 0 ? "Inactive" : "Active")))
    )
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "Status: " + item,
        key: "4",
        active: false,
      }));

    const hostingName = Array.from(
      new Set(data.map((el) => el.hosting_provider))
    )
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "Hosting Provider: " + item,
        key: "5",
        active: false,
      }));
    const secutiryIssues = Array.from(
      new Set(data.map((el) => el.security_issues))
    )
      ?.filter((name) => name)
      .map((el, index) => ({
        id: index,
        name: `${el}`,
        type: "Security Issues: " + `${el}`,
        key: "9",
        active: false,
      }));
    const cdnName = Array.from(new Set(data.map((el) => el.cdn_name)))
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "CDN: " + item,
        key: "6",
        active: false,
      }));

    setTabs(
      assetsDiscoveryTabsConfig({
        domainName,
        hostName,
        ipName,
        statusName,
        hostingName,
        cdnName,
        networkZoneFilterOptions,
        serviceCategorName,
        criticalityFilterOptions,
        secutiryIssues,
        discoveredByFilterOptions,
        firstDetectedFilterOptions,
        webInterfaceFilterOptions,
      })
    );
  };
  useEffect(() => {
    if (statusParam && isBackendInitialized) {
      handleChartClick("New");
    }
  }, [statusParam, isBackendInitialized]);

  useEffect(() => {
    searchQueryRef.current = searchQuery;
  }, [searchQuery]);

  useEffect(() => {
    const targetScan = dropdownItems.filter((item) => {
      return item.target_uuid === routeParams?.id;
    })[0];
    setTargetScan(targetScan);
  }, [dropdownItems]);

  useEffect(() => {
    clearInterval(fetchIntervalRef.current);
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    if (scanningStatus === 0) {
      setIsLoading(false);
      setIsDataInProcess(true);
    } else {
      setIsDataInProcess(false);
      fetchAssetsInventory();
      fetchSummary();
      if (
        scanningStatus === 3 ||
        scanningStatus === -1 ||
        scanningStatus === 4
      ) {
        setIsDataisPartiallyProcessing(false);
      } else if (scanningStatus === 2 || scanningStatus === 1) {
        setIsDataisPartiallyProcessing(true);
      }
    }
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      clearInterval(fetchIntervalRef.current);
    };
  }, [scanningStatus, routeParams?.id]);

  useEffect(() => {
    fetchManageColumns();
  }, []);

  useEffect(() => {
    if (allColumnsObj?.length > 0) {
      const updateColumnSettings = async () => {
        let columns = "";
        allColumnsObj?.forEach((el) => {
          if (el?.visibility) {
            columns += `${el?.key},`;
          }
        });
        try {
          await axios.post(`updateUserSettings`, {
            table_name: "ASSETS_INVENTORY",
            columns: columns,
          });
        } catch (err) {
          console.error(err);
        }
      };
      updateColumnSettings();
    }
  }, [allColumnsObj]);

  const filteredColumns = useMemo(() => {
    const columns = [
      { Header: "Domain", accessor: "domain", key: "domain", isSortable: true },
      {
        Header: "Hostname",
        key: "hostname",
        accessor: (row) =>
          row?.hostname?.length > 26 ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Popover
                  id={`tooltip-${row?.hostname}`}
                  className="custom-popover-arrow"
                >
                  <Popover.Body className="comming-tool-body">
                    <label className="comming-text-tool">{row?.hostname}</label>
                  </Popover.Body>
                </Popover>
              }
            >
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "190px",
                }}
                onClick={() => openModal(row?.hostname)}
                className="asset-link"
              >
                {row?.hostname.slice(0, 26) + "..."}
              </div>
            </OverlayTrigger>
          ) : (
            <span
              onClick={() => openModal(row?.hostname)}
              className="asset-link"
            >
              {row?.hostname}
            </span>
          ),
        isSortable: true,
      },
      {
        Header: "IP Address",
        accessor: (row) => {
          return (
            <span onClick={() => openModal(row?.ip)} className="asset-link">
              {row?.ip}
            </span>
          );
        },
        key: "ip",
        isSortable: true,
      },
      {
        Header: "Status",
        isSortable: true,
        key: "status",
        width: 20,
        accessor: (row) => {
          let statusLabel;
          let statusIcon;
          let statusColor;
          let statusValue = "";
          if (!row?.is_processed) {
            statusValue = "Validating";
          } else {
            if (row?.status) {
              statusValue = "Active";
            } else {
              statusValue = "Inactive";
            }
          }
          switch (statusValue) {
            case "Validating":
              statusLabel = "Validating";
              statusIcon = (
                <ThreeCircles height={18} width={18} color="white" />
              );
              statusColor = "#fff";
              break;
            case "Active":
              statusLabel = "Active";
              statusIcon = <FaCheckCircle />;
              statusColor = "#3DDC97";
              break;
            case "Inactive":
              statusLabel = "Inactive";
              statusIcon = <FaExclamationCircle />;
              statusColor = "#FF6155";
              break;
            default:
              break;
          }

          return (
            <div
              style={{
                color: statusColor,
                display: "flex",
                alignItems: "center",
              }}
            >
              {statusIcon}{" "}
              <span style={{ marginLeft: "5px" }}>{statusLabel}</span>
            </div>
          );
        },
      },
      {
        Header: "Hosting Provider",
        key: "hosting_provider",
        isSortable: true,
        width: 20,
        accessor: (row) => {
          let statusLabel = "Scanning";
          let statusIcon = (
            <ThreeCircles height={18} width={18} color="white" />
          );
          let statusColor = "#fff";

          return !row.hosting_provider &&
            allServices?.find((s) => s.id == 7)?.status < 3 ? (
            <div
              style={{
                color: statusColor,
                display: "flex",
                alignItems: "center",
              }}
            >
              {statusIcon}{" "}
              <span style={{ marginLeft: "5px" }}>{statusLabel}</span>
            </div>
          ) : !row.hosting_provider ? (
            "-"
          ) : (
            row.hosting_provider
          );
        },
      },
      {
        Header: "CDN",
        key: "cdn_name",
        width: 25,
        isSortable: true,
        accessor: (row) => {
          let statusLabel = "Scanning";
          let statusIcon = (
            <ThreeCircles height={18} width={18} color="white" />
          );
          let statusColor = "#fff";
          let hostsCdn = "-";
          return !row.cdn_name &&
            allServices?.find((s) => s.id === 9)?.status < 3 ? (
            <div
              style={{
                color: statusColor,
                display: "flex",
                alignItems: "center",
              }}
            >
              {statusIcon}{" "}
              <span style={{ marginLeft: "5px" }}>{statusLabel}</span>
            </div>
          ) : !row.cdn_name ? (
            hostsCdn
          ) : (
            row.cdn_name
          );
        },
      },
      {
        Header: "Network Zone",
        key: "network_zone",
        width: 25,
        isSortable: true,
        accessor: (row) => {
          if (row.network_zone === null) {
            return "-";
          }
          const text =
            row.network_zone === 1
              ? "On-Premise"
              : row.network_zone === 2
              ? "Cloud-Hosted"
              : "Unknown";
          return (
            <div className="cursor-pointer web-interface-container">{text}</div>
          );
        },
      },
      {
        Header: "Service Category",
        key: "service",
        width: 50,
        isSortable: true,
        accessor: (row) => {
          if (row.service == "-") {
            return row.service;
          }
          const categoryArray = row.service?.split(",");
          const displayCategories = categoryArray.slice(0, 2);
          const remainingCategories = categoryArray.slice(2);
          const popover = (
            <Popover
              id={`popover-categories-${row?.host_id || row?.ip_id}`}
              className="custom-popover-arrow"
            >
              <Popover.Body className="comming-tool-body d-grid scrollable-checkbox-list">
                {remainingCategories.map((category, i) => (
                  <span key={i} className="text-black port-box">
                    <span>·</span> {category}
                  </span>
                ))}
              </Popover.Body>
            </Popover>
          );

          return (
            <div className="">
              <div className="flex gap-2">
                {displayCategories.map((category, i) => (
                  <div
                    className="cursor-pointer service-categiry-chip w-mx-content"
                    key={i}
                  >
                    {category}
                  </div>
                ))}

                {remainingCategories.length > 0 && (
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="top"
                    overlay={popover}
                  >
                    <div className="cursor-pointer service-categiry-chip">
                      +{remainingCategories.length}
                    </div>
                  </OverlayTrigger>
                )}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Web Interface",
        key: "web_interface",
        width: 25,
        isSortable: true,
        accessor: (row) => {
          const protocol = row?.host_protocol || "https";
          const host = row?.hostname;
          if (
            row?.web_interface === null ||
            row?.web_interface === "" ||
            row?.web_interface === "No" ||
            row?.web_interface === undefined
          ) {
            return (
              <div className="cursor-pointer web-interface-container">No</div>
            );
          }
          return (
            <a
              href={`${protocol}://${host}`}
              target="_blank"
              rel="noopener noreferrer"
              className="web-interface-link"
            >
              <div className="cursor-pointer web-interface-container">
                <p className="underline m-0">Yes</p>
                <LinkIcon />
              </div>
            </a>
          );
        },
      },
      {
        Header: "Asset Criticality",
        key: "criticality",
        isSortable: true,
        accessor: (row) => {
          return (
            <div className="d-flex align-items-center">
              <div className="cursor-pointer">
                <ClickableBarChart
                  criticality={row?.criticality}
                  onCriticalityChange={(criticality) =>
                    handleModifyCriticalitySubmit(row, criticality)
                  }
                  barClickable={isUser}
                />{" "}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Security Issues",
        key: "security_issues",
        isSortable: true,
        accessor: (row) => {
          return (
            <div
              onClick={() => {
                redirected(row);
              }}
              className="cursor-pointer fw-bolder"
              style={{
                textDecoration:
                  row?.security_issues > 0 ? "underline" : "initial",
              }}
            >
              {row?.security_issues || 0}
            </div>
          );
        },
      },
      {
        Header: "Discovered By",
        key: "discovered_by",
        isSortable: true,
        accessor: (row) => (
          <div>
            {row?.discovered_by === 2
              ? "User’s input"
              : row?.discovered_by === 1
              ? "Armory"
              : "-"}
          </div>
        ),
      },
      {
        Header: "First Detected",
        key: "first_detected",
        isSortable: true,
        accessor: (row) => {
          return (
            <div>
              {row?.first_detected ? formatDate(row?.first_detected) : ""}
            </div>
          );
        },
      },
      {
        Header: "Last Updated",
        key: "updated_at",
        isSortable: true,
        accessor: (row) => {
          return (
            <div>{row?.updated_at ? formatDate(row?.updated_at) : ""}</div>
          );
        },
      },
      {
        Header: "Current State",
        key: "change_status",
        isSortable: true,
        accessor: (row) => {
          return (
            <div>
              {row?.change_status != null ? (
                <CurrentStatus
                  status={row?.change_status}
                  tooltipInfo={row?.changes}
                  headerKeys={{
                    domain: "Domain",
                    hostname: "Hostname",
                    ip: "IP Address",
                    status: "Status",
                    hosting_provider: "Hosting Provider",
                    cdn_name: "CDN",
                    network_zone: "Network Zone",
                    service: "Service Category",
                    web_interface: "Web Interface",
                    criticality: "Asset Criticality",
                    security_issues: "Security Issues",
                    discovered_by: "Discovered By",
                    first_detected: "First Detected",
                    updated_at: "Last Updated",
                  }}
                />
              ) : (
                ""
              )}
            </div>
          );
        },
      },
    ];
    return columns.filter((column) => {
      const found = allColumnsObj.find((sc) => sc.key === column.key);
      return found ? found.visibility : false;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, isUser, redirected, allColumnsObj]);

  const groupFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});
  const filteredData = isBackendProcess
    ? allAssetsInventory
    : allAssetsInventory.filter((target) => {
        const matchesFilters = Object.keys(groupFilters).every((eventKey) => {
          return groupFilters[eventKey].some((filter) => {
            const lowerCaseFilter = filter.name?.toLowerCase();
            if (eventKey === "1") {
              return (
                target?.domain &&
                target.domain?.toLowerCase() === lowerCaseFilter
              );
            } else if (eventKey === "3") {
              return target.ip && target.ip === lowerCaseFilter;
            } else if (eventKey === "2") {
              return target.hostname?.toLowerCase() === lowerCaseFilter;
            } else if (eventKey === "5") {
              return (
                target.hosting_provider &&
                target.hosting_provider?.toLowerCase() === lowerCaseFilter
              );
            } else if (eventKey === "4") {
              return (
                (lowerCaseFilter === "validating" && !target.is_processed) ||
                (lowerCaseFilter === "active" &&
                  target.is_processed &&
                  target.status) ||
                (lowerCaseFilter === "inactive" &&
                  target.is_processed &&
                  !target.status)
              );
            } else if (eventKey === "6") {
              return (
                target.cdn_name &&
                target.cdn_name?.toLowerCase() === lowerCaseFilter
              );
            } else if (eventKey === "10") {
              return (
                target?.change_status === statusMap[filter.name?.toLowerCase()]
              );
            } else if (eventKey === "9") {
              return Number(target?.security_issues) == Number(filter.name);
            } else if (eventKey === "8") {
              return (
                (filter.name === "User’s input" &&
                  target.discovered_by === 2) ||
                (filter.name === "Armory" && target.discovered_by === 1)
              );
            } else if (eventKey === "15") {
              return filter.name === target.web_interface;
            } else if (eventKey === "11") {
              return (
                (filter.name === "On-Premise" && target.network_zone === 1) ||
                (filter.name === "Cloud-Hosted" && target.network_zone === 2)
              );
            } else if (eventKey === "13") {
              return (
                (filter.name === "Low" && target.criticality === 1) ||
                (filter.name === "Medium" && target.criticality === 2) ||
                (filter.name === "High" && target.criticality === 3)
              );
            } else if (eventKey === "12") {
              return (
                target.service &&
                target.service
                  ?.split(",")
                  .some((service) => service?.toLowerCase() === lowerCaseFilter)
              );
            } else if (eventKey === "15") {
              if (filter.name?.toLowerCase() == "no") {
                return (
                  target.host_status_code === null ||
                  target.host_status_code === "" ||
                  target.host_status_code === undefined
                );
              } else {
                return filter.name?.includes(target.host_status_code);
              }
            } else if (eventKey === "14") {
              return applyCondition(
                target.first_detected,
                filter.name?.toLowerCase().replaceAll(" ", "_"),
                filter.name
              );
            } else if (eventKey === "advanced-filter") {
              const parsedFilters = parseFilterString(filter.name);
              return parsedFilters.every((ol) => {
                const { column, condition, value } = ol;
                switch (column) {
                  case "domain":
                    return applyCondition(
                      target.domain?.toLowerCase(),
                      condition,
                      value?.toLowerCase()
                    );
                  case "host":
                    return applyCondition(
                      target.hostname?.toLowerCase(),
                      condition,
                      value?.toLowerCase()
                    );
                  case "ip":
                    return applyCondition(
                      target.ip?.toLowerCase(),
                      condition,
                      value?.toLowerCase()
                    );
                  case "status":
                    return (
                      (value?.toLowerCase() === "validating" &&
                        !target.is_processed) ||
                      (value?.toLowerCase() === "active" &&
                        target.is_processed &&
                        target.status === 1) ||
                      (value?.toLowerCase() === "inactive" &&
                        target.is_processed &&
                        !target.status)
                    );
                  case "hosting_provider_discovery":
                    return applyCondition(
                      target.hosting_provider?.toLowerCase(),
                      condition,
                      value?.toLowerCase()
                    );
                  case "cdn_name_discovery":
                    return applyCondition(
                      target.cdn_name?.toLowerCase(),
                      condition,
                      value?.toLowerCase()
                    );
                  case "number_issues":
                    return applyCondition(
                      `${target?.security_issues}`,
                      condition,
                      value
                    );
                  case "web_interface":
                    return applyCondition(
                      target?.web_interface,
                      condition,
                      value
                    );
                  case "host_status_code":
                    if (value?.toLowerCase() == "no") {
                      const isStatusCode =
                        target.host_status_code === null ||
                        target.host_status_code === "" ||
                        target.host_status_code === undefined;
                      return condition == "is_not"
                        ? !isStatusCode
                        : isStatusCode;
                    } else {
                      return condition == "is_not"
                        ? !value?.includes(target.host_status_code)
                        : value?.includes(target.host_status_code);
                    }
                  case "first_detected":
                    return applyCondition(
                      target.first_detected,
                      condition,
                      value
                    );
                  case "discovered_by":
                    return (
                      (value === "User’s input" &&
                        target.discovered_by === 2) ||
                      (value === "Armory" && target.discovered_by === 1)
                    );
                  case "current_state":
                    return applyCondition(
                      target?.change_status,
                      condition,
                      statusMap[value?.toLowerCase()]
                    );
                  case "network_zone":
                    return (
                      (value === "On-Premise" && target.network_zone === 1) ||
                      (value === "Cloud-Hosted" && target.network_zone === 2)
                    );
                  case "service_category":
                    return (
                      target.service &&
                      target.service
                        ?.split(",")
                        .some(
                          (service) =>
                            service?.toLowerCase() === value?.toLowerCase()
                        )
                    );
                  case "criticality":
                    return (
                      (value === "Low" && target.criticality === 1) ||
                      (value === "Medium" && target.criticality === 2) ||
                      (value === "High" && target.criticality === 3)
                    );
                  default:
                    return false;
                }
              });
            }
            return false;
          });
        });

        const matchesSearch =
          searchValue === "" ||
          target.domain?.toLowerCase().includes(searchValue?.toLowerCase()) ||
          target.host?.toLowerCase().includes(searchValue?.toLowerCase()) ||
          target.hosting_provider
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase()) ||
          target.cdn?.toLowerCase().includes(searchValue?.toLowerCase()) ||
          (target.ip &&
            typeof target.ip === "string" &&
            target.ip?.toLowerCase().includes(searchValue?.toLowerCase()));

        return matchesFilters && matchesSearch;
      });

  return (
    <div className="py-4 px-4">
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <>
          <div className="row mb-4 cards-container">
            <div className="col-6 h-50">
              <GenericCard
                children={
                  <Data
                    items={[
                      {
                        icon: GlobeIcon,
                        label: "Domains",
                        value: statsData?.totalDomain || 0,
                        active: null,
                      },
                      {
                        icon: IPAddressIcon,
                        label: "IP Addresses",
                        value: statsData?.totalIps || 0,
                        active: statsData?.totalActiveIps || 0,
                      },
                      {
                        icon: HostnameIcon,
                        label: "Hostnames",
                        value: statsData?.totalHostnames || 0,
                        active: statsData?.totalActiveHostnames || 0,
                      },
                    ]}
                  />
                }
                title={"Summary"}
              />
            </div>

            <div className="col-6 chart-with-position">
              <GenericCard
                children={
                  noDataDoughnutChart ? (
                    <NoDataAfterScanning />
                  ) : isDatainProcess ? (
                    <NoData />
                  ) : (
                    <DoughnutChart
                      data={doughnutChartType}
                      options={doughnutChartOptions}
                      plugins={[ChartDataLabels]}
                      height={104}
                      width={209}
                      onHandleClick={handleChartClick}
                    />
                  )
                }
                title={"Asset State"}
              />
            </div>
          </div>
          <div>
            <BaseFilter
              tabs={tabs}
              className="mt-4"
              onFilterChange={handleFilterChange}
              activeFilters={activeFilters}
              removeFilter={removeFilter}
              searchValue={searchValue}
              onSearchChange={handleSearchChange}
              isSearch={true}
              isManageColumn={true}
              allColumns={allColumnsObj}
              setAllColumns={setAllColumnsObj}
              totalRecords={
                isBackendProcess ? totalRecords : filteredData?.length
              }
              exportTitle={`AssetsDiscovery_${formatScannerName(
                targetScan?.title
              )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
              actions={[
                { id: 1, name: "Reject Hostname" },
                { id: 2, name: "Reject IP" },
                { id: 3, name: "Modifiy Asset Criticality" },
              ]}
              onActionSelect={handleMultipleAction}
              onClearSelectRow={() => {
                setRejectIP([]);
                setSelectedAssets(null);
              }}
              multiSelect={
                selectedAssets &&
                Object.entries(selectedAssets)?.filter((el) => el[1] == true)
                  ?.length > 0
                  ? Object.entries(selectedAssets)?.filter(
                      (el) => el[1] == true
                    )?.length
                  : 0
              }
              tableData={filteredData}
              showAddNewAsset
              key={"assetInventory"}
              isBackendProcess={isBackendProcess}
              isExportFromBackend={true}
            />
          </div>
          <div>
            {selectedAssets &&
              Object.entries(selectedAssets)?.filter((el) => el[1] === true)
                ?.length > 0 && (
                <div>
                  <BulkSelection
                    actions={[
                      { id: 1, name: "Reject Hostname" },
                      { id: 2, name: "Reject IP" },
                      { id: 3, name: "Modify Asset Criticality" },
                    ]}
                    onClearSelectRow={() => {
                      setRejectIP([]);
                      setSelectedAssets(null);
                    }}
                    onActionSelect={handleMultipleAction}
                    multiselectLabel={"Assets"}
                    multiSelect={
                      Object.entries(selectedAssets)?.filter(
                        (el) => el[1] === true
                      )?.length
                    }
                    totalRecords={filteredData?.length}
                    handleSelectionAll={handleSelectionAll}
                  />
                </div>
              )}
          </div>
          {filteredColumns.length > 0 ? (
            <div>
              <div className="assets-discovery-table  custom-x-scroll-table position-relative">
                <BaseTable
                  className="mt-3 mb-3"
                  columns={filteredColumns}
                  data={filteredData}
                  selectable={isUser}
                  showCheckboxes={isUser}
                  action={isUser}
                  stickyAction={true}
                  actions={availableActions}
                  onApplyActions={handleAction}
                  onRowSelect={handleRowSelect}
                  allSelectedRows={selectedAssets}
                  onClickActionItems={onClickActionItems}
                  isStickyScroll
                  loading={isLoading}
                  isDatainProcess={
                    isDatainProcess || isDataisPartiallyProcessing
                  }
                  isBackendProcess={isBackendProcess}
                  handleBackendSort={handleBackendSort}
                  handleBackendPageChange={handleBackendPageChange}
                  handleBackendPageSize={handleBackendPageSize}
                  page={searchQuery?.page}
                  pageSize={searchQuery?.pageSize}
                  numberOfpages={totalPages}
                />
                {isFetching && (
                  <div className="loading-overlay">
                    <ThreeCircles
                      visible={true}
                      height="50"
                      width="50"
                      color="#fff"
                    />
                  </div>
                )}
              </div>
            </div>
          ) : null}
          <AssetsModal
            showModal={showModal}
            handleOnHide={handleOnHide}
            actionType={rejectAction}
            items={rejectIP}
            handleSubmit={handleRejectSubmit}
            handleModifyCriticalitySubmit={handleModifyCriticalitySubmit}
          />
        </>
      )}
    </div>
  );
};

export default AssetInventory;
