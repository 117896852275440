import React, { useEffect, useState } from "react";
import LineChart from "./LineChart";
import RadarChart from "./RadarChart";
import GenericCard from "./GenericCard";
import SecurityIssuesCard from "./SecurityIssuesCard";
import axios from "../../util/axios";
import { useParams } from "react-router-dom";
import { lineChartOptions } from "../../util/chartUtils";
import { formatDate } from "../../util/formatDate.js";

const LeftSection = ({
  cardVisibility,
  isSidebarOpen,
  riskScore,
  issuesPerCategory,
  isDone,
  lightRiskScore,
}) => {
  const { target_id } = useParams();
  const [hasNoData, setHasNoData] = useState(false);
  const [riskScoreData, setRiskScoreData] = useState({
    labels: [],
    datasets: [
      {
        ...lineChartOptions,
        label: "Risk Score Trend",
        data: [],
        issues: [],
      },
    ],
  });
  const [issuesOverData, setIssuesOverData] = useState({
    labels: [],
    datasets: [
      {
        ...lineChartOptions,
        label: "Issues over time",
        data: [],
        issues: [],
      },
    ],
  });

  const fetchRiskScoreTrend = async () => {
    try {
      const { data } = await axios.get(
        `/issues/risk-score-trends/trends/${target_id}`
      );
      if (data?.trends?.length > 0) {
        let sortedData = data?.trends?.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        setHasNoData(false);
        setRiskScoreData({
          labels: sortedData?.map((el) => formatDate(el.date, "MMM DD, YY")),
          datasets: [
            {
              ...lineChartOptions,
              label: "Risk Score Trend",
              data: sortedData?.map((el) => Math.round(el.risk_score)),
              issues: sortedData?.map((el) => ({
                total_high_issues: el.total_high_issues,
                total_med_issues: el.total_med_issues,
                total_low_issues: el.total_low_issues,
              })),
            },
          ],
        });
      } else {
        setHasNoData(true);
      }
    } catch (err) {}
  };

  const fetchIssuesOverTime = async () => {
    try {
      const { data } = await axios.get(
        `/issues/risk-score-trends/issues/${target_id}`
      );
      if (data?.trends?.length > 0) {
        let sortedData = data?.trends?.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        setHasNoData(false);
        setIssuesOverData({
          labels: sortedData?.map((el) => formatDate(el.date, "MMM DD, YY")),
          datasets: [
            {
              ...lineChartOptions,
              label: "Risk Score Trend",
              data: sortedData?.map(
                (el) =>
                  Number(el.total_high_issues) +
                  Number(el.total_med_issues) +
                  Number(el.total_low_issues)
              ),
              issues: sortedData?.map((el) => ({
                total_high_issues: el.total_high_issues,
                total_med_issues: el.total_med_issues,
                total_low_issues: el.total_low_issues,
              })),
            },
          ],
        });
      } else {
        setHasNoData(true);
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchRiskScoreTrend();
    fetchIssuesOverTime();
  }, [target_id]);

  return (
    <div
      className={`${
        isSidebarOpen ? "dashboard-left-side-bar-open" : "dashboard-left"
      } `}
    >
      {cardVisibility.showRiskScoreTrend && (
        <GenericCard className="mb-2" title="Risk Score Trend">
          {!hasNoData ? (
            <LineChart
              style={{ width: "480px", height: "190px" }}
              data={{
                labels: riskScoreData?.labels,
                datasets: [
                  {
                    ...lineChartOptions(false),
                    ...riskScoreData.datasets[0],
                  },
                ],
              }}
              id="chartjs-tooltip-1"
              showRiskLevels={false}
            />
          ) : !isDone ? (
            <div className="line-scan-chart">Scan in progress...</div>
          ) : (
            <div className="line-scan-chart">No Data Found</div>
          )}
        </GenericCard>
      )}

      {cardVisibility.showIssuesPerCategory && (
        <GenericCard
          className="radar-chart-container mb-2"
          title="Issues Per Category"
        >
          <RadarChart
            style={{ width: "450px", height: "230px" }}
            riskScore={riskScore}
            issuesPerCategory={issuesPerCategory}
          />
        </GenericCard>
      )}

      {cardVisibility.showIssuesOverTime && (
        <GenericCard className="mb-2" title="Issues over time">
          {!hasNoData ? (
            <LineChart
              style={{ width: "480px", height: "190px" }}
              data={{
                labels: issuesOverData?.labels,
                datasets: [
                  {
                    ...lineChartOptions(true),
                    ...issuesOverData.datasets[0],
                  },
                ],
              }}
              id="chartjs-tooltip-2"
              showRiskLevels={true}
            />
          ) : !isDone ? (
            <div className="line-scan-chart">Scan in progress...</div>
          ) : (
            <div className="line-scan-chart">No Data Found</div>
          )}
        </GenericCard>
      )}

      {cardVisibility.showSecurityIssues && (
        <GenericCard className="mb-2" title="Security Issues">
          {riskScore === 0 ? (
            <div className="security-scan-chart">Scan in progress...</div>
          ) : (
            <SecurityIssuesCard
              highRisk={lightRiskScore?.issuesGroupByRisk?.High || 0}
              mediumRisk={lightRiskScore?.issuesGroupByRisk?.Medium || 0}
              lowRisk={lightRiskScore?.issuesGroupByRisk?.Low || 0}
              className="offensive-highlight"
            />
          )}
        </GenericCard>
      )}
    </div>
  );
};

export default LeftSection;
