import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

const BarChart = ({
  barData,
  optionsData,
  marginBottom,
  isChartLabelsOnTop,
  onBarClick,
  dynamicLabels = [],
}) => {
  const getSelectedLabel = (labels, index) => {
    return labels[index] !== undefined ? labels[index] : "Unknown";
  };

  const onChartClick = (event, elements) => {    
    if (elements.length === 0) return;
    const { datasetIndex, index: elementIndex } = elements[0];
    // If dynamicLabels has more than 2 items, use datasetIndex
    if (dynamicLabels.length > 3) {
      const selectedLabel = getSelectedLabel(dynamicLabels, elementIndex);
      return onBarClick && onBarClick(selectedLabel);
    }

    if (dynamicLabels.length > 2 && dynamicLabels.length <= 3) {
      const selectedLabel = getSelectedLabel(dynamicLabels, datasetIndex);
      return onBarClick && onBarClick(selectedLabel);
    }
    // If dynamicLabels has 2 or fewer items, use elementIndex
    if (dynamicLabels.length > 0) {
      const selectedLabel = getSelectedLabel(dynamicLabels, elementIndex);
      return onBarClick && onBarClick(selectedLabel);
    }
    // Fallback to "Yes" or "No"
    const fallbackLabel = datasetIndex === 0 ? "Yes" : "No";
    return onBarClick && onBarClick(fallbackLabel);
  };

  return (
    <div
      className="bar-chart-container"
      style={{ marginBottom: marginBottom || "3px" }}
    >
      <div className="col-12 d-flex align-items-center justify-content-center h-100">
        <Bar
          data={barData}
          options={{
            ...optionsData,
            onClick: onChartClick,
          }}
          plugins={isChartLabelsOnTop ? [ChartDataLabels] : []}
        />
      </div>
    </div>
  );
};

export default BarChart;
