import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import UserTimezoneModal from "./components/ModalUserTimezone";
import { sendEvent } from "./util/analytics";
import { locationToPageName } from "./helpers";

function App({ scanningStatus, isSidebarOpen }) {
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.timezone?.length === 0 || !user?.timezone) {
      setModalShow(true);
    }
    const isMobileOrTablet = window.innerWidth <= 1024;

    if (isMobileOrTablet) {
      const metaTag = document.createElement("meta");
      metaTag.name = "viewport";
      metaTag.content = "width=1920";
      document.head.appendChild(metaTag);

      return () => {
        document.head.removeChild(metaTag);
      };
    }
  }, []);

  const location = useLocation();

  useEffect(() => {
    const pageName = locationToPageName(location);
    sendEvent("pageOpen", { pageName });
  }, [location]);

  const confirmationModalHide = () => {
    setModalShow(false);
  };

  return (
    <>
      <Outlet context={{ scanningStatus, isSidebarOpen }} />
      <UserTimezoneModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        confirmationHide={confirmationModalHide}
      />
    </>
  );
}

export default App;
