import React, { useState, useMemo, useRef } from "react";
import { ipRegex } from "../../../util/constants";
import { ThreeCircles } from "react-loader-spinner";
import BaseCheckbox from "../../../components/form/BaseCheckbox";
import TermsConditionsModal from "../../../auth/register/TermsConditionsModal";

const DomainModal = ({
  isOpen,
  onClose,
  onConfirm,
  domain,
  isRejectMode,
  showDomain,
  isDomainLoading,
}) => {
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const formikRef = useRef();
  const { domainsToDisplay, isMultipleDomains, isIp, actionText } =
    useMemo(() => {
      const domainsToDisplay = Array.isArray(domain) ? domain : [domain];
      const isMultipleDomains = domainsToDisplay.length > 1;
      const isIp = domainsToDisplay.every((el) => ipRegex.test(el));
      const actionText = isRejectMode ? "Reject" : "Scan";

      return { domainsToDisplay, isMultipleDomains, isIp, actionText };
    }, [domain, isRejectMode]);

  if (!isOpen) return null;
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const handleAcceptTerms = () => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("agreeTerms", true);
    }
    setShowTermsModal(false);
    setIsTermsAccepted(true);
  };

  const handleDeclineTerms = () => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("agreeTerms", false);
    }
    setShowTermsModal(false);
    setIsTermsAccepted(false);
  };
  const isActive = !isRejectMode ? !isChecked || isDomainLoading : false;

  return (
    <>
      <div className="asset-modal-overlay">
        <div className="asset-modal-content">
          <div className="domain-header">
            <h4>
              {isMultipleDomains
                ? `Are you sure you want to ${actionText.toLowerCase()} ${
                    domainsToDisplay.length
                  } selected ${isIp ? "IP Addresses" : "hostnames"}?`
                : `Are you sure you want to ${actionText.toLowerCase()} ${
                    domainsToDisplay[0]
                  } ${
                    showDomain
                      ? "domain"
                      : `${isIp ? "IP Address" : "hostname"}`
                  }?`}
            </h4>
          </div>
          <p>
            {isMultipleDomains
              ? `These ${isIp ? "IP Addresses" : "hostnames"} will be ${
                  actionText.toLowerCase() == "scan" ? "scanned" : "rejected"
                } ${
                  isRejectMode
                    ? "and will not appear in future scans."
                    : "for related assets, which will automatically be added to the asset inventory."
                }`
              : `This ${
                  showDomain ? "domain" : `${isIp ? "IP Address" : "hostname"}`
                } will be ${
                  actionText.toLowerCase() == "scan" ? "scanned" : "rejected"
                } ${
                  isRejectMode
                    ? "and will not appear in future scans."
                    : "for related assets, which will automatically be added to the asset inventory."
                }`}
          </p>

          {isMultipleDomains && <hr />}

          {isMultipleDomains ? (
            <div
              className="domain-list"
              style={{ maxHeight: "150px", overflowY: "scroll" }}
            >
              {domainsToDisplay.map((d) => (
                <div className="list-domains" key={d}>
                  {d}
                </div>
              ))}
            </div>
          ) : (
            <div></div>
          )}
          {!isRejectMode && (
            <div className="single-domain">
              <BaseCheckbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                name="selectAll"
              />
              <p>
                I have the rights and authorization to scan this target and I
                agree to the{" "}
                <span onClick={() => setShowTermsModal(true)}>
                  Terms of Services
                </span>
              </p>
            </div>
          )}
          <div className="asset-modal-actions">
            <button className="btn asset-modal-button" onClick={onClose}>
              Cancel
            </button>
            <button
              className="btn asset-modal-button"
              onClick={() =>
                onConfirm(domainsToDisplay, isRejectMode, showDomain)
              }
              disabled={isActive}
              style={{
                borderColor: isActive ? "#636363" : "",
                backgroundColor: isActive ? "#252525" : "",
                color: isActive ? "#636363" : "",
                cursor: isActive ? "not-allowed" : "pointer",
              }}
            >
              {isDomainLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ThreeCircles
                    visible={true}
                    height="20"
                    width="20"
                    color="#398ffe"
                    ariaLabel="three-circles-loading"
                    wrapperClass=""
                  />
                </div>
              ) : (
                actionText
              )}
            </button>
          </div>
        </div>
      </div>
      <TermsConditionsModal
        show={showTermsModal}
        onHide={handleDeclineTerms}
        onContinue={handleAcceptTerms}
        onDecline={handleDeclineTerms}
        isAfterRegister={true}
      />
    </>
  );
};

export default DomainModal;