import React, { useEffect, useRef, useState } from "react";
import armorytestedIconSmall from "../../assets/images/armorytestedIconSmall.png";
import armoryWarZoneIcon from "../../assets/images/warTag.png";
import dotMenuIcon from "../../assets/images/dotMenuIcon.png";
import securityissue_Point from "../../assets/images/securityissue_Point.png";
import CvesPopup from "../cves/CvesPopup";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { ReactComponent as FaExclamationCircle } from "../../assets/images/FaExclamationCircle.svg";
import { ReactComponent as InfoTag } from "../../assets/images/infoTag.svg";
import { ReactComponent as OpenCircle } from "../../assets/images/open-circle.svg";
import { ReactComponent as InfoCircle } from "../../assets/images/INFO-CIRCLE.svg";
import { ReactComponent as Acknowledged } from "../../assets/images/Acknowledged.svg";
import { ReactComponent as FalsePositive } from "../../assets/images/False-Positive.svg";
import { ReactComponent as Solved } from "../../assets/images/Solved.svg";
import { ReactComponent as ReOpened } from "../../assets/images/Re-Opened.svg";
import { ReactComponent as PendingValidation } from "../../assets/images/Pending-Validation.svg";
import CriticalityTag from "./CriticalityTag";
import Moment from "react-moment";
import AssetsHost from "./AssetsHost";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ActionPopover from "../../components/actions/ActionPopover";
import { ReactComponent as Elipses } from "../../assets/images/elipses.svg";
import { formatDate } from "../../util/formatDate";
import { UseAssetsModal } from "../../hooks/useAssetsModal.js";

const SecurityIssueCard = ({
  classNameVariant = "",
  RiskPercentage,
  data,
  isGroup = false,
  asset,
  actions,
  onSelectionChange,
  clearSelectedRows,
  handleActionClick,
  type,
  criticalityLevel,
  isActivePopover,
  popoverId,
  handleShowPopover,
  setIsActivePopover,
  isChecked,
  setSelectedIssue,
  selectedIssue,
  isUser
}) => {
  const { openModal } = UseAssetsModal();
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Reject");
  const [action, setAction] = useState("Reject");
  const [details, setDetails] = useState(null);
  const [showAllAssets, setShowAllAssets] = useState(false);
  const dropdownRef = useRef(null);
  const [localIsChecked, setLocalIsChecked] = useState(isChecked);
  const [activePopover, setActivePopover] = useState(null);
  const popover = (
    <Popover id="popover-basic-comming">
      <Popover.Body className="comming-tool-body">
        <label className="comming-text-tool">
          This issue was not resolved after the latest scan.
        </label>
      </Popover.Body>
    </Popover>
  );
  const statusMap = {
    1: { text: "Open", icon: <OpenCircle /> },
    2: { text: "Re-Opened", icon: <ReOpened /> },
    3: { text: "Pending Validation", icon: <PendingValidation /> },
    4: {
      text: "Validation Failed",
      icon: (
        <>
          {" "}
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="top"
            overlay={popover}
            className="mx-3"
          >
            <InfoCircle className="cursor-pointer" />
          </OverlayTrigger>
        </>
      ),
    },
    5: { text: "Solved", icon: <Solved /> },
    6: { text: "False-Positive", icon: <FalsePositive /> },
    7: { text: "Acknowledged", icon: <Acknowledged /> },
  };

  const handleCheckboxChange = (e) => {
    e.stopPropagation();
    const checked = e.target.checked;
    const selectionData = isGroup
      ? { ...asset, groupInfo: data.issue_name }
      : data;
    onSelectionChange(selectionData, checked);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedIssue(null);
  };
  const handleShow = (id) => {
    setSelectedIssue(id);
    setShow(true);
  };
  const mainClass = `securityissueCard_main${classNameVariant ? `_${classNameVariant}` : ""
    }`;
  const valueClass = `securityissueCard_section_3_value${classNameVariant ? `_${classNameVariant}` : ""
    }`;

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setAction(option);
    setShowDropDown(false);
    setShowModal(true);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropDown(false);
    }
  };
  useEffect(() => {
    if (showDropDown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropDown]);
  useEffect(() => {
    setLocalIsChecked(false);
  }, [clearSelectedRows]);

  let hasGenericData = false;
  data?.assets?.map((el) => {
    if (el?.generic_data1 && el?.generic_data1?.pages?.length > 0) {
      hasGenericData = true;
    }
  });
  let issuesFor = "";
  if (hasGenericData) {
    if (data?.service_id == 15) {
      issuesFor = "login";
    } else if (data?.service_id == 5) {
      issuesFor = "certificates";
    }
  }
  const source = isGroup ? asset : data;
  const handleActionSelect = (action) => {
    handleActionClick(action.name, isGroup ? asset : data, 0);
    setActivePopover(null);
  };
  const actionPopover = (id) => (
    <Popover id={`action-single-${id}`} className="p-0">
      <Popover.Body className="p-0">
        <ActionPopover
          rowId={id}
          actions={actions}
          onApply={(rowId, selectedAction) =>
            handleActionSelect(selectedAction)
          }
        />
      </Popover.Body>
    </Popover>
  );
  return (
    <React.Fragment>
      <div
        className="SecurityIssueCard_Main cursor-pointer"
        onClick={() => {
          setDetails(data);
          handleShow(data?.target_isssue_id);
        }}
      >
        <div className="">
          {isUser && (
            <div className="issue-check-box" onClick={(e) => e.stopPropagation()}>
              <input
                type="checkbox"
                className="custom-checkbox me-1 "
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            </div>)}

        </div>
        <div
          className={`w-100 securityissuemain ${isChecked ? "border-action" : ""
            }`}
          style={{
            border:
              selectedIssue && selectedIssue === data?.target_isssue_id
                ? "2px solid #398FFE"
                : "",
          }}
        >
          <div className={`${mainClass} rounder-group-radius`}>
            <div className="securityissueCard_section_1">
              <div className="section_1_part1">
                {!isGroup && (
                  <div className="securityissueCard_title">
                    {data?.issue_name}
                  </div>
                )}
              </div>
              <div
                className={`d-flex align-items-center w-10 ${source.host ? "assets-column-container" : "assets-container"
                  }`}
              >
                <div className="d-flex align-items-center">
                  <div className="section_1_part2 mt-0 pe-2">Asset:</div>
                  {source.domain && source.service_id !== 3 && (
                    <div className="d-flex align-items-center text-unwrap">
                      {source.domain}
                    </div>
                  )}
                  {source.ip && (
                    <div className="d-flex align-items-center text-unwrap text-white">
                      {source.domain && source.service_id !== 3 && (
                        <span className="px-1">|</span>
                      )}
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          openModal(source.ip);
                        }}
                        className="asset-link"
                      >
                        {source.ip}
                      </span>
                    </div>
                  )}

                  {source.port && (
                    <div className="d-flex align-items-center text-unwrap text-white">
                      {(source.domain || source.ip) && (
                        <span className="px-1">|</span>
                      )}
                      Port: {source.port}
                    </div>
                  )}
                </div>
                <div className="d-flex w-100">
                  {source.host && (
                    <div className="d-flex align-items-center text-unwrap ">
                      {(source.domain || source.ip || source.port) && (
                        <span className="px-1">|</span>
                      )}
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          openModal(source.host);
                        }}
                        className="asset-link"
                      >
                        <AssetsHost source={source} />
                      </span>
                    </div>
                  )}
                  <div className="d-flex align-items-center issue-status-type text-unwrap">
                    {source.status && statusMap[source.status] && (
                      <div className=" mt-0 ms-2 pe-1 title-text text-unwrap text-white d-flex align-items-center">
                        <span className="me-1 d-flex">
                          {" "}
                          {statusMap[source.status].icon}
                        </span>
                        {statusMap[source.status].text}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="section-seen-days d-flex align-items-center">
                {(data?.new || asset?.new) && type === "open" && (
                  <div className="is-secuiryt-new-tag">New</div>
                )}
                {(data?.new || asset?.new) && type === "open" && (
                  <span className="title-text mx-2">|</span>
                )}
                <div className="first-seen ">
                  <span className="title-text">First Seen:</span>
                  <span className="data-value-text ms-2">
                    {isGroup
                      ? formatDate(asset?.created_at)
                      : formatDate(data?.first_seen_at)}
                  </span>
                  {type === "history" && (
                    <>
                      <span className="title-text mx-2">|</span>
                      <span className="title-text">Last Seen:</span>
                      <span className="data-value-text ms-2">
                        {isGroup
                          ? formatDate(asset?.updated_at)
                          : formatDate(data?.last_seen_at)}
                      </span>
                    </>
                  )}
                </div>
                <span className="title-text mx-2">|</span>
                <div className="">
                  {type === "open" ? (
                    <span className="title-text">Days Open:</span>
                  ) : (
                    <span className="title-text">Days to Remediation:</span>
                  )}
                  <span className="data-value-text ms-2 text-white">
                    {" "}
                    {source?.days_open}
                  </span>
                </div>
              </div>
            </div>
            <div className="securityissueCard_section_2 d-flex align-items-center">
              <div className="group-categroy-tags">
                <div className="d-flex align-items-center flex-wrap">
                  <CriticalityTag level={criticalityLevel} />
                  {!isGroup && data?.scan_category && (
                    <div className="issues-assets-tag d-flex align-items-center me-2 mb-2">
                      <span className=" text-unwrap">
                        Category: {data?.scan_category}
                      </span>
                    </div>
                  )}
                  <div className="issues-assets-tag d-flex align-items-center me-2 mb-2">
                    <span className=" text-unwrap">Impact: {data?.impact}</span>
                  </div>
                  <div className="issues-assets-tag d-flex align-items-center me-2 mb-2">
                    <span className=" text-unwrap">
                      Exploitability: {data?.exploitability}
                    </span>
                  </div>

                  <div className="countent-cve-small">
                    {(data?.cisa_kev && data.cisa_kev !== "-") ||
                      (data?.epss && data.epss !== "-") ||
                      (data?.cvss && data.cvss !== "-") ? (
                      <div className="issues-assets-tag d-flex align-items-center me-2 mb-2">
                        <OverlayTrigger
                          trigger={["hover", "focus"]}
                          placement="top"
                          overlay={
                            <Popover
                              id="popover-basic-comming"
                              className="custom-popover-arrow"
                            >
                              <Popover.Body className="email-tool-body">
                                <div className="tooltip-content">
                                  {data?.cve && (
                                    <div>
                                      <span className="fw-bold text-black">
                                        CVE:
                                      </span>{" "}
                                      <span className="text-black">Yes</span>
                                    </div>
                                  )}
                                  <div>
                                    <span className="fw-bold text-black">
                                      CVSS:
                                    </span>{" "}
                                    <span className="text-black">
                                      {data?.cvss || "-"}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="fw-bold text-black">
                                      EPSS:
                                    </span>{" "}
                                    <span className="text-black">
                                      {data?.epss || "-"}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="fw-bold text-black">
                                      CISA KEV:{" "}
                                    </span>
                                    <span className="text-black">
                                      {" "}
                                      {data?.cisa_kev
                                        ? data.cisa_kev
                                          .charAt(0)
                                          .toUpperCase() +
                                        data.cisa_kev.slice(1)
                                        : "-"}
                                    </span>
                                  </div>
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <span
                            className="text-unwrap"
                            style={{ cursor: "pointer" }}
                          >
                            +3
                          </span>
                        </OverlayTrigger>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="countent-cve-large">
                  <div className="d-flex">
                    {data?.cve && (
                      <>
                        {data?.cve && (
                          <div
                            className="issues-assets-tag d-flex align-items-center"
                            style={{ marginRight: "8px" }}
                          >
                            <span className="ms-1">CVE: Yes</span>
                          </div>
                        )}
                        <div className="issues-assets-tag d-flex align-items-center">
                          <span className="ms-1">CVSS: {data?.cvss}</span>
                        </div>
                        <div className="issues-assets-tag d-flex align-items-center mx-2">
                          <span className="ms-1">EPSS: {data?.epss}</span>
                        </div>
                        <div className="issues-assets-tag d-flex align-items-center">
                          <span className="ms-1">
                            CISA KEV:{" "}
                            {data?.cisa_kev
                              ? data?.cisa_kev.charAt(0).toUpperCase() +
                              data?.cisa_kev.slice(1)
                              : ""}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="securityissue-section_3 d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <div className="securityissueCard_section_3 me-1 d-flex flex-row">
                  <div className="risk-score-container">
                    <div className="securityissueCard_section_3_text text-nowrap risk-p-r">
                      Risk Score:
                    </div>
                    <div className={valueClass}>{source?.risk_score}</div>
                  </div>

                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      handleShowPopover(asset?.id || data?.target_isssue_id);
                    }}
                  >
                    { isUser && (
                       <OverlayTrigger
                       trigger="click"
                       placement="left"
                       overlay={actionPopover(
                         asset?.id || data?.target_isssue_id
                       )}
                       show={
                         isActivePopover &&
                         popoverId === (asset?.id || data?.target_isssue_id)
                       }
                       onToggle={() => {
                         if (isActivePopover) {
                           setIsActivePopover(false);
                         }
                       }}
                       rootClose
                     >
                       <div className="elipses-container">
                         <Elipses
                           className="cves-popup cursor-pointer "
                           style={{
                             width: "10px",
                             height: "21px",
                           }}
                         />
                       </div>
                     </OverlayTrigger>
                    )}
                  </div>
                </div>
                <div
                  className="section_1_part1 d-flex align-items-center justify-content-left mt-2"
                  style={{
                    justifyContent: "flex-end",
                    marginRight: "13px",
                  }}
                >
                  <div className="securityissueCard_title"></div>
                  {data?.is_war_room && !isGroup ? (
                    <div
                      className={`section_1_part1_subpart d-flex align-items-center justify-content-center ${data?.armory_tested && !isGroup
                          ? "position-absolute"
                          : ""
                        }`}
                      style={{ right: "180px" }}
                    >
                      <img className="h-100" src={armoryWarZoneIcon} />
                      <div className="securityissueCard_subtitlewar">
                        WAR ROOM
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {data?.armory_tested && !isGroup ? (
                    <div className="section_1_part1_subpart d-flex align-items-center justify-content-center">
                      <img className="h-100" src={armorytestedIconSmall} />
                      <div className="securityissueCard_subtitle">
                        ARMORY TESTED
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CvesPopup
        handleClose={handleClose}
        show={show}
        details={details}
        isGroup={isGroup}
        asset={asset}
        riskScore={source.risk_score}
        criticality={criticalityLevel}
      />
    </React.Fragment>
  );
};

export default SecurityIssueCard;
