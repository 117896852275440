import React, { useState, useEffect, useRef, useContext } from "react";
import BaseTable from "../../../components/table/BaseTable.js";
import BaseFilter from "../../../components/filter/BaseFilter.js";
import axios from "../../../util/axios.js";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ActionConfirmationModal from "../../../components/actions/ActionConfirmationModal.jsx";
import DomainListTable from "./DomainListTable.js";
import { ScannerContext } from "../../../components/ScannerContext.js";
import { formatScannerName } from "../../../helpers/formatScannerName.js";
import moment from "moment";
import { locationToPageName } from "../../../helpers/index.js";
import { sendEvent } from "../../../util/analytics.js";
import { showToast } from "../../../util/toasts.js";
import { ThreeCircles } from "react-loader-spinner";
import AssetsArchive from "../asset-archive/AssetsArchive.js";
import { parseFilterString, applyCondition } from "../../../util/conditions.js";
import { assetsInventoryTabsConfig } from "../../../util/tabsConfig.js";
import { UseServices } from "../../../hooks/useServices.js";

const Overview = ({
  webScanner,
  setScanStatus,
  onStatsFetch,
  setIsLoading,
  isActiveLink,
  isUser,
  canEdit,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [initialDataFetched, setInitialDataFetched] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredTargets, setFilteredTargets] = useState([]);
  const [targetScan, setTargetScan] = useState({});
  const [activeFilters, setActiveFilters] = useState([]);
  const [rejectIP, setRejectIP] = useState([]);
  const [isScannerList, setIsScannerList] = useState(false);
  const [totalDomain, setTotalDomain] = useState(0);
  const [currentPage, setCurrentPage] = useState({});
  const { fetchAllServicesItems } = UseServices();
  const [archiveData, setArchiveData] = useState([]);
  const [assetsArchiveStatus, setAssetsArchiveStatus] = useState(null);
  const [assetsSummary, setAssetsSummary] = useState(null);
  const [mapData, setMapData] = useState({});
  const isFirstRunRef = useRef(true);
  const queueRef = useRef([]);
  const isProcessingRef = useRef(false);
  const abortControllerRef = useRef(null);
  const timeoutRef = useRef(null);
  const currentScanIdRef = useRef(id);
  const isFirstFetch = useRef(true);
  const { dropdownItems } = useContext(ScannerContext);
  const [openAccordian, setOpenAccordian] = useState(false);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const logColumns = [
    { Header: "Domain", accessor: "domain", isSortable: false },
    { Header: "Ip Address", accessor: "ip", isSortable: false },
    {
      Header: "Host Name",
      isSortable: false,
      accessor: () => (
        <div className="greentext">Initializing first-time scanning...</div>
      ),
    },
    { Header: "Web Server", accessor: "web", isSortable: false },
  ];

  const [showModal, setShowModal] = useState(false);
  const [tabs, setTabs] = useState([]);

  const [targets, setTargets] = useState([]);
  const [listLogs, setListLogs] = useState([]);

  const statusFilterOptions = [
    {
      id: 0,
      name: "Validating",
      key: "5",
      active: false,
    },
    {
      id: 1,
      name: "Active",
      key: "5",
      active: false,
    },
    {
      id: 2,
      name: "Inactive",
      key: "5",
      active: false,
    },
  ];

  const appendDataOneByOne = async (newItems) => {
    if (isFirstRunRef.current) {
      isFirstRunRef.current = false;
    }
    const modifiedData = getIpsData(newItems);
    for (let i = 0; i < modifiedData.length; i++) {
      if (currentScanIdRef.current !== id) {
        return;
      }
      const newItem = modifiedData[i];
      setFilteredTargets((prevData) => {
        const existingIndex = prevData.findIndex(
          (item) => item.id === newItem.id
        );
        if (existingIndex !== -1) {
          const updatedData = [...prevData];
          updatedData[existingIndex] = newItem;
          return updatedData;
        } else {
          return [...prevData, newItem];
        }
      });
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
  };

  const processQueue = async () => {
    while (queueRef.current.length > 0) {
      if (currentScanIdRef.current !== id) {
        return; // Exit if the current scan ID has changed
      }
      const nextBatch = queueRef.current.shift();
      await appendDataOneByOne(nextBatch);
    }
    isProcessingRef.current = false;
  };

  const updateTabsWithData = (assets) => {
    const domains = Array.from(
      new Set(assets.scanInfo.hosts.map((host) => host.host))
    ).map((host, index) => ({
      id: index,
      name: host,
      type: "Domain: " + host,
      key: "1",
      active: false,
    }));
    const ips = Array.from(new Set(assets.ips.map((ip) => ip.ip))).map(
      (ip, index) => ({
        id: index,
        name: ip.ip,
        type: "IP Address: " + ip?.ip,
        key: "2",
        active: false,
      })
    );
    const hostingProvider = Array.from(
      new Set(
        assets.ips
          .map((server) => server.hosting_provider)
          ?.filter((provider) => provider)
      )
    ).map((server, index) => ({
      id: index,
      name: server,
      type: "Hosting Provider: " + server,
      key: "4",
      active: false,
    }));
    const cdn = Array.from(
      new Set(
        assets.ips
          .map((server) => server.cdn_name)
          ?.filter((provider) => provider)
      )
    ).map((server, index) => ({
      id: index,
      name: server,
      type: "CDN: " + server,
      key: "6",
      active: false,
    }));
    const hosts = Array.from(
      new Set(
        assets.ips.flatMap((host) =>
          host.scan_hosts.map((scanHost) => scanHost.host.host)
        )
      )
    ).map((server, index) => ({
      id: index,
      name: server,
      type: "Hostname: " + server,
      key: "3",
      active: false,
    }));
    const ports = Array.from(
      new Set(
        assets.ips.flatMap((host) =>
          host.scan_ports.map((scanHost) => `${scanHost.ports.port}`)
        )
      )
    ).map((server, index) => ({
      id: index,
      name: server,
      type: "Port: " + server,
      key: "7",
      active: false,
    }));
    const secutiryIssues = Array.from(
      new Set(assets.ips.map((ip) => ip.number_issues))
    ).map((el, index) => ({
      id: index,
      name: `${el}`,
      type: "Security Issues: " + `${el}`,
      key: "8",
      active: false,
    }));
    setTabs(
      assetsInventoryTabsConfig({
        domains,
        hosts,
        ips,
        statusFilterOptions,
        hostingProvider,
        cdn,
        ports,
        secutiryIssues,
      })
    );
  };

  const createModifiedIpObject = (
    ip,
    statusValue,
    domain_id = "",
    domain = null,
    scan_hosts = []
  ) => {
    return {
      ...ip,
      id: ip.id,
      domain_id: domain_id,
      domain: domain,
      isSortable: true,
      ip: ip.ip,
      is_processed: ip.is_processed,
      status: ip.status,
      ip_id: ip.ip_id,
      ipData: ip.ip.ip,
      scan_hosts: scan_hosts,
      web_ports: ip.web_ports,
      cdn_name: null,
      hosting_provider: ip.hosting_provider,
      scan_ports: ip.scan_ports,
      number_issues: ip?.number_issues,
      network_zone: ip?.network_zone,
      scan_ip_categories: ip?.scan_ip_categories,
      statusValue,
      targetUID: ip?.target_uuid || null,
      created_at: ip.ip?.created_at,
      discovered_by: ip.ip?.discovered_by || null,
      criticality: ip.ip?.criticality || null,
    };
  };

  const getIpsData = (data) => {
    const modifiedData = [];

    data.ips.forEach((ip) => {
      if (ip.scan_hosts?.length > 0) {
        const groupedHosts = ip.scan_hosts.reduce((acc, scanHost) => {
          const domainId = scanHost.host?.domain_id;
          if (domainId) {
            if (!acc[domainId]) {
              acc[domainId] = [];
            }
            acc[domainId].push(scanHost);
          }
          return acc;
        }, {});

        Object.keys(groupedHosts).forEach((domainId) => {
          const matchingHosts = data.scanInfo.scan_target_hosts.filter(
            (host) => host.id === parseInt(domainId, 10)
          );

          matchingHosts.forEach((host) => {
            let statusValue = "";
            if (!ip?.is_processed) {
              statusValue = "Validating";
            } else {
              statusValue = ip?.status ? "Active" : "Inactive";
            }

            modifiedData.push(
              createModifiedIpObject(
                ip,
                statusValue,
                host.id,
                host.host,
                groupedHosts[domainId]
              )
            );
          });
        });
      } else {
        let statusValue = "";
        if (!ip?.is_processed) {
          statusValue = "Validating";
        } else {
          statusValue = ip?.status ? "Active" : "Inactive";
        }
        modifiedData.push(createModifiedIpObject(ip, statusValue));
      }
    });

    return modifiedData;
  };

  const listScanLogs = (data) => {
    const logs = data.map((host) => ({
      domain: host.host.match(/\b(?:\d{1,3}\.){3}\d{1,3}\b/) ? "" : host.host,
      ip: host.host.match(/\b(?:\d{1,3}\.){3}\d{1,3}\b/) ? host.host : "",
      host: host.host,
      web: "",
    }));
    setListLogs(logs);
  };
  useEffect(() => {
    if (isActiveLink) {
      if (isActiveLink === "Asset Archive") {
        fetchAssetsArchive();
      } else {
        fetchScans();
      }
    }
  }, [isActiveLink]);
  const fetchAssetsArchive = async () => {
    setIsLoadingState(true);
    let scanId = id;
    if (!scanId && targets.length > 0) {
      scanId = targets[0].id;
    }

    try {
      const { data } = await axios.get(`/assets/${scanId}/archive`);
      if (data) {
        const modifiedArchiveData = data.items.flatMap((item, index) => {
          const baseData = {
            id: item.id,
            ip: item.ip || "-",
            domain: item?.target_host?.host,
            discovered_scan_id: item.discovered_scan_id,
            domain_id: item.domain_id,
            target_id: item.target_id,
            is_rejected: item.is_rejected,
            is_removed: item.is_removed,
            criticality: item.criticality,
            discovered_by: item.discovered_by,
            first_detected: item.created_at || item.target_host?.created_at,
            archive_date: item.updated_at,
            host: item.target_host?.host || "-",
            host_criticality: item.target_host?.host_criticality,
            host_type: item.target_host?.host_type,
            is_approved: item.target_host?.is_approved,
            network_zone: item?.sip?.network_zone,
            hosting_provider: item.sip?.hosting_provider || "-",
            cdn_name: item.sip?.cdn_name || "-",
            is_processed: item.sip?.is_processed,
            status: item.sip?.status,
            number_issues: item.sip?.number_issues || 0,
            scan_ports:
              item.sip?.scan_ports.map((port) => port.ports.port) || [],
          };
          if (item.hosts && item.hosts.length > 0) {
            return item.hosts.map((host, hostIndex) => ({
              ...baseData,
              id: host?.id,
              host: host.host || baseData.host,
              discovered_by: host.discovered_by || item.discovered_by,
              criticality: host.criticality || item.criticality,
              is_rejected: host.is_rejected || item.is_rejected,
              is_removed: host.is_removed || item.is_removed,
              first_detected: host.created_at || baseData.created_at,
              archive_date: host?.updated_at || item?.updated_at,
              host_status_code: host?.sih?.host_status_code,
              host_protocol: host?.sih?.host_protocol,
              cdn_name: host?.sih?.cdn_name,
            }));
          } else {
            return {
              ...baseData,
              id: item.id || "null",
            };
          }
        });

        const sortedArchiveData = modifiedArchiveData.sort((a, b) => {
          const dateA = a.archive_date ? new Date(a.archive_date) : new Date(0);
          const dateB = b.archive_date ? new Date(b.archive_date) : new Date(0);
          return dateB - dateA;
        });
        setArchiveData(sortedArchiveData);
        setAssetsArchiveStatus(data.groups);
        setAssetsSummary(data.groups?.stats);
      }
    } catch (error) {
      console.error("Error fetching assets archive:", error);
    } finally {
      setIsLoadingState(false);
    }
  };
  const fetchScans = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;
    try {
      let scanId = id;
      if (!scanId && targets.length > 0) {
        scanId = targets[0].id;
      }

      if (scanId) {
        const { data } = await axios.get(`/scans/${scanId}`, {
          signal: controller.signal,
        });
        if (!data.scanInfo) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(fetchScans, 2000);
          return;
        }
        const service = data?.scanInfo?.services.find(
          (service) => service.service_id
        );

        if (data?.scanInfo?.status == -1) {
          setScanStatus(4);
        } else {
          setScanStatus(data?.scanInfo?.services[0].service_status);
        }
        if (
          isFirstFetch.current ||
          service?.service_status === 3 ||
          service?.service_status === -1
        ) {
          const modifiedData = getIpsData(data);

          setFilteredTargets(modifiedData);

          if (isFirstFetch.current) {
            isFirstFetch.current = false;
          }
        } else {
          const newIps = {
            ips: data?.ips || [],
            scanInfo: data?.scanInfo || {},
            targetUID: data?.target_uuid || null,
          };

          queueRef.current.push(newIps);

          if (!isProcessingRef.current) {
            isProcessingRef.current = true;
            processQueue();
          }
        }
        if (data?.scanInfo?.services[0].service_status === 3) {
          clearTimeout(timeoutRef.current);
        }
        if (!data?.ips || data.ips.length === 0) {
          listScanLogs(data?.scanInfo?.scan_target_hosts);
          setIsScannerList(true);
        } else {
          setIsScannerList(false);

          if (service?.service_status === 3 || service?.service_status === -1) {
            setTimeout(() => {
              clearTimeout(timeoutRef.current);
              console.log("Stopping fetch due to service status 3 or -1");
            }, 5000);
          }
        }
        if (data?.scanInfo) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(fetchScans, 5000);
        } else {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(fetchScans, 2000);
        }
        webScanner(data);
        let totalHostnames = 0;
        let webServersCount = 0;

        data?.ips?.forEach((ipItem) => {
          if (ipItem.scan_hosts && Array.isArray(ipItem.scan_hosts)) {
            totalHostnames += ipItem.scan_hosts.length;
          }
          if (ipItem.web_ports && ipItem.web_ports.length > 0) {
            webServersCount += 1;
          }
        });

        onStatsFetch({
          domains: data.totals.domain,
          ips: data.totals.ips,
          hosts: totalHostnames,
          webServers: webServersCount,
          totalIssues: data.scanInfo?.totalIssues,
          new: 0,
          removed: 0,
        });
        setMapData(data);
        updateTabsWithData(data);
        let totalActive = 0;
        let totalActiveHost = 0;
        data.ips.forEach((ip) => {
          if (ip?.is_processed && ip.status === 1) {
            totalActive++;
            totalActiveHost += ip.scan_hosts.length;
          }
        });

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
      if (!initialDataFetched) {
        setInitialDataFetched(true);
      }
    } catch (err) {
      if (err?.response?.data?.message === "Unable to find the scan!") {
        navigate(-1);
      } else {
        onStatsFetch({});
        setFilteredTargets([]);
        setMapData([]);
        if (!controller.signal?.aborted) {
          setIsLoading(false);
        }
        setTotalDomain(0);
        webScanner(null);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getExportedRows = (dataForExport) => {
    const selectedIds = Object.keys(selectedRows)
      .filter((id) => selectedRows[id])
      .map(Number);
    const filteredData =
      selectedIds.length > 0
        ? dataForExport.filter((item) => selectedIds.includes(item.id))
        : dataForExport;

    const result = filteredData.map((item) => {
      return item;
    });

    const body = result.flatMap((ipData) => {
      const domain = ipData?.domain || "";
      const ipAddress = ipData.ip?.ip || "";
      const statusValue = !ipData?.is_processed
        ? "Validating"
        : ipData?.status
        ? "Active"
        : "Inactive";
      const hostingProvider = ipData?.hosting_provider || "";
      const cdnName = ipData?.cdn_name || "";
      const ports = `"${ipData.scan_ports
        .map((hostData) => hostData?.ports.port)
        .join(", ")}"`;

      return ipData.scan_hosts.map((hostData) => {
        const host = hostData?.host.host || "";
        return [
          domain,
          host,
          ipAddress,
          statusValue,
          hostingProvider,
          cdnName,
          ports,
        ];
      });
    });

    return body;
  };

  const fetchTargets = async () => {
    try {
      const { data } = await axios.get(`targets`);
      setTargets(data.items);
      if (data.items.length <= 0) {
        navigate("/assets-inventory/add");
      }
    } catch (err) {}
  };

  const init = async () => {
    await fetchTargets();
    setIsLoading(true);
    fetchScans();
  };

  useEffect(() => {
    const targetScan = dropdownItems.filter((item) => {
      return item.target_uuid === id;
    })[0];
    setTargetScan(targetScan);
  }, [targets]);

  useEffect(() => {
    if (id) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      queueRef.current = [];
      isProcessingRef.current = false;
      setFilteredTargets([]);
      currentScanIdRef.current = id;
      isFirstFetch.current = true;
      isFirstRunRef.current = true;
      init();
      return () => {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        clearTimeout(timeoutRef.current);
      };
    }
  }, [id]);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    resetCurrentPage();
    setOpenAccordian(true);
  };

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
    resetCurrentPage();
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const resetCurrentPage = () => {
    const resetPages = Object.keys(currentPage).reduce((acc, key) => {
      acc[key] = 1;
      return acc;
    }, {});
    setCurrentPage(resetPages);
  };

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const filteredData = filteredTargets.filter((target) => {
    const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
      return groupedFilters[eventKey].some((filter) => {
        const lowerCaseFilter = filter.name.toLowerCase();
        if (eventKey === "1") {
          return (
            target?.domain && target.domain.toLowerCase() === lowerCaseFilter
          );
        } else if (eventKey === "2") {
          return (
            target.ip &&
            target.ip.ip &&
            target.ip.ip.toLowerCase() === lowerCaseFilter
          );
        } else if (eventKey === "3") {
          return (
            target.scan_hosts &&
            target.scan_hosts.some(
              (host) =>
                host.host &&
                typeof host.host === "object" &&
                host.host.host &&
                typeof host.host.host === "string" &&
                host.host.host.toLowerCase() === lowerCaseFilter
            )
          );
        } else if (eventKey === "4") {
          return (
            target.hosting_provider &&
            target.hosting_provider.toLowerCase() === lowerCaseFilter
          );
        } else if (eventKey === "5") {
          return (
            (lowerCaseFilter === "validating" && !target.is_processed) ||
            (lowerCaseFilter === "active" &&
              target.is_processed &&
              target.status === 1) ||
            (lowerCaseFilter === "inactive" &&
              target.is_processed &&
              !target.status)
          );
        } else if (eventKey === "6") {
          return (
            target.cdn_name && target.cdn_name.toLowerCase() === lowerCaseFilter
          );
        } else if (eventKey === "7") {
          return (
            target.scan_ports &&
            target.scan_ports.some(
              (port) =>
                port?.ports.port &&
                `${port.ports.port}`.toLowerCase() === lowerCaseFilter
            )
          );
        } else if (eventKey === "8") {
          return Number(target?.number_issues) == Number(filter.name);
        } else if (eventKey === "advanced-filter") {
          const parsedFilters = parseFilterString(filter.name);
          return parsedFilters.every((ol) => {
            const { column, condition, value } = ol;
            switch (column) {
              case "domain":
                return applyCondition(
                  target.domain?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "host":
                return (
                  target.scan_hosts &&
                  target.scan_hosts.some((host) =>
                    applyCondition(
                      host.host.host.toLowerCase(),
                      condition,
                      value?.toLowerCase()
                    )
                  )
                );
              case "ip":
                return applyCondition(
                  target.ip.ip.toLowerCase().toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "status":
                return (
                  (value?.toLowerCase() === "validating" &&
                    !target.is_processed) ||
                  (value?.toLowerCase() === "active" &&
                    target.is_processed &&
                    target.status === 1) ||
                  (value?.toLowerCase() === "inactive" &&
                    target.is_processed &&
                    !target.status)
                );
              case "hosting_provider_discovery":
                return applyCondition(
                  target.hosting_provider?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "cdn_name_discovery":
                return applyCondition(
                  target.cdn_name?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "number_issues":
                return applyCondition(
                  `${target?.number_issues}`,
                  condition,
                  value
                );
              case "port":
                return (
                  target.scan_ports &&
                  target.scan_ports.some((port) =>
                    applyCondition(
                      `${port.ports.port}`.toLowerCase(),
                      condition,
                      value?.toLowerCase()
                    )
                  )
                );
              default:
                return false;
            }
          });
        }
        return false;
      });
    });

    const matchesSearch =
      searchValue === "" ||
      (target.domain &&
        typeof target.domain === "string" &&
        target.domain.toLowerCase().includes(searchValue.toLowerCase())) ||
      (target.ip &&
        target.ip.ip &&
        typeof target.ip.ip === "string" &&
        target.ip.ip.toLowerCase().includes(searchValue.toLowerCase())) ||
      (target.scan_hosts &&
        Array.isArray(target.scan_hosts) &&
        target.scan_hosts.some(
          (host) =>
            host.host &&
            typeof host.host === "object" &&
            host.host.host &&
            typeof host.host.host === "string" &&
            host.host.host.toLowerCase().includes(searchValue.toLowerCase())
        )) ||
      (target.web_ports &&
        typeof target.web_ports === "string" &&
        target.web_ports.toLowerCase().includes(searchValue.toLowerCase()));

    return matchesFilters && matchesSearch;
  });

  const onRowSelect = (selectedRow) => {
    setSelectedRows(selectedRow);
    const selectedIpsToAdd = [];
    const selectedIpsToRemove = [];
    Object.entries(selectedRow).forEach(([rowId, isSelected]) => {
      const selectedData = filteredData.find((row) => row.id === Number(rowId));
      if (selectedData) {
        const ipData = {
          domain_id: selectedData?.ip?.domain_id,
          ip_id: selectedData?.ip_id,
          ip: selectedData?.ip?.ip,
        };
        if (isSelected) {
          selectedIpsToAdd.push(ipData);
        } else {
          selectedIpsToRemove.push(ipData);
        }
      }
    });

    setRejectIP((prevIps) => {
      const updatedIps = prevIps.filter(
        (ip) =>
          !selectedIpsToRemove.some(
            (selectedIp) => selectedIp.ip_id === ip.ip_id
          )
      );
      return [...updatedIps, ...selectedIpsToAdd];
    });
    setRejectIP(selectedIpsToAdd);
  };
  const handleApplyActions = (data) => {
    const ips = [];
    ips.push({
      domain_id: data.data?.domain_id,
      ip_id: data.data?.ip_id,
      ip: data?.data?.ip,
    });
    setRejectIP(ips);
    setShowModal(true);
  };

  const location = useLocation();
  const handleRejectSubmit = () => {
    axios
      .post(`/scans/${id}/rejects`, rejectIP)
      .then((response) => {
        showToast(response.data.message, "success", "top-center");

        const pageName = locationToPageName(location);
        sendEvent("buttonClick", {
          pageName,
          result: "rejectIP",
          ip: rejectIP,
        });
        setShowModal(false);
        fetchAllServicesItems(id);
        fetchScans();
      })
      .catch((e) => {});
  };
  const updateTotalDomain = (count) => {
    setTotalDomain(count);
  };
  return (
    <>
      {isActiveLink === "Overview" ? (
        <div className="my-3 list_header">
          <BaseFilter
            tabs={tabs}
            onFilterChange={handleFilterChange}
            activeFilters={activeFilters}
            removeFilter={removeFilter}
            totalRecords={totalDomain}
            exportTitle={`AssetInventory_${formatScannerName(
              targetScan?.title
            )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
            exportHeader={[
              "Domain",
              "Hostname",
              "IP Address",
              "Status",
              "Hosting Provider",
              "CDN",
              "Port",
            ]}
            exportRows={getExportedRows(filteredData)}
            activeRows={rejectIP}
            handleApplyActions={() => setShowModal(true)}
            searchValue={searchValue}
            onSearchChange={handleSearchChange}
            isSearch={true}
            tableData={filteredData}
          />
          {showModal && (
            <ActionConfirmationModal
              showModal={showModal}
              onHide={() => setShowModal(false)}
              onReject={handleRejectSubmit}
              data={rejectIP}
            />
          )}

          <>
            {isScannerList ? (
              <BaseTable
                columns={logColumns}
                data={listLogs}
                selectable={false}
                showCheckboxes={false}
                action={false}
              />
            ) : (
              <DomainListTable
                domains={filteredData}
                servicesStatus={mapData.servicesStatus}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handleApplyActions={handleApplyActions}
                onRowSelect={onRowSelect}
                accordianEnabled={openAccordian}
                updateTotalDomain={updateTotalDomain}
                isUser={isUser}
                canEdit={canEdit}
              />
            )}
          </>
        </div>
      ) : isActiveLink === "Asset Archive" ? (
        <>
          {isLoadingState ? (
            <div className="content-loader">
              <ThreeCircles
                visible={true}
                height="60"
                width="60"
                color="#ffff"
                ariaLabel="three-circles-loading"
                wrapperClass=""
              />
            </div>
          ) : (
            <AssetsArchive
              data={archiveData}
              servicesStatus={mapData.servicesStatus}
              assetsStatus={assetsArchiveStatus}
              summary={assetsSummary}
            />
          )}
        </>
      ) : null}
    </>
  );
};

export default Overview;
