import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import axios from "../../util/axios";
import PasswordRequirements from "../../components/password-requirement/PasswordRequirements";
import logopng from "../../assets/images/logo.png";
import {
  FormGroup,
  FloatingLabel,
  FormControl,
  Button,
  Stack,
  FormSelect,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Linker from "../../components/Linker";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import "react-toastify/dist/ReactToastify.css";
import TermsConditionsModal from "./TermsConditionsModal";
import { showToast } from "../../util/toasts";

const labelStyle = "original";
const timezones = {
  ...allTimezones,
};
const validatePassword = (password, fullName) => {
  if (!fullName) return true;
  const nameParts = fullName.toLowerCase().split(" ");
  for (const part of nameParts) {
    if (password.toLowerCase().includes(part)) {
      return false;
    }
  }
  return true;
};

const schema = Yup.object().shape({
  fullname: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Full name is required"),
  email: Yup.string().email().required("Email is required"),
  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$&*]).{8,}$/,
      "Password must be at least 8 characters, with at least one uppercase letter, one number, and one special character"
    )
    .test(
      "password-check",
      "Password cannot include your full name or parts of it",
      function (value) {
        const { fullname } = this.parent;
        return validatePassword(value, fullname);
      }
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Confirm Password does not match")
    .required("Confirm Password is required"),
  agreeTerms: Yup.boolean()
    .oneOf([true], "You must accept the terms and conditions")
    .required("You must accept the terms and conditions"),
  timezone: Yup.string().required("Timezone is required"),
});

const Register = () => {
  const navigate = useNavigate();
  const routeParams = useParams();
  const [submitBtn, setSubmitBtn] = useState({});
  const [invitation, setInvitation] = useState(null);
  const [disableEmail, setDisableEmail] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const formikRef = useRef();
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones,
  });
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const initialValues = {
    fullname: "",
    email: "",
    password: "",
    confirmPassword: "",
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    agreeTerms: false,
  };
  const [isLoading, setIsLoading] = useState(false);
  let API_ENDPOINT_NODE = "/signup";

  const init = async () => {
    await fetchInvitation();
  };
  const fetchInvitation = async () => {
    const params = {
      code: routeParams.code,
    };
    try {
      const { data } = await axios.get("/check-invitation", { params });

      setInvitation(data.data);
      if (data.data.email) {
        initialValues.email = data.data.email;
        setDisableEmail(true);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        setTimeout(() => {
          navigate("/auth/login?iu=1");
        }, 500);
      }

      if (err.response.status === 400) {
        setTimeout(() => {
          navigate("/expired");
        }, 100);
      }
    }
  };

  const handleSubmit = (values, helpers) => {
    setIsLoading(true);
    if (invitation) values.code = invitation.code;

    axios
      .post(API_ENDPOINT_NODE, values)
      .then((response) => {
        const data = response.data;
        console.log("API response:", response);
        showToast(
          data.message || "Registration Successful",
          "success",
          "top-center",
          "light",
          { autoClose: 5000 }
        );

        setTimeout(() => {
          navigate("/auth/login");
        }, 2000);
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.response && e.response.status === 422) {
          const errors = e.response.data;
          errors.forEach((error) => {
            helpers.setFieldError(error.path, error.msg);
          });
        } else {
          showToast(
            "An error occurred during registration. Please try again.",
            "error",
            "top-center",
            "light",
            { autoClose: 5000 }
          );
        }
      });
  };

  useEffect(() => {
    if (isLoading) {
      setSubmitBtn({ disabled: true, label: "Please wait.." });
    } else {
      setSubmitBtn({ disabled: false, label: "Sign Up" });
    }
  }, [isLoading]);

  useEffect(() => {
    init();
  }, []);

  const handleAcceptTerms = () => {
    setShowTermsModal(false);
    setIsTermsAccepted(true);
    formikRef.current.setFieldValue("agreeTerms", true);
  };

  const handleDeclineTerms = () => {
    setShowTermsModal(false);
    setIsTermsAccepted(false);
    formikRef.current.setFieldValue("agreeTerms", false);
  };
  const fullNameInputChange = (field) => {
    const regex = /^[a-zA-Z0-9\s\-.']+$/;
    if (!regex.test(field.value)) {
      field.value = field.value.replace(/[^a-zA-Z0-9\s\-.']/g, "");
    }
  };

  const passwordChange = (field) => {
    if (field.value.includes("<") || field.value.includes(">")) {
      field.value = field.value.replace(/[<>]/g, "");
    }
  };

  return (
    <React.Fragment>
      {/* new register */}
      <div className="register-fragment">
        <div className="register-main">
        <img src={logopng} alt="Logo" className="mt-3 d-block mx-auto img-fluid logo-img" /> 
          <h2 className="mt-4 text-center ">GET STARTED</h2>
          <div className="register-form">
            <div className="register-input">
              <Formik
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                innerRef={formikRef}
                render={({
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  values,
                  errors,
                  validateForm,
                  isValid,
                  isSubmitting,
                }) => (
                  <Form>
                    <Field
                      name="fullname"
                      render={({ field, form: { touched, errors }, meta }) => (
                        <FormGroup className="mt-3 " controlId="fullname">
                          <label controlId="floatingPassword">Full Name</label>
                          <FormControl
                            type={"text"}
                            size="lg"
                            value={field.value}
                            onChange={fullNameInputChange(field)}
                            placeholder="Enter your full name"
                            className={
                              meta.touched && meta.error ? "is-invalid" : ""
                            }
                            {...field}
                          />
                          {meta.touched && meta.error ? (
                            <div className="invalid-feedback">{meta.error}</div>
                          ) : null}
                        </FormGroup>
                      )}
                    />
                    <Field
                      name="email"
                      render={({ field, form: { touched, errors }, meta }) => (
                        <FormGroup className="mt-3 " controlId="email">
                          <label>Email</label>
                          <FormControl
                            type={"text"}
                            size="lg"
                            disabled={disableEmail}
                            value={field.value}
                            onChange={field.onChange}
                            placeholder="Enter your email"
                            className={
                              meta.touched && meta.error ? "is-invalid" : ""
                            }
                            {...field}
                          />
                          {meta.touched && meta.error ? (
                            <div className="invalid-feedback">{meta.error}</div>
                          ) : null}
                        </FormGroup>
                      )}
                    />
                    <Field name="password">
                      {({ field, form }) => (
                        <FormGroup className="mt-3" controlId="password">
                          <label>Password</label>
                          <FormControl
                            placeholder="Enter a  password "
                            type="password"
                            size="lg"
                            onChange={passwordChange(field)}
                            className={
                              form.touched.password && form.errors.password
                                ? "is-invalid"
                                : ""
                            }
                            {...field}
                          />
                          {form.touched.password && form.errors.password && (
                            <div className="invalid-feedback">
                              {form.errors.password}
                            </div>
                          )}
                        </FormGroup>
                      )}
                    </Field>

                    <Field
                      name="confirmPassword"
                      render={({ field, form: { touched, errors }, meta }) => (
                        <FormGroup
                          className="mt-3 "
                          controlId="confirmPassword"
                        >
                          <label>Confirm password</label>
                          <FormControl
                            type={"password"}
                            size="lg"
                            value={field.value}
                            onChange={field.onChange}
                            placeholder="Confirm your password"
                            className={
                              meta.touched && meta.error ? "is-invalid" : ""
                            }
                            {...field}
                          />
                          {meta.touched && meta.error ? (
                            <div className="invalid-feedback">{meta.error}</div>
                          ) : null}
                        </FormGroup>
                      )}
                    />
                    <Field
                      as="select"
                      name="timezone"
                      render={({ field, form: { touched, errors }, meta }) => (
                        <FormGroup className="mt-3 " controlId="timezone">
                          <label>Select Timezone</label>
                          <FormSelect
                            value={field.value}
                            onChange={field.onChange}
                            className={
                              meta.touched && meta.error ? "is-invalid" : " "
                            }
                            {...field}
                          >
                            <option value="">Select Timezone</option>
                            {options.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </FormSelect>
                          {meta.touched && meta.error ? (
                            <div className="invalid-feedback">{meta.error}</div>
                          ) : null}
                        </FormGroup>
                      )}
                    />

                    <Field name="agreeTerms">
                      {({ field, form }) => (
                        <FormGroup
                          controlId="agreeTerms"
                          className="my-3 cursor-pointer"
                          onClick={() => setShowTermsModal(true)}
                        >
                          <div className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              checked={field.value}
                              className={`base-checkbox custom-checkbox${
                                form.touched.agreeTerms &&
                                form.errors.agreeTerms
                                  ? "is-invalid"
                                  : ""
                              }`}
                              readOnly
                            />
                            <label className="ms-2">
                              I agree to the Terms and Conditions
                            </label>
                          </div>
                          {form.touched.agreeTerms &&
                            form.errors.agreeTerms && (
                              <div className="invalid-feedback d-block">
                                {form.errors.agreeTerms}
                              </div>
                            )}
                        </FormGroup>
                      )}
                    </Field>
                    <hr />
                    <div>
                      <PasswordRequirements
                        password={values.password}
                        setPasswordValid={setIsPasswordValid}
                      />
                    </div>
                    <div className="base-modal">
                      <Button
                        type="submit"
                        className="mt-2 btn btn-primary btn-full mx-auto "
                        id="singup-btn"
                        disabled={
                          !isValid ||
                          isSubmitting ||
                          isLoading ||
                          !isPasswordValid
                        }
                      >
                        {isLoading ? "Please wait.." : "Sign Up"}
                      </Button>
                    </div>

                    <TermsConditionsModal
                      show={showTermsModal}
                      onHide={handleDeclineTerms}
                      onContinue={handleAcceptTerms}
                      onDecline={handleDeclineTerms}
                      closeOption={false}
                      isContactLink={false}
                    />

                    <div className="d-flex justify-content-center align-items-center mt-2 mx-auto text-white">
                      Already have an account?{" "}
                      <Linker
                        to={"/auth/login"}
                        title="Proceed to Login"
                        className="m-1 "
                        style={{
                          color: "rgba(57, 143, 254, 1)",
                          textDecoration: "underline",
                          fontSize: 13,
                        }}
                      >
                        {"Sign in"}
                      </Linker>
                    </div>
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Register;
