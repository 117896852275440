import axios from "../util/axios";
import { showToast, showUpdateToast, closeToast } from "../util/toasts";

export const downloadPDFReport = async ({
  url,
  fileName,
  setLoadingState,
  controller,
  toastOptions = { position: "top-center", theme: "light" },
  activeDownloads,
}) => {
  const toastId = showToast(
    `Generating Report`,
    "info",
    toastOptions.position,
    toastOptions.theme,
    { isLoading: true },
    false
  );
  
  // Store the toast ID in the context's tracking object
  activeDownloads[url] = {
    toastId,
    lastUpdate: 0,
    lastLoaded: 0,
    completed: false
  };

  setLoadingState(true);

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json, text/plain, */*",
      },
      responseType: "blob",
      signal: controller.signal,
      onDownloadProgress: (progressEvent) => {
        // Check if this download is still being tracked
        if (!activeDownloads[url]) return;
        
        const now = Date.now();
        if (now - activeDownloads[url].lastUpdate > 250) {
          activeDownloads[url].lastUpdate = now;
          const totalBytes = progressEvent.total || progressEvent.loaded + 100000;
          const percent = Math.min(99, Math.round((progressEvent.loaded * 100) / totalBytes));
          
          if (percent !== activeDownloads[url].lastLoaded) {
            activeDownloads[url].lastLoaded = percent;
            showUpdateToast(activeDownloads[url].toastId, `Download is Progress ${percent}%`, "info", true);
          }
        }
      },
    });

    const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
    if (activeDownloads[url]) {
      activeDownloads[url].completed = true;
      showUpdateToast(activeDownloads[url].toastId, "Report downloaded successfully", "success", false);
      
      setTimeout(() => {
        delete activeDownloads[url];
      }, 2000);
    }
  } catch (e) {
    if (activeDownloads[url]) {
      showUpdateToast(
        activeDownloads[url].toastId,
        `${e?.response?.data?.message || "Failed to generate report."}`,
        "error",
        false,
        { className: "toast-report" }
      );
      setTimeout(() => {
        delete activeDownloads[url];
      }, 2000);
    }
  } finally {
    setLoadingState(false);
  }
};