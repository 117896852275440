import React from "react";
import { ReactComponent as Folder } from "../../../assets/images/folder.svg";
const EmptyAssetsArchive = () => {
  return (
    <>
      <div className="data-processing-screen-main">
        <Folder className="icon mb-2" />
        <h1 className="mb-2 empty-message-title">
          No Assets Have Been Archived Yet
        </h1>
        <p className="empty-message-description">
          Rejected or removed assets will be listed here
        </p>
      </div>
    </>
  );
};

export default EmptyAssetsArchive;
