import React, { createContext, useContext, useState } from "react";
import { closeToast } from "../util/toasts";

const ReportsContext = createContext();
// Track active downloads within the context instead of globally
const activeDownloads = {};

export function UseReports() {
  return useContext(ReportsContext);
}

export const ReportsProvider = ({ children }) => {
  const [downloadingReports, setDownloadingReports] = useState({ 2: false, 3: false });

  const cleanupDownloads = () => {
    Object.keys(activeDownloads).forEach(url => {
      if (activeDownloads[url].completed) {
        closeToast(activeDownloads[url].toastId);
        delete activeDownloads[url];
      }
    });
  };

  return (
    <ReportsContext.Provider
      value={{
        downloadingReports,
        setDownloadingReports,
        activeDownloads,
        cleanupDownloads,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
}; 