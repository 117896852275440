import React, { useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
} from "chart.js";
import { colors } from "../../util/colors.js";

ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip
);

const LineChart = ({ data, options, id, showRiskLevels }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const resizeChart = () => {
      if (chartRef.current) {
        chartRef.current.resize();
      }
    };

    window.addEventListener("resize", resizeChart);
    return () => window.removeEventListener("resize", resizeChart);
  }, []);

  const createExternalTooltip = (id) => {
    return function (context) {
      const tooltipModel = context.tooltip;
      let tooltipEl = document.getElementById(id);

      if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.id = id;
        tooltipEl.style.zIndex = "9999";
        tooltipEl.style.position = "absolute";
        tooltipEl.style.pointerEvents = "none";
        tooltipEl.style.background = "white";
        tooltipEl.style.padding = "12px";
        tooltipEl.style.borderRadius = "5px";
        tooltipEl.style.boxShadow = "0px 0px 8px rgba(0, 0, 0, 0.2)";
        document.body.appendChild(tooltipEl);
      }

      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }
      if (tooltipModel.dataPoints) {
        const dataPoint = tooltipModel.dataPoints[0];
        const dateLabel = dataPoint.label;
        const value = dataPoint.raw;
        const datasetIndex = dataPoint.datasetIndex;
        const dataset = data.datasets[datasetIndex];
        const issuesData = dataset.issues
          ? dataset.issues[dataPoint.dataIndex]
          : {};
        const riskScoreValueColor =
          issuesData?.total_high_issues > 0
            ? colors["danger"]
            : issuesData?.total_med_issues > 0
            ? colors["warning"]
            : colors["success"];

        tooltipEl.innerHTML = `
          <div style="background: white; padding: 12px; border-radius: 5px;  width: max-content; position: relative;">
            <div style="font-size: 15px; font-weight: bold; margin-bottom: 5px;">
              <span style="color: ${
                colors["gray-title2"]
              };font-weight: 400;">Date:</span> 
              <span style="color: ${
                colors["gray-title2"]
              }; font-weight: 700;">${dateLabel}</span>
            </div>
            <div style="font-size: 15px; font-weight: bold; margin-bottom: 5px;">
              <span style="color: ${colors["gray-title2"]};font-weight: 400;">${
          showRiskLevels ? "Total Issues:" : "Risk Score:"
        }</span> 
              <span style="color: ${
                showRiskLevels ? colors["gray-title2"] : riskScoreValueColor
              }; font-weight: 700;">${
          showRiskLevels
            ? (Number(issuesData?.total_high_issues) || 0) +
              (Number(issuesData?.total_med_issues) || 0) +
              (Number(issuesData?.total_low_issues) || 0)
            : value
        }</span>
            </div>
            ${
              showRiskLevels
                ? `<div style="display: flex; align-items: center; gap: 5px;">
                    <div style="color: ${
                      colors["danger"]
                    }; font-size: 15px;">High: <span style="font-weight: 600;">${
                    Number(issuesData?.total_high_issues) || 0
                  }</span></div>
                <div style="color: ${
                  colors["warning2"]
                }; font-size: 15px;">Medium: <span style="font-weight: 600;">${
                    Number(issuesData?.total_med_issues) || 0
                  }</span></div>
                <div style="color: ${
                  colors["success"]
                }; font-size: 15px;">Low: <span style="font-weight: 600;">${
                    Number(issuesData?.total_low_issues) || 0
                  }</span></div>
                  </div>`
                : ""
            }
            <div style="position: absolute; bottom: -10px; left: ${
              showRiskLevels ? "13" : dataPoint.dataIndex == 0 ? "24" : "50"
            }%; transform: translateX(-50%); width: 0; height: 0; border-left: 10px solid transparent; border-right: 10px solid transparent; border-top: 10px solid white;"></div>
          </div>
        `;
      }

      const { offsetLeft: left, offsetTop: top } = context.chart.canvas;
      const caretX = tooltipModel.caretX;
      const caretY = tooltipModel.caretY;
      const dataPoint = tooltipModel.dataPoints[0];
      tooltipEl.style.opacity = 1;
      tooltipEl.style.position = "absolute";
      tooltipEl.style.left = showRiskLevels
        ? `${left + 70 + caretX}px`
        : dataPoint.dataIndex == 0
        ? `${left + 2 * caretX}px`
        : `${left + caretX}px`;
      tooltipEl.style.top = `${top + caretY}px`;
      tooltipEl.style.transform = "translate(-48%, -140%)";
      tooltipEl.style.pointerEvents = "none";
    };
  };

  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: createExternalTooltip(id),
      },
    },
    scales: {
      x: {
        ticks: {
          color: "white",
          font: { size: 10, weight: "normal" },
          maxRotation: 0,
          minRotation: 0,
          padding: 8,
          maxTicksLimit: 5,
        },
        grid: { display: false },
      },
      y: {
        ticks: { display: false },
        grid: { display: false },
        beginAtZero: true,
      },
    },
    layout: {
      padding: { top: 20, bottom: 10, left: 10, right: 10 },
    },
    elements: {
      line: {
        borderJoinStyle: "miter",
        tension: 0.4,
      },
      point: {
        radius: 5,
        hitRadius: 0,
        hoverRadius: 7,
        borderWidth: 1,
        hoverBorderWidth: 3,
      },
    },
    devicePixelRatio: window.devicePixelRatio,
    onHover: (event, chartElement) => {
      if (chartElement.length) {
        event.native.target.style.cursor = "pointer";
      } else {
        event.native.target.style.cursor = "default";
      }
    },
  };
  return (
    <div>
      <Line
        ref={chartRef}
        className="line-chart-offensive"
        style={{ width: "480px", height: "190px" }}
        data={data}
        options={options || defaultOptions}
      />
      <div id={id}></div>
    </div>
  );
};

export default LineChart;
