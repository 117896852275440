export const initialColumns = [
  { header: "Domain", key: "domain", visibility: true, default: true },
  { header: "Hostname", key: "hostname", visibility: true, default: true },
  { header: "IP Address", key: "ip", visibility: true, default: true },
  { header: "Status", key: "status", visibility: true },
  { header: "Hosting Provider", key: "hosting_provider", visibility: true },
  { header: "CDN", key: "cdn_name", visibility: true },
  { header: "Network Zone", key: "network_zone", visibility: true },
  { header: "Service Category", key: "service", visibility: true },
  { header: "Web Interface", key: "web_interface", visibility: true },
  { header: "Asset Criticality", key: "criticality", visibility: true },
  { header: "Security Issues", key: "security_issues", visibility: true },
  { header: "Discovered By", key: "discovered_by", visibility: true },
  { header: "First Detected", key: "first_detected", visibility: true },
  { header: "Last Updated", key: "updated_at", visibility: true },
  { header: "Current State", key: "change_status", visibility: true },
];

export const discoveredByFilterOptions = [
  {
    id: 0,
    name: "Armory",
    type: "Discovered By: Armory",
    key: "8",
    active: false,
  },
  {
    id: 1,
    name: "User’s input",
    type: "Discovered By: User’s input",
    key: "8",
    active: false,
  },
];

export const networkZoneFilterOptions = [
  {
    id: 0,
    name: "On-Premise",
    type: "Network Zone: On-Premise",
    key: "11",
    active: false,
  },
  {
    id: 1,
    name: "Cloud-Hosted",
    type: "Network Zone: Cloud-Hosted",
    key: "11",
    active: false,
  },
];

export const criticalityFilterOptions = [
  {
    id: 0,
    name: "Low",
    type: "Asset Criticality: Low",
    key: "13",
    active: false,
  },
  {
    id: 1,
    name: "Medium",
    type: "Asset Criticality: Medium",
    key: "13",
    active: false,
  },
  {
    id: 2,
    name: "High",
    type: "Asset Criticality: High",
    key: "13",
    active: false,
  },
];

export const firstDetectedFilterOptions = [
  {
    id: 0,
    name: "Last 24 Hours",
    type: "First Detected: Last 24 Hours",
    key: "14",
    active: false,
  },
  {
    id: 1,
    name: "Last 7 Days",
    type: "First Detected: Last 7 Days",
    key: "14",
    active: false,
  },
  {
    id: 2,
    name: "Last 30 Days",
    type: "First Detected: Last 30 Days",
    key: "14",
    active: false,
  },
];

export const criticalityMap = {
  High: 3,
  Medium: 2,
  Low: 1,
};

export const webInterfaceFilterOptions = [
  {
    id: 0,
    name: "Yes",
    type: "Web Interface: Yes",
    key: "15",
    active: false,
  },
  {
    id: 1,
    name: "No",
    type: "Web Interface: No",
    key: "15",
    active: false,
  },
];