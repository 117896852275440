import React from "react";
import { ReactComponent as RightArrow } from "../../assets/images/right-fill.svg";
import GenericCardImg from "../../assets/images/generic-bg.png";

const GenericCard = ({ title, children, className }) => {
  return (
    <div
      className={`generic-container ${className}`}
      style={{
        backgroundImage: `url(${GenericCardImg})`,
      }}
    >
      <div
        className="risk-score-trend-container"
        style={{
          position: "relative",
        }}
      >
        <div className="card-main">
          <div className="card-left"></div>
          <div className="card-middle"></div>
          <div className="card-right"></div>
          <div className="progress-bar-dashboard"></div>
        </div>

        <div className="title">
          <RightArrow className="me-3" />
          <div className="generic-title">{title}</div>
        </div>

        <div className="line-chart-container">{children}</div>
      </div>
    </div>
  );
};

export default GenericCard;
