import { services } from "../util/scans";
import axios from "../util/axios";
import React, { createContext, useContext, useRef, useState } from "react";

export const ServicesContext = createContext();

export function UseServices() {
  return useContext(ServicesContext);
}

export const ServicesContextProvider = ({ children }) => {
  const [allServices, setAllServices] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [manualServices, setManualServices] = useState([]);
  const [isScannerStop, setIsScannerStop] = useState(false);
  const timeoutRef = useRef();

  const fetchAllServicesItems = async (id) => {
    if (!id) return;
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    try {
      const { data } = await axios.get(`scans/${id}/services`);
      setManualServices(data?.manualServices || []);
      setIsScannerStop(data?.scanStatus === -1);
      const updatedServices = services
        .map((service) => {
          if (service.id === 25) {
            const foundService2 = data?.services?.find(
              (item) => item.service_id === 2
            );
            const foundService18 = data?.services?.find(
              (item) => item.service_id === 18
            );
            if (foundService2 && foundService18) {
              const hasIssues2 = foundService2?.has_issues ?? 0;
              const hasIssues18 = foundService18?.has_issues ?? 0;
              const combinedHasIssues = Math.max(hasIssues2, hasIssues18);
              const status2 = foundService2?.service_status;
              const status18 = foundService18?.service_status;
              let combinedStatus;

              if ([0, 1, 2].includes(status2) || [0, 1, 2].includes(status18)) {
                combinedStatus = Math.min(status2, status18);
              } else if (
                ![0, 1, 2].includes(status2) &&
                ![0, 1, 2].includes(status18)
              ) {
                combinedStatus = status18;
              } else {
                const validStatus2 = typeof status2 === "number" ? status2 : 0;
                const validStatus18 =
                  typeof status18 === "number" ? status18 : 0;
                combinedStatus = Math.min(validStatus2, validStatus18);
              }

              const menuEnabled18 =
                typeof foundService18?.menu_enabled === "undefined"
                  ? service.menu_enabled
                  : foundService18.menu_enabled;
              const combinedMenuEnabled = menuEnabled18 === 1 ? 1 : 0;
              return {
                ...service,
                status: combinedStatus,
                updated_at:
                  foundService2?.updated_at || foundService18?.updated_at,
                created_at:
                  foundService2?.created_at || foundService18?.created_at,
                has_issues: combinedHasIssues,
                menu_enabled: combinedMenuEnabled,
              };
            } else {
              return {
                ...service,
                menu_enabled: 0,
                has_issues: null,
              };
            }
          } else {
            const foundService = data?.services?.find(
              (item) => item.service_id === service.id
            );
            if (foundService) {
              let menuEnabled =
                typeof foundService["menu_enabled"] === "undefined"
                  ? service.menu_enabled
                  : foundService.menu_enabled;
              return {
                ...service,
                status: foundService.service_status ?? service.status,
                updated_at: foundService.updated_at,
                created_at: foundService.created_at,
                has_issues: foundService.has_issues,
                menu_enabled: menuEnabled,
              };
            } else {
              return {
                ...service,
                menu_enabled: 0,
                has_issues: null,
              };
            }
          }
        })
        .filter(function (item) {
          return (item?.status === 3 && item.id === 15) || item.id !== 15;
        });

      const allServicesUpdated = updatedServices.every(
        (service) =>
          service.status !== 0 && service.status !== 1 && service.status !== 2
      );
      setIsDone(allServicesUpdated);
      setAllServices(
        data.isFirstRun || allServicesUpdated
          ? updatedServices
          : updatedServices.map((service) => {
              service.status = 1;
              return service;
            })
      );
      if (!allServicesUpdated || data?.services?.length === 0) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => fetchAllServicesItems(id), 5000);
      }
    } catch (err) {
      if (err?.response?.data?.status === false) {
        setAllServices([
          ...services?.map((el) => ({
            ...el,
            menu_enabled: 0,
          })),
        ]);
        timeoutRef.current = setTimeout(() => fetchAllServicesItems(id), 5000);
      }
    }
  };

  return (
    <ServicesContext.Provider
      value={{
        allServices,
        fetchAllServicesItems,
        isDone,
        manualServices,
        isScannerStop,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};
