import React, { useState, useEffect, useRef, useContext } from "react";
import DashedImage from "../../assets/images/Frame.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Collapse, Accordion } from "react-bootstrap";
import { ReactComponent as ArrowDown } from "../../assets/images/chevron-green-down.svg";
import { ReactComponent as Loader } from "../../assets/images/loader.svg";
import { ReactComponent as IconWarning } from "../../assets/images/icon-warning.svg";
import { categories, services,warRoomServices } from "../../util/scans";
import { ScannerContext } from "../../components/ScannerContext";
import { UseServices } from "../../hooks/useServices";
import { ReactComponent as ArrowBlueDown } from "../../assets/images/chevron-blue-down.svg";
import { ReactComponent as ArmoryWarZoneIcon } from "../../assets/images/war-room.svg";
import { colors } from "../../util/colors.js";




const LeftNavbar = ({ open, selectedItem, isWarRoom = false }) => {
  const { allServices, fetchAllServicesItems,manualServices } = UseServices();
  const { dropdownItems } = useContext(ScannerContext);
  // const [allServices, setAllServices] = useState(services);
  const sortedCategories = categories.sort(
    (a, b) => a.categ_order - b.categ_order
  );

  const [openSections, setOpenSections] = useState(
    sortedCategories.map((_, index) => `${index}`)
  );
  const location = useLocation();
  const navigate = useNavigate();
  const routeParams = useParams();

  useEffect(() => {
    fetchAllServicesItems(selectedItem?.target_uuid);
  }, [selectedItem?.target_uuid]);

  const toggleSection = (eventKey) => {
    setOpenSections((prev) => {
      if (prev.includes(eventKey)) {
        return prev.filter((item) => item !== eventKey);
      } else {
        return [...prev, eventKey];
      }
    });
  };

  return (
    <React.Fragment>
      <Collapse in={open} dimension="width">
        <Col
          lg="auto"
          md="12"
          className="p-0 sidebar-col border-end sub-sidebar"
        >
          <Accordion
            activeKey={openSections}
            onSelect={toggleSection}
            className="custom-accordion-sub"
          >
            {isWarRoom ? (<>
              <Accordion.Item eventKey="1" className="custom-accordion-item">
                <Accordion.Header className="custom-accordion-header">
                  <ArrowBlueDown height={25} width={25} className="accordion-arrow pe-2" />
                  <div className="d-flex"><ArmoryWarZoneIcon className="me-1"/> WAR ROOM</div>
                </Accordion.Header>
                <Accordion.Body className="accordion-body-main">
                  {warRoomServices.map((service, serviceIndex) => {
                 const matchedService = manualServices.find(
                   (s) => s.service_id === service.id
                 );
                 const showWarning = !!matchedService; 
                   
                    
                    return (
                      <Accordion.Body
                        key={`warroom-${serviceIndex}`}
                        className={`custom-accordion-body sub-body`}
                        style={{
                          backgroundImage: `url(${DashedImage})`,
                          cursor: "not-allowed",
                          backgroundColor: "#5D5D5E"
                        }}
                      >
                        <span
                          style={{
                            fontSize: 14,
                            color: "inherit",
                          }}
                        >
                          {service.name}
                        </span>
                        {showWarning ? (
                          <IconWarning
                            className="warning-icon cursor-pointer"
                            width="24"
                            style={{
                              right:'5px'
                            }}
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              navigate(
                                `/security-issues/${routeParams?.target_id || routeParams?.id}?warRoomScanner=${service?.id}`
                              );
                            }}
                          />
                        ) : null}
                      </Accordion.Body>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            </>
          ) : <>
              {sortedCategories.map((category, categoryIndex) => (
                <Accordion.Item
                  eventKey={`${categoryIndex}`}
                  className="custom-accordion-item"
                  key={categoryIndex}
                >
                  <Accordion.Header className="custom-accordion-header custom-child-header">
                    <ArrowDown
                      height={25}
                      width={25}
                      className="accordion-arrow svg-white pe-2"
                    />
                    {category.categ_name}
                  </Accordion.Header>
                  {allServices
                    .filter(
                      (service) =>
                        service.category_id === category.id &&
                        service.show_in_menu
                    )
                    .map((service, serviceIndex) => {
                      let active =
                        service.key?.replace(
                          ":target_id",
                          selectedItem?.target_uuid
                        ) === location.pathname;
                      return (
                        <Accordion.Body
                          key={`${categoryIndex}-${serviceIndex}`}
                          className={`custom-accordion-body sub-body ${active ? "sub-body-active" : ""
                            } ${!service.menu_enabled || dropdownItems?.length == 0
                              ? "menu-disabled"
                              : ""
                            }`}
                          style={{
                            backgroundImage: `url(${DashedImage})`,
                            cursor:
                              dropdownItems?.length > 0
                                ? service.menu_enabled
                                  ? "pointer"
                                  : "no-drop"
                                : "no-drop",
                          }}
                          as={
                            dropdownItems?.length > 0
                              ? service.menu_enabled
                                ? Link
                                : "div"
                              : "div"
                          }
                          to={
                            service.key?.replace(
                              ":target_id",
                              selectedItem?.target_uuid
                            ) || "#"
                          }
                        >
                          <span
                            style={{
                              fontSize: 14,
                              color: active ? colors["white"] : "inherit",
                            }}
                          >
                            {service.title}
                          </span>
                          {!!service.is_new && (
                            <span className="new-tag">new</span>
                          )}
                          {service.has_issues === 1 && service.menu_enabled ? (
                            <IconWarning
                              className="warning-icon"
                              width="24"
                              style={{
                                right:
                                  service.status !== -1 &&
                                    service.status !== 3 &&
                                    service.status !== null &&
                                    service.menu_enabled
                                    ? "33px"
                                    : "5px",
                              }}
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                navigate(
                                  `/security-issues/${routeParams?.target_id || routeParams?.id
                                  }?scanner=${service?.id}`
                                );
                              }}
                            />
                          ) : null}{" "}
                          {service.status !== -1 &&
                            service.status !== 4 &&
                            service.status !== 3 &&
                            service.status !== null &&
                            !!service.menu_enabled && (
                              <Loader
                                className="Loader-icon"
                                height={20}
                                width={20}
                              />
                            )}
                        </Accordion.Body>
                      );
                    })}
                </Accordion.Item>
              ))}
            </>
            }
          </Accordion>
        </Col>
      </Collapse>
    </React.Fragment>
  );
};

export default LeftNavbar;
