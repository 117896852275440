import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { colors } from "../../util/colors.js";

const AttackSurfaceComponent = ({ data }) => {
  const { target_id } = useParams();
  const navigate = useNavigate();

  const handleRedirectSecurityIssues = (host) => {
    navigate(`/security-issues/${target_id}?host=${host}`);
  };

  return (
    <div className="attack-main">
      {data.map((item) => (
        <div key={item?.host} className="attack-container">
          <div
            className="attack-card"
            style={{
              background: `linear-gradient(0deg, rgba(0, 13, 25, 0.35), rgba(0, 13, 25, 0.35)),
              radial-gradient(485.61% 165.79% at 50% 100%, ${
                item?.risk_score_color == "High"
                  ? colors["danger"]
                  : item?.risk_score_color == "Medium"
                  ? colors["warning2"]
                  : colors["success"]
              } 0%, rgba(1, 5, 6, 0.2) 100%)`,
              borderColor: "white",
            }}
          ></div>
          <span onClick={() => handleRedirectSecurityIssues(item.host)}>
            {item.host}
          </span>
        </div>
      ))}
    </div>
  );
};

export default AttackSurfaceComponent;
