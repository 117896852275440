import { logout } from "./authUtils";

export const getUserRole = (navigate) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user || user.member_level === undefined) {
    return null;
  }

  switch (user.member_level) {
    case 0:
      return "viewer";
    case 2:
      return "editor";
    case 9:
      return "admin";
    case 1:
      return "distributor";
    default:
      logout(navigate);
      return null;
  }
};
