import React, { useState, useEffect, useRef } from "react";
import NestedDropdown from "./NestedDropdown";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { ReactComponent as ArrowDown } from "../../assets/images/arrow-select.svg";
import CustomTooltip from "../../components/tooltip/WhiteTooltip";
import { colors } from "../../util/colors";

const DropdownWrapper = ({ options, setSelectedOptions, selectedOptions }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectOptions = (values) => {
    setSelectedOptions(values);
  };

  const getDisplayText = () => {
    if (selectedOptions.length === 0) {
      return "Select Scanners";
    }

    const selectedText = [];

    const addSelectedText = (options) => {
      options.forEach((option) => {
        if (selectedOptions.includes(option.id)) {
          selectedText.push(option.title);
        }
        if (option.children && option.children.length > 0) {
          addSelectedText(option.children);
        }
      });
    };

    addSelectedText(options);

    return selectedText.length > 0 ? selectedText.join(", ") : "Select Scanners";
  };

  const renderButton = () => (
    <button
      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      type="button"
      style={{
        marginTop: "10px",
        width: "100%",
        padding: "10px",
        background: colors["bg-black"],
        color: colors["text-gray"],
        border: `1px solid ${
          isDropdownOpen ? colors["border-blue"] : colors["white"]
        }`,
        borderRadius: "8px",
        textAlign: "left",
        cursor: "pointer",
        display: "flex",
        marginLeft: "0px",
        alignItems: "center",
        justifyContent: "space-between",
        transition: "border-color 0.3s ease-in-out",
        position: "relative",
        zIndex: 9999,
      }}
    >
      <span
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {getDisplayText()}
      </span>
      <span>
        <ArrowDown
          style={{
            fill: colors["text-gray"],
            transform: isDropdownOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease-in-out",
          }}
        />
      </span>
    </button>
  );

  const renderTooltip = (props) => {
    const getTitlesByIds = (options, ids) => {
      let titles = [];

      options.forEach((option) => {
        if (ids.includes(option.id)) {
          titles.push(option.title);
        }
        if (option.children && option.children.length > 0) {
          titles = titles.concat(getTitlesByIds(option.children, ids));
        }
      });

      return titles;
    };

    const selectedTitles = getTitlesByIds(options, selectedOptions);

    return (
      <CustomTooltip id="tooltip-demo-scan" {...props}>
        {selectedTitles.length > 0
          ? selectedTitles.map((title, index) => (
              <span key={index} style={{ display: "block" }}>
                {title}
              </span>
            ))
          : "Select Scanners"}
      </CustomTooltip>
    );
  };

  return (
    <div ref={dropdownRef} style={{ position: "relative" }}>
      {selectedOptions.length > 0 ? (
        <OverlayTrigger
          placement="top"
          overlay={renderTooltip}
          delay={{ show: 250, hide: 400 }}
        >
          {renderButton()}
        </OverlayTrigger>
      ) : (
        renderButton()
      )}

      {isDropdownOpen && (
        <div
          style={{
            position: "absolute",
            top: "30%",
            left: "0",
            width: "100%",
            padding: "10px 0px",
            borderRadius: "8px",
            marginTop: "5px",
            zIndex: 1000,
          }}
        >
          <NestedDropdown
            onSelectOptions={handleSelectOptions}
            allOptions={options}
            setSelectedOptions={setSelectedOptions}
            selectedOptions={selectedOptions}
          />
        </div>
      )}
    </div>
  );
};

export default DropdownWrapper;
