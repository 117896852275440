import React, { useState, useEffect } from "react";
import { Tab } from "react-bootstrap";
import BaseInput from "../form/BaseInput";
import BaseCheckbox from "../form/BaseCheckbox";
import { ReactComponent as Search } from "../../assets/images/search.svg";

const TabPaneContent = ({ eventKey, activeFilters, data, onDomainChange }) => {
  const [searchValue, setSearchValue] = useState("");
  const [displayData, setDisplayData] = useState([]);
  const [checkedItems, setCheckedItems] = useState(new Map());

  useEffect(() => {
    let newCheckedItems = new Map(checkedItems);
    data?.forEach((el) => {
      const hasActiveFilter = activeFilters?.some(
        (ol) => ol.eventKey === el.key && ol.name === el.name
      );
      newCheckedItems.set(el.name, hasActiveFilter);
    });
    setCheckedItems(newCheckedItems);
    const filteredData =
      searchValue.trim() === ""
        ? data
        : data.filter((item) =>
            item.name.toLowerCase().includes(searchValue.toLowerCase())
          );
    setDisplayData(filteredData);
  }, [searchValue, activeFilters]);

  const handleCheckboxChange = (item) => {
    const currentState = checkedItems.get(item.name);
    const newState = !currentState;
    setCheckedItems(new Map(checkedItems.set(item.name, newState)));
    onDomainChange(item, newState);
  };

  const clearAllCheckboxes = () => {
    let newCheckedItems = new Map();
    displayData.forEach((item) => {
      newCheckedItems.set(item.name, false);
      onDomainChange(item, false, "clear");
    });
    setCheckedItems(newCheckedItems);
  };

  const selectAllCheckbox = () => {
    onDomainChange(false, false, "selectAll", displayData);
  };
  return (
    <Tab.Pane eventKey={eventKey}>
      <div className="regular-tab-pane">
        <div className="search-container-input">
          <BaseInput
            type="text"
            placeholder="Search..."
            icon={Search}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between mb-2 tab-pane-selector">
          <span className="tab-pane-title">
            <BaseCheckbox
              type="checkbox"
              label={`Select All Filters (${displayData?.length})`}
              checked={
                displayData.length > 0 &&
                displayData.every((item) => checkedItems.get(item.name))
              }
              onChange={selectAllCheckbox}
              className="base-checkbox"
            />
          </span>
          <span
            className="cursor-pointer tab-pane-title title-clear"
            onClick={activeFilters.length > 0 ? clearAllCheckboxes : undefined}
            style={{
              textDecoration: "underline",
              opacity: activeFilters.length > 0 ? 1 : 0.5,
              pointerEvents: activeFilters.length > 0 ? "auto" : "none",
            }}
          >
            Clear All
          </span>
        </div>
        <div className="mt-2 ">
          {displayData.map((item) => (
            <div key={item.id} className="d-flex mb-3">
              <BaseCheckbox
                type="checkbox"
                name={`checkbox-${item.name}`}
                label={item.name}
                checked={checkedItems.get(item.name) || false}
                onChange={() => handleCheckboxChange(item)}
                className="base-checkbox"
              />
            </div>
          ))}
        </div>
      </div>
    </Tab.Pane>
  );
};

export default TabPaneContent;
