export const colors = {
  "gray-title": "#D3D3D3",
  "gray-title2": "#333333",
  white: "#ffff",
  danger: "#FF6155",
  warning: "#FFB500",
  warning2: "#FFAE58",
  success: "#3DDC97",
  blue: "#169FFE",
  "bg-black": "#0A0A0E",
  "text-gray": "#A7A7B1",
  "border-blue": "#398FFE",
  "border-gray": "#333"
};

export const colorList = ["#FFFFFF", "#3CE2C1", "#87C2F3", "#A7A7B1"];
