import { colors } from "./colors.js";

export const getChartOptions = (chartConfig) => ({
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 0,
      bottom: 0,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    x: {
      display: false,
      barPercentage: 0.9,
      categoryPercentage: 0.2,
    },
    y: {
      display: false,
    },
  },
  onClick: (event, elements) =>
    chartConfig.handleBarClick(event, elements, chartConfig),
});
export const getChartData = (clickedBars) => ({
  labels: ["First", "Second", "Third"],
  datasets: [
    {
      label: "Sample Data",
      data: [70, 100, 130],
      backgroundColor: clickedBars.map((clicked, index) => {
        return clicked ? "#3b82f6" : index === 0 ? "#60a5fa" : "#cbd5e1";
      }),
      hoverBackgroundColor: ["#2563eb", "#2563eb", "#2563eb"],
      borderWidth: 1,
      borderRadius: 10,
      borderSkipped: false,
      maxBarThickness: 12,
    },
  ],
});

export const barChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: 750,
    easing: "easeOutQuart",
  },
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        color: "white",
        fillStyle: "none",
        padding: 30,
        usePointStyle: true,
        pointStyle: "rectRounded",
        boxSize: 0,
        boxWidth: 0,
        font: {
          family: "Kodchasan",
        },
      },
    },
    tooltip: {
      backgroundColor: "white",
      titleColor: "black",
      bodyColor: "black",
      padding: 20,
      yPadding: 90,
      callbacks: {
        title: function () {
          return "";
        },
        label: function (context) {
          const labelIndex = context.dataIndex;
          const labels = context.dataset.label;
          const value = context.raw;
          return `${labels[labelIndex]}: ${value}`;
        },
      },
      yAlign: "bottom",
      xAlign: "center",
      titleFont: {
        family: "Kodchasan",
      },
      bodyFont: {
        family: "Kodchasan",
      },
    },
    datalabels: {
      color: "white",
      anchor: "end",
      align: "top",
      formatter: (value, context) => {
        return value;
      },
      font: {
        family: "Kodchasan",
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        color: "white",
        precision: 0,
        font: {
          family: "Kodchasan",
        },
      },
      border: { dash: [4, 4] },

      grid: {
        color: "rgba(255, 255, 255, 0.1)",
        borderDash: [10, 10],
      },
    },
    x: {
      ticks: {
        color: "white",
        font: {
          family: "Kodchasan",
        },
      },
      display: true,
      grid: {
        color: "transparent",
        drawOnChartArea: true,
        borderDash: [10, 10],
        borderDashOffset: function (context) {
          return context.index === context.chart.data.labels.length ? 0 : 10;
        },
      },
      border: {
        color: "#959595",
        width: 1,
      },
    },
  },
  layout: {
    padding: {
      top: 30,
      bottom: -40,
    },
  },
  onHover: (event, elements) => {
    event.native.target.style.cursor = elements.length ? "pointer" : "default";
  },
};

export const lineChartOptions = (showRiskLevels) => ({
  borderColor: "white",
  backgroundColor: "transparent",
  borderWidth: 2,
  tension: 0,
  pointBackgroundColor: "white",
  pointBorderColor: "white",
  pointRadius: (context) => {
    return context.raw === 0 ? 0 : 6;
  },
  pointHoverRadius: (context) => {
    return context.raw === 0 ? 0 : 8;
  },
  pointHoverBorderWidth: 2,
  pointHoverBorderColor: "white",
  pointHoverBackgroundColor: (context) => {
    const index = context.dataIndex;
    const dataset = context.dataset;
    const issuesData = dataset.issues ? dataset.issues[index] : {};

    return showRiskLevels
      ? colors["blue"]
      : issuesData?.total_high_issues > 0
      ? colors["danger"]
      : issuesData?.total_med_issues > 0
      ? colors["warning"]
      : colors["success"];
  },
});
