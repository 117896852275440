import React, { useState, useEffect, useRef, useContext } from "react";
import WebScanner from "../../../../components/web-scanner/WebScanner";
import WebUpdate from "../../../../components/website-updates/WebUpdate";
import BaseTable from "../../../../components/table/BaseTable";
import BaseFilter from "../../../../components/filter/BaseFilter";
import BaseInput from "../../../../components/form/BaseInput";
import ComparisonChart from "../../../../components/charts/ComparisonChart";
import { ReactComponent as Search } from "../../../../assets/images/search.svg";
import axios from "../../../../util/axios";
import { useParams, useOutletContext, useNavigate } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";
import GenericCard from "../../../../components/card/GenericCard";
import DecisionToggle from "../../../../components/badge/DecisionToggle";
import DoughnutChart from "../../../../components/charts/DoughnutChart";
import NoData from "../../../../components/empty/NoData";
import BarChart from "../../../../components/charts/BarChart";
import { NoDataAfterScanning } from "../../../../components/empty/NoDataAfterScanning";
import { Badge } from "react-bootstrap";
import { ScannerContext } from "../../../../components/ScannerContext";
import { formatScannerName } from "../../../../helpers/formatScannerName.js";
import moment from "moment";
import CurrentStatus from "../../../../components/status/CurrentStatus.js";
import {
  webUpdatesFilter,
  webDataFillter,
} from "../../../../util/genericFunctions.js";
import TestedPopover from "../../../../components/shared/TestedPopover.js";
import { ReactComponent as RedCross } from "../../../../assets/images/Red-Cross.svg";
import { ReactComponent as GrayCross } from "../../../../assets/images/Grey-Cross.svg";
import {
  passAuthFilter,
  sshAuthFilter,
  dictionaryAttackFilter,
} from "./filterOptions.js";
import { parseFilterString, applyCondition } from "../../../../util/conditions";
import { sshTabsConfig } from "../../../../util/tabsConfig.js";
import { formatDate } from "../../../../util/formatDate.js";
import { UseAssetsModal } from "../../../../hooks/useAssetsModal.js";
import { barChartOptions } from "../../../../util/chartUtils.js";

const DefaultCredential = () => {
  const { openModal } = UseAssetsModal();
  const { dropdownItems } = useContext(ScannerContext);
  const { scanningStatus } = useOutletContext();
  const routeParams = useParams();
  const [isDataisPartiallyProcessing, setIsDataisPartiallyProcessing] =
    useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [noDataDoughtnutChart, setNoDataDoughtnutChart] = useState(false);
  const [noDataBarChart, setNoDataBarChart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const [webUpdatesData, setWebUpdatesData] = useState(null);
  const [SSHAuthenticationMethodFilters, setSSHAuthenticationMethodFilters] =
    useState(sshAuthFilter);
  const [chartData, setChartData] = useState({
    labels: ["Secured", "Unsecured"],
    datasets: [
      {
        data: [],
        backgroundColor: ["#3DDC97", "#FF6155"],
        hoverOffset: 4,
        borderColor: "transparent",
        borderWidth: 0,
        borderAlign: "center",
      },
    ],
  });
  const [isDataInProcess, setIsDataInProcess] = useState(false);
  const [data, setData] = useState([]);
  const [barChartType, setBarChartType] = useState([]);
  const navigate = useNavigate();

  const [tabs, setTabs] = useState([]);
  const [barChartData, setBarChartData] = useState({
    labels: ["Vulnerable", "Not Vulnerable"],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: ["#FF6155", "#3DDC97"],
        borderColor: ["rgb(255, 99, 132)", "rgb(75, 192, 192)"],
        borderWidth: 1,
      },
    ],
  });

  const updateTabsWithData = (data) => {
    const ips = Array.from(new Set(data.map((ssh) => ssh.ip))).map((ip) => ({
      id: ip,
      name: ip,
      type: "IP Address: " + ip,
      key: "1",
      active: false,
    }));
    let authNames = [];
    data?.map((el) => {
      el?.auth_methods?.map((ol) => {
        authNames.push(ol);
      });
    });
    const authName = Array.from(new Set(authNames)).map((el, index) => ({
      id: index,
      name: el.toString(),
      type: "Auth. Method: " + el.toString(),
      key: "7",
      active: false,
    }));

    const portNumber = Array.from(new Set(data.map((ssh) => ssh.port_no)))
      ?.filter((name) => name)
      .map((port) => ({
        id: port,
        name: port,
        type: "Port: " + port,
        key: "2",
        active: false,
      }));
    const domainNames = Array.from(new Set(data.map((ssh) => ssh.domain)))
      ?.filter((name) => name)
      .map((port) => ({
        id: port,
        name: port,
        type: "Domain: " + port,
        key: "8",
        active: false,
      }));
    setTabs(
      sshTabsConfig({
        ips,
        portNumber,
        authName,
        passAuthFilter,
        dictionaryAttackFilter,
        domainNames,
      })
    );
  };

  const columns = [
    {
      Header: "IP Address",
      accessor: (row) => {
        return (
          <span onClick={() => openModal(row?.ip)} className="asset-link">
            {row?.ip}
          </span>
        );
      },
      isSortable: true,
      key: "ip",
    },
    {
      Header: "Port",
      accessor: "port_no",
      isSortable: true,
      key: "port_no",
    },
    {
      Header: "Authentication Methods",
      accessor: (row) => (
        <div className="d-flex flex-wrap">
          {row?.auth_methods?.length > 0
            ? row.auth_methods?.map((field, index) => (
                <div key={index} className="me-1 my-1">
                  <Badge className="secondary-badges rounded">{field}</Badge>
                </div>
              ))
            : "-"}
        </div>
      ),
      isSortable: false,
    },
    {
      Header: "Password Authentication",
      key: "pass_auth_method",
      isSortable: true,
      accessor: (row) => (
        <div
          className={` ${
            row?.pass_auth_method === 1 ? "cursor-pointer" : "no-pointer"
          }`}
          onClick={() => {
            handelClickOnIssuePassword(row);
          }}
        >
          <DecisionToggle
            label={row?.pass_auth_method === 1 ? "Yes" : "No"}
            isIndication={row?.pass_auth_method === 1 ? false : true}
          />
        </div>
      ),
    },
    {
      Header: <TestedPopover title="Dictionary Attack" />,
      key: "username",
      isSortable: true,
      width: "25",
      accessor: (row) => {
        return (
          <div>
            {row?.creds?.length > 0 ? (
              <div className="d-flex gap-2 custom-ssh-badge">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    handelClickOnIssueDictionary(row);
                  }}
                >
                  <DecisionToggle
                    label={
                      <div className="d-flex align-items-center">
                        <RedCross className="me-1" />
                        <div>Username: {row.username}</div>
                      </div>
                    }
                    isIndication={false}
                  />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    handelClickOnIssueDictionary(row);
                  }}
                >
                  <DecisionToggle
                    label={
                      <div className="d-flex align-items-center">
                        <RedCross className="me-1" />
                        <div>Password: {row.password}</div>
                      </div>
                    }
                    isIndication={false}
                  />
                </div>
              </div>
            ) : (
              <div className="no-pointer">
                <DecisionToggle
                  label={
                    <div className="d-flex align-items-center">
                      <GrayCross className="me-1" />
                      <div>Not Vulnerable</div>
                    </div>
                  }
                  isIndication={true}
                />
              </div>
            )}
          </div>
        );
      },
    },
    {
      Header: "First Detected",
      key: "first_detected",
      isSortable: true,
      accessor: (row) => {
        return (
          <div>
            {row?.first_detected ? formatDate(row?.first_detected) : ""}
          </div>
        );
      },
    },
    {
      Header: "Last Updated",
      key: "updated_at",
      isSortable: true,
      accessor: (row) => {
        return (
          <div>
            {row?.updated_at ? formatDate(row?.updated_at) : ""}
          </div>
        );
      },
    },
    {
      Header: "Current State",
      key: "current_state",
      isSortable: true,
      accessor: (row) => {
        return (
          <div>
            {row?.change_status != null ? (
              <CurrentStatus
                status={row?.change_status}
                tooltipInfo={row?.changes}
                headerKeys={{
                  ip: "IP Address",
                  port: "Port",
                  auth_methods: "Authentication Methods",
                  pass_auth_method: "Password Authentication",
                  creds: "Default Credentials",
                  first_detected: "First Detected",
                  updated_at: "Last Updated",
                  change_status: "Current State",
                }}
              />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  const CircularChartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "80%",
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Secured vs Unsecured Email Configuration",
        color: "white",
        font: {
          family: "Kodchasan",
          size: 24,
        },
      },
    },
    onHover: (event, elements) => {
      event.native.target.style.cursor = elements.length
        ? "pointer"
        : "default";
    },
  };

  const handleWebUpdateSort = (name) => {
    setActiveFilters(webUpdatesFilter(name, activeFilters));
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const fetchSSHData = async () => {
    try {
      const { data } = await axios.get(`scans/${routeParams?.target_id}/ssh`);
      if (data?.groups) {
        setWebUpdatesData(data?.groups?.changes);
        setChartData({
          labels: ["Secured", "Unsecured"],
          datasets: [
            {
              data: [
                data?.groups?.auth?.true || 0,
                data?.groups?.auth?.false || 0,
              ],
              backgroundColor: ["#3DDC97", "#FF6155"],
              hoverOffset: 4,
              borderColor: "transparent",
              borderWidth: 0,
              borderAlign: "center",
            },
          ],
        });
        setBarChartData({
          labels: ["Vulnerable", "Not Vulnerable"],
          datasets: [
            {
              label: ["Vulnerable", "Not Vulnerable"],
              data: [
                data?.groups?.creds?.true || 0,
                data?.groups?.creds?.false || 0,
              ],
              backgroundColor: ["#FF6155", "#3DDC97"],
              borderColor: ["rgb(255, 99, 132)", "rgb(75, 192, 192)"],
              borderWidth: 1,
              maxBarThickness: 70,
              borderRadius: {
                topLeft: 5,
                topRight: 5,
              },
            },
          ],
        });
      }
      if (data?.ssh?.length > 0) {
        let tableData = data?.ssh?.map((item) => {
          return {
            id: item?.id,
            ip: item?.ip?.ip,
            domain: item?.domain,
            port_no: item?.scan_ports?.port,
            auth_methods: item?.auth_methods,
            pass_auth_method: item?.hasPassworAuth ? 1 : 0,
            creds: item?.creds,
            username: item?.creds?.length > 0 ? item?.creds[0]?.login : "",
            password: item?.creds?.length > 0 ? item?.creds[0]?.password : "",
            first_detected: item?.first_detected,
            change_status: item?.change_status,
            changes: item?.changes,
            updated_at: item?.updated_at,
          };
        });
        updateTabsWithData(tableData);
        setData(tableData);
      }
      if (
        scanningStatus === -1 ||
        scanningStatus === 3 ||
        scanningStatus === 4 ||
        scanningStatus === 1 ||
        scanningStatus === 2
      ) {
        if (data?.groups?.creds?.true || data?.groups?.creds?.false) {
          setNoDataBarChart(false);
        } else {
          setNoDataBarChart(true);
        }
        if (data?.groups?.auth?.true || data?.groups?.auth?.false) {
          setNoDataDoughtnutChart(false);
        } else {
          setNoDataDoughtnutChart(true);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const fetchIntervalRef = useRef();
  useEffect(() => {
    clearInterval(fetchIntervalRef.current);

    if (scanningStatus === 0) {
      setIsLoading(false);
      setIsDataInProcess(true);
    } else {
      setIsDataInProcess(false);
      if (
        scanningStatus === 3 ||
        scanningStatus === -1 ||
        scanningStatus === 4
      ) {
        setIsDataisPartiallyProcessing(false);
        setIsLoading(true);
        fetchSSHData();
      } else if (scanningStatus === 2 || scanningStatus === 1) {
        setIsDataisPartiallyProcessing(true);
        setIsLoading(true);
        fetchSSHData();
        fetchIntervalRef.current = setInterval(fetchSSHData, 5000);
      }
    }
    return () => {
      clearInterval(fetchIntervalRef.current);
    };
  }, [scanningStatus, routeParams?.target_id]);

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const statusMap = { new: 1, changed: 2, unchanged: 0 };

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const filteredSSH = data.filter((ssh) => {
    // Check if item matches all groups of filters (intersection)
    const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
      // Check if item matches at least one filter in the current group (union)
      return groupedFilters[eventKey].some((filter) => {
        const lowerCaseFilter =
          filter.eventKey == "2" ? filter.name : filter.name.toLowerCase();
        if (eventKey === "1") {
          return ssh.ip && ssh.ip.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "8") {
          return ssh.domain && ssh.domain.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "2") {
          return ssh.port_no && ssh.port_no == lowerCaseFilter;
        } else if (eventKey === "3") {
          return (
            (lowerCaseFilter === "no" && !ssh.pass_auth_method) ||
            (lowerCaseFilter === "yes" && ssh.pass_auth_method)
          );
        } else if (eventKey === "7") {
          return (
            ssh.auth_methods?.length > 0 &&
            ssh.auth_methods?.includes(lowerCaseFilter)
          );
        } else if (eventKey === "4") {
          return (
            (lowerCaseFilter === "secured" && !ssh.pass_auth_method) ||
            (lowerCaseFilter === "unsecured" && ssh.pass_auth_method)
          );
        } else if (eventKey === "5") {
          const isVulnerable = ssh.creds?.length > 0;
          if (lowerCaseFilter === "not vulnerable") {
            return !isVulnerable;
          } else if (lowerCaseFilter === "vulnerable") {
            return isVulnerable;
          }
        } else if (eventKey === "6") {
          return ssh?.change_status === statusMap[filter.name.toLowerCase()];
        } else if (eventKey === "advanced-filter") {
          const parsedFilters = parseFilterString(filter.name);
          return parsedFilters.every((ol) => {
            const { column, condition, value } = ol;
            switch (column) {
              case "ip":
                return applyCondition(
                  ssh.ip?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "domain":
                return applyCondition(
                  ssh.domain?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "port":
                return applyCondition(
                  ssh.port_no?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "pass_auth_method":
                return (
                  (value?.toLowerCase() === "no" && !ssh.pass_auth_method) ||
                  (value?.toLowerCase() === "yes" && ssh.pass_auth_method)
                );
              case "auth_methods":
                if (condition == "is_not") {
                  return !(
                    ssh.auth_methods?.length > 0 &&
                    ssh.auth_methods?.includes(value?.toLowerCase())
                  );
                } else {
                  return (
                    ssh.auth_methods?.length > 0 &&
                    ssh.auth_methods?.includes(value?.toLowerCase())
                  );
                }
              case "creds":
                const isVulnerable = ssh.creds?.length > 0;
                if (value?.toLowerCase() === "not vulnerable") {
                  return condition == "is" ? !isVulnerable : isVulnerable;
                } else if (value?.toLowerCase() === "vulnerable") {
                  return condition == "is" ? isVulnerable : !isVulnerable;
                }
              case "current_state":
                return applyCondition(
                  ssh?.change_status,
                  condition,
                  statusMap[value.toLowerCase()]
                );

              default:
                return false;
            }
          });
        }
        return false;
      });
    });

    const matchesSearch =
      searchValue === "" ||
      (ssh.auth_methods &&
        ssh.auth_methods.some((method) =>
          method.toLowerCase().includes(searchValue.toLowerCase())
        )) ||
      (ssh.ip && ssh.ip.toLowerCase().includes(searchValue.toLowerCase())) ||
      (ssh.port_no &&
        ssh.port_no
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase()));

    return matchesFilters && matchesSearch;
  });

  const toggleFilter = (name, filters) => {
    return filters.map((filter) => {
      if (filter.name === name) {
        return { ...filter, active: !filter.active };
      }
      return filter;
    });
  };
  const handleChartClick = (
    name,
    eventKey = "4",
    filterType = "SSH Auth. Method"
  ) => {
    const updatedFilters = toggleFilter(name, SSHAuthenticationMethodFilters);
    setSSHAuthenticationMethodFilters(updatedFilters);
    setActiveFilters((prevActiveFilters) => {
      const isFilterAlreadyActive = prevActiveFilters.some(
        (filter) => filter.name === name && filter.eventKey === eventKey
      );
      if (isFilterAlreadyActive) {
        return prevActiveFilters.filter((filter) => filter.name !== name);
      } else {
        return webUpdatesFilter(name, prevActiveFilters, eventKey, filterType);
      }
    });
    setTabs((prevTabs) =>
      prevTabs.map((tab) => {
        if (tab.eventKey === eventKey) {
          return {
            ...tab,
            data: updatedFilters,
          };
        }
        return tab;
      })
    );
  };

  const handleBarChartClick = (name) => {
    const updatedFilters = webUpdatesFilter(
      name,
      activeFilters,
      "5",
      "Dictionary Attack"
    );

    const updatedCertificateExpiration = tabs[4].data.map((item) => ({
      ...item,
      active: updatedFilters.some((filter) => filter.name === item.name),
    }));

    setTabs((prevTabs) => {
      const newTabs = [...prevTabs];
      newTabs[4] = {
        ...newTabs[4],
        data: updatedCertificateExpiration,
      };
      return newTabs;
    });
    setActiveFilters(updatedFilters);
  };
  const handelClickOnIssuePassword = (row) => {
    if (row?.pass_auth_method) {
      navigate(
        `/security-issues/${routeParams?.target_id}?ip=${row?.ip}&armoryIdParam=arm-ssh2`
      );
    }
  };
  const handelClickOnIssueDictionary = (row) => {
    if (row?.pass_auth_method) {
      navigate(
        `/security-issues/${routeParams?.target_id}?ip=${row?.ip}&armoryIdParam=arm-ssh1`
      );
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="main_container_style">
          <div className="pb-4 row top-container-main web-updates-container">
            <div className="col-12 col-lg-6 h-100 web-updates-col-7">
              <div className="row gap-4 top-container-main-row">
                <div className="col-12 h-50">
                  {/* <GenericCard
                    children={<ComparisonChart cardsData={cardsData[0]} />}
                    title={"SSH"}
                  /> */}
                  <GenericCard
                    children={
                      isDataInProcess ? (
                        <NoData />
                      ) : isDataisPartiallyProcessing &&
                        noDataDoughtnutChart ? (
                        <NoData />
                      ) : noDataDoughtnutChart ? (
                        <NoDataAfterScanning />
                      ) : (
                        <DoughnutChart
                          data={chartData}
                          options={CircularChartoptions}
                          onHandleClick={handleChartClick}
                        />
                      )
                    }
                    title={"SSH Authentication Method"}
                    isCentered={false}
                  />
                </div>
                <div className="col-12 h-50">
                  <div className="row h-100">
                    <div className="col-5">
                      <GenericCard
                        children={
                          <WebUpdate
                            data={webUpdatesData}
                            onSort={handleWebUpdateSort}
                            dataInProcess={
                              isDataInProcess || isDataisPartiallyProcessing
                            }
                          />
                        }
                        title={"Updates"}
                      />
                    </div>
                    <div className="col-7">
                      <GenericCard
                        children={
                          isDataInProcess ? (
                            <NoData />
                          ) : isDataisPartiallyProcessing && noDataBarChart ? (
                            <NoData />
                          ) : noDataBarChart ? (
                            <NoDataAfterScanning />
                          ) : (
                            <BarChart
                              barData={barChartData}
                              optionsData={barChartOptions}
                              onBarClick={handleBarChartClick}
                              isData={
                                !!(
                                  barChartType &&
                                  barChartType.no !== undefined &&
                                  barChartType.yes !== undefined
                                )
                              }
                              dynamicLabels={["Vulnerable", "Not Vulnerable"]}
                              isChartLabelsOnTop
                            />
                          )
                        }
                        title={"SSH Dictionary Attack"}
                        subtitle={"View Issues"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 h-100 web-updates-col-5">
              <WebScanner title={"SSH Authentication Security Scanner"} />
            </div>
          </div>
          <div className="px-10 pb-3">
            {/* <div className="table-container">
              <div className="table-left">Results</div>
              <div>
                <BaseInput
                  type="text"
                  placeholder="Search..."
                  className="search-input"
                  icon={Search}
                  value={searchValue}
                  onChange={handleSearchChange}
                />
              </div>
            </div> */}
            <div className="py-1">
              <BaseFilter
                className="mt-3 mb-3"
                tabs={tabs}
                totalRecords={filteredSSH.length}
                onFilterChange={handleFilterChange}
                activeFilters={activeFilters}
                removeFilter={removeFilter}
                exportTitle={`SSHAuthenticationSecurity_${formatScannerName(
                  dropdownItems.filter((item) => {
                    return item.target_uuid === routeParams?.target_id;
                  })[0]?.title
                )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
                exportHeader={[
                  "IP Address",
                  "Port",
                  "Authentication Method",
                  "Password Auth. Method",
                  "Default Credentials",
                ]}
                exportRows={filteredSSH.flatMap((ssh) =>
                  ssh.auth_methods?.length > 0
                    ? ssh.auth_methods.map((method) => [
                        ssh.ip,
                        ssh.port_no,
                        method,
                        method === "password" && ssh.creds?.length > 0
                          ? "Yes"
                          : "No",
                        method === "password" && ssh.creds?.length > 0
                          ? `"Username:${ssh.username}, Password:${ssh.password}"`
                          : "None",
                      ])
                    : [[ssh.ip, ssh.port_no, "-", "No", "None"]]
                )}
                exportPDFRows={filteredSSH.map((ssh) => [
                  ssh.ip,
                  ssh.port_no,
                  ssh.auth_methods?.length > 0
                    ? `"${ssh.auth_methods.join(", ")}"`
                    : "-",
                  ssh.pass_auth_method === 1 ? "Yes" : "No",
                  ssh.creds?.length > 0
                    ? `"Username:${ssh.username}, Password:${ssh.password}"`
                    : "None",
                ])}
                isDatainProcess={isDataInProcess || isDataisPartiallyProcessing}
                searchValue={searchValue}
                onSearchChange={handleSearchChange}
                isSearch={true}
                tableData={filteredSSH}
              />
            </div>
            <div>
              <BaseTable
                className="mt-3 mb-3"
                columns={columns}
                data={filteredSSH}
                selectable={true}
                showCheckboxes={false}
                isDatainProcess={isDataInProcess || isDataisPartiallyProcessing}
                loading={isLoading}
                isStickyScroll
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DefaultCredential;
