import axios from "./axios";
import { showToast } from "./toasts";

export const logout = (navigate) => {
  axios
    .get(`/logout`)
    .then(() => {
      localStorage.clear();
      navigate("/auth/login");
      showToast("You are logged out!", "success", "top-center", "light");
    })
    .catch(() => {
      showToast("Failed to logout", "error", "top-center", "light");
    });
};
