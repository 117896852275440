import React, { useState, useRef, useEffect } from "react";
import {
  Dropdown,
  FormControl,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import DownIcon from "../../assets/images/down-icon.png";
import { ReactComponent as CancelIcon } from "../../assets/images/cross-icon.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import axios from "../../util/axios.js";

const FilterSelect = ({
  options,
  onChange,
  value,
  hasCustomInput,
  disabled,
  isDatePicker,
  columnName,
  isBackendProcess = false,
}) => {
  const routeParams = useParams();
  const [customValue, setCustomValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [userAddedOptions, setUserAddedOptions] = useState([]);
  const [prevColumnName, setPrevColumnName] = useState(columnName);
  const inputRef = useRef(null);
  const datePickerRef = useRef(null);
  const searchTimeoutRef = useRef(null);

  const customOptions = useMemo(() => {
    if (customValue) {
      return [...userAddedOptions];
    } else {
      return [...userAddedOptions, ...options];
    }
  }, [options, userAddedOptions, customValue]);


  useEffect(() => {
    if (columnName && columnName !== prevColumnName) {
      setUserAddedOptions([]);
      setCustomValue("");
      setPrevColumnName(columnName);
    }
  }, [columnName]);

  const formatDate = (date) => {
    return date ? new Date(date).toLocaleDateString("en-US") : "";
  };

  const calculateDates = (days) => {
    const current = new Date();
    const past = new Date();
    past.setDate(current.getDate() - days);
    return [past, current];
  };

  useEffect(() => {
    const updateDateRange = (start, end = null) => {
      setStartDate(start);
      setEndDate(end);
      const formattedValue = end
        ? `${formatDate(start)} - ${formatDate(end)}`
        : formatDate(start);
      setSelectedValue(formattedValue);
      onChange && onChange(formattedValue);
    };

    switch (isDatePicker) {
      case "last_24_hours":
        updateDateRange(new Date());
        break;
      case "last_7_days":
        updateDateRange(...calculateDates(7));
        break;
      case "last_30_days":
        updateDateRange(...calculateDates(30));
        break;
      case "from":
      case "until":
        updateDateRange(value || null);
        break;
      case "between":
        let updatedValue = value?.split(" - ")?.filter((el) => el);
        if (updatedValue?.length == 2) {
          setStartDate(updatedValue?.[0]);
          setEndDate(updatedValue?.[1]);
        }
        setSelectedValue(
          startDate && endDate
            ? `${formatDate(startDate)} - ${formatDate(endDate)}`
            : "Select Date Range"
        );
        break;
      default:
        setSelectedValue("Select");
    }
  }, [isDatePicker, value]);

  const handleDateChange = (dates) => {
    if (isDatePicker === "between") {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      onChange(`${formatDate(start)} - ${formatDate(end)}`);
    } else if (isDatePicker === "from") {
      setStartDate(dates[0]);
      setEndDate(null);
      onChange(formatDate(dates[0]));
    } else if (isDatePicker === "until") {
      setStartDate(dates[0]);
      setEndDate(null);
      onChange(formatDate(dates[0]));
    }
  };

  const handleSelect = (option) => {
    setSelectedValue(option.label);
    setShowDropdown(false);
    onChange(option.value);
  };

  const fetchSearchedValue = async (value) => {
    try {
      const { data } = await axios.get(
        `/filter/${routeParams?.id}/assetInventory?service=${columnName}&search=${value}`,
        {
          version: "v2",
        }
      );
      if (Array.isArray(data?.data) && data?.data?.length > 0) {
        setUserAddedOptions(data?.data);
      } else {
        setUserAddedOptions([]);
      }
    } catch (e) {
      console.error("Error", e);
    }
  };

  const handleCustomValueChange = (e) => {
    setCustomValue(e.target.value);
    if (isBackendProcess && e.target.value?.length > 1) {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
      searchTimeoutRef.current = setTimeout(() => {
        fetchSearchedValue(e.target.value);
      }, 800);
    }
  };

  const handleAddCustomValue = (e) => {
    e.stopPropagation();
    if (customValue.length > 1) {
      setUserAddedOptions([])
      const newOption = { label: customValue, value: customValue };
      setUserAddedOptions((prev) => [newOption, ...prev]);
      setCustomValue("");
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const handleClearInput = (e) => {
    e.stopPropagation();
    setUserAddedOptions([]);
    setCustomValue("");
    inputRef.current?.focus();
  };

  const renderTooltip = (props) => (
    <Tooltip
      id="selectedValue-tooltip"
      {...props}
      className="light-theme-tooltip"
    >
      {selectedValue}
    </Tooltip>
  );

  const renderDateTooltip = (props) =>
    typeof startDate == "string" && typeof endDate == "string" ? (
      <Tooltip
        id="startDate-tooltip"
        {...props}
        className="light-theme-tooltip"
      >
        {`${startDate} - ${endDate}`}
      </Tooltip>
    ) : (
      <Tooltip
        id="startDate-tooltip"
        {...props}
        className="light-theme-tooltip"
      >
        {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}
      </Tooltip>
    );

  useEffect(() => {
    const selectedOption = options.find((option) => option.value === value);
    setSelectedValue(selectedOption?.label || value || "");
  }, [value, options]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return isDatePicker ? (
    <div
      className="date-picker-dropdown"
      ref={datePickerRef}
      style={{
        width: "152px",
      }}
    >
      <Dropdown
        show={showDatePicker}
        onToggle={() => setShowDatePicker(!showDatePicker)}
        disabled={disabled}
      >
        <Dropdown.Toggle
          variant="secondary"
          onClick={() => setShowDatePicker(!showDatePicker)}
          className="date-picker-toggle"
        >
          {startDate && endDate ? (
            typeof startDate == "string" && typeof endDate == "string" ? (
              <OverlayTrigger placement="top" overlay={renderDateTooltip}>
                <div>{startDate}...</div>
              </OverlayTrigger>
            ) : (
              <OverlayTrigger placement="top" overlay={renderDateTooltip}>
                <div>{`${startDate.toLocaleDateString()}...`}</div>
              </OverlayTrigger>
            )
          ) : typeof startDate == "string" ? (
            startDate
          ) : startDate ? (
            `${startDate.toLocaleDateString()}`
          ) : (
            "Select"
          )}
          <img src={DownIcon} alt="dropdown-icon" />
        </Dropdown.Toggle>
        {showDatePicker && (
          <div
            className="date-picker-container"
            style={{
              position: "absolute",
              zIndex: 99,
            }}
          >
            <DatePicker
              selectsRange
              startDate={typeof startDate == "string" ? null : startDate}
              endDate={typeof endDate == "string" ? null : endDate}
              onChange={handleDateChange}
              inline
            />
          </div>
        )}
      </Dropdown>
    </div>
  ) : (
    <Dropdown
      show={showDropdown}
      onToggle={() => setShowDropdown(!showDropdown)}
      disabled={disabled}
    >
      <Dropdown.Toggle
        variant="secondary"
        className={!selectedValue && "not-selected"}
      >
        {selectedValue ? (
          selectedValue?.length > 9 ? (
            <OverlayTrigger placement="top" overlay={renderTooltip}>
              <div>{`${selectedValue.slice(0, 9)}...`}</div>
            </OverlayTrigger>
          ) : (
            selectedValue
          )
        ) : (
          "Select"
        )}
        <img src={DownIcon} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {hasCustomInput && (
          <Dropdown.Item
            className={`custom-value hide-border ${
              customValue.length > 1 && "custom-value-entered"
            }`}
            disabled={customValue.length <= 1}
            onClick={handleAddCustomValue}
          >
            <span>+</span>{" "}
            {customValue.length <= 1 ? "Custom Value" : "Add Custom Value"}
          </Dropdown.Item>
        )}
        {hasCustomInput && (
          <div className="input-custom">
            <FormControl
              type="text"
              ref={inputRef}
              value={customValue}
              placeholder="Type Value"
              onChange={handleCustomValueChange}
            />
            {customValue && (
              <CancelIcon
                className="cancel-icon ms-2 cursor-pointer"
                onClick={handleClearInput}
              />
            )}
          </div>
        )}
        {customOptions?.length > 0 ? (
          customOptions.map((option) => (
            <Dropdown.Item
              key={option.value}
              onClick={() => handleSelect(option)}
              className={`${
                selectedValue == option.label && "selected-option"
              }`}
            >
              {option.label?.length > 20 ? (
                <OverlayTrigger
                  placement="top"
                  overlay={(props) => (
                    <Tooltip
                      id="option-tooltip"
                      className="light-theme-tooltip"
                      {...props}
                    >
                      {option.label}
                    </Tooltip>
                  )}
                >
                  <div>{`${option.label.slice(0, 20)}...`}</div>
                </OverlayTrigger>
              ) : (
                option.label
              )}
            </Dropdown.Item>
          ))
        ) : (
          <Dropdown.Item key="No Value" className="disabled-option" disabled>
            No Value Found
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default FilterSelect;
