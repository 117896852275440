import React, { useState, useRef } from "react";
import { ThreeCircles } from "react-loader-spinner";
import BaseCheckbox from "../../../components/form/BaseCheckbox";
import TermsConditionsModal from "../../../auth/register/TermsConditionsModal";

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  domain,
  selectedDomains,
  isDomainLoading,
}) => {
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const formikRef = useRef();

  if (!isOpen) return null;

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleAcceptTerms = () => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("agreeTerms", true);
    }
    setShowTermsModal(false);
    setIsTermsAccepted(true);
  };

  const handleDeclineTerms = () => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("agreeTerms", false);
    }
    setShowTermsModal(false);
    setIsTermsAccepted(false);
  };

  const domainsToDisplay =
    selectedDomains.length > 0 ? selectedDomains : domain ? [domain] : [];
  const isActive = !isChecked || isDomainLoading;
  return (
    <>
      <div className="asset-modal-overlay">
        <div className="asset-modal-content">
          <div className="domain-header">
            <h4>
              {domainsToDisplay.length > 1
                ? `Are you sure you want to scan ${domainsToDisplay.length} selected domains?`
                : `Are you sure you want to scan ${domainsToDisplay[0]} domain?`}
            </h4>
          </div>
          <p>
            {domainsToDisplay.length > 1
              ? `These domains will be scanned for related assets, which will automatically be added to the asset inventory.`
              : `This domain will be scanned for related assets, which will automatically be added to the asset inventory.`}
          </p>

          {domainsToDisplay.length > 1 && <hr />}

          {domainsToDisplay.length > 1 && (
            <div
              className="domain-list"
              style={{ maxHeight: "150px", overflowY: "scroll" }}
            >
              {domainsToDisplay.map((d) => (
                <div className="list-domains" key={d}>
                  {d}
                </div>
              ))}
            </div>
          )}
          <div className="single-domain">
            <BaseCheckbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              name="selectAll"
            />
            <p>
              I have the rights and authorization to scan this target and I
              agree to the{" "}
              <span onClick={() => setShowTermsModal(true)}>
                Terms of Services
              </span>
            </p>
          </div>

          <div className="asset-modal-actions">
            <button className="btn asset-modal-button" onClick={onClose}>
              Cancel
            </button>
            <button
              className="btn asset-modal-button"
              onClick={() => onConfirm(domainsToDisplay)}
              disabled={isActive}
              style={{
                borderColor: isActive ? "#636363" : "",
                backgroundColor: isActive ? "#252525" : "",
                color: isActive ? "#636363" : "",
                cursor: isActive ? "not-allowed" : "pointer",
              }}
            >
              {isDomainLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ThreeCircles
                    visible={true}
                    height="20"
                    width="20"
                    color="#398ffe"
                    ariaLabel="three-circles-loading"
                    wrapperClass=""
                  />
                </div>
              ) : (
                "Scan"
              )}
            </button>
          </div>
        </div>
      </div>
      <TermsConditionsModal
        show={showTermsModal}
        onHide={handleDeclineTerms}
        onContinue={handleAcceptTerms}
        onDecline={handleDeclineTerms}
        isAfterRegister={true}
      />
    </>
  );
};

export default ConfirmationModal;