import React from "react";
import { FormCheck, OverlayTrigger } from "react-bootstrap";
import { isNormalUser, isEditorUser } from "../../util/constants";
import CustomTooltip from "../../components/tooltip/WhiteTooltip";

const ScanTypeSelector = ({
  selectedService,
  setSelectedService,
  setFieldValue,
}) => {
  return (
    <div className="row">
      <div className="col-12 checkbox-scan">
        {!isNormalUser() && (
          <FormCheck
            inline
            label="Full Scan"
            className="me-3"
            type="checkbox"
            name="scan_type"
            id="fullScan"
            checked={selectedService === 0}
            onChange={() => {
              setSelectedService(0);
              setFieldValue("scan_type", 0);
              setFieldValue("ip_limit", 0);
            }}
          />
        )}

        {!isNormalUser() && !isEditorUser() && (
          <FormCheck
            inline
            label={
              <OverlayTrigger
                placement="top"
                trigger={["hover", "focus"]}
                overlay={
                  <CustomTooltip id="tooltip-demo-scan">
                    The Demo Scan is a full scan with a limit of 30 IPs.
                  </CustomTooltip>
                }
              >
                <span>Demo Scan</span>
              </OverlayTrigger>
            }
            type="checkbox"
            name="scan_type"
            id="demoScan"
            checked={selectedService === 1}
            onChange={() => {
              setSelectedService(1);
              setFieldValue("scan_type", 1);
              setFieldValue("ip_limit", 30);
            }}
          />
        )}

        {!isNormalUser() && (
          <FormCheck
            inline
            label="Select Scanners"
            type="checkbox"
            name="service"
            id="selectedScan"
            checked={selectedService === 4}
            onChange={() => {
              setSelectedService(4);
              setFieldValue("scan_type", 4);
              setFieldValue("ip_limit", 0);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ScanTypeSelector;
